<template>
    <div>
      <div v-if="!authenticationRequired">
        <li :class="{'selected' : isSelected}">
          <b-link @click="navigateTo">
            <slot></slot>
          </b-link>
        </li>
      </div>

      <secured-area v-else hideWhenAccessIsRejected :authenticationRequired="isAuthenticationRequired" :operations="operations">
        <li :class="{'selected': isSelected}">
          <b-link @click="navigateTo">
            <slot></slot>
          </b-link>
        </li>
      </secured-area>
    </div>
</template>

<script>
import SecuredArea from '../Security/SecuredArea.vue';
export default{
  components: { SecuredArea },
  props: { 
    to: { type:Object, default:null }, 
    authenticationRequired: { type:Boolean, default: true},
    operations: { type: Array, default: null },
  },
  data(){
    return {
      isSelected: false,
    }
  },
  computed:{
      isAuthenticationRequired:{
        get() {
          if(this.operations && this.operations.length > 0) return true;
          return this.authenticationRequired
        }
      },
  },
  methods: {
    async navigateTo(){
      if(this.to && this.to != null)
      {
        var newLoc = this.$router.resolve(this.to);
        var navOk = (newLoc.href != this.$router.currentRoute.path);
        if(!navOk)
          this.$emit('refreshCurrentPage');
        else{
          await this.$showLoader();
          await this.$router.push(this.to)
            .catch(error =>
            {
              console.log(error);
              this.toastError("Impossible d'atteindre la page demandée.");
              this.$hideLoader();
              return false;
            });
        }
      }
    }
  },
  watch:{
    $route(to){
      var navLoc = this.$router.resolve(this.to);
      this.isSelected = navLoc.href == to.path;
    },
    cfgByPassSecurity(){
      var navLoc = this.$router.resolve(this.to);
      this.isSelected = navLoc.href == this.$router.currentRoute.path;
    }
  }
}
</script>

<style scoped >
  /*li { list-style-type:square; }*/
  li { /*background-color: var(--ffvlOrangeLight);*/ color: var(--ffvlSecondary); font-weight: bold; list-style-type: none; }
  li::before{ /*background-color: var(--dark);*/ content: "\2022"; color: var(--ffvlOrangePicto); font-weight: bold; display: inline-block; width:1em; margin-left:-1em; }
  /*li { background-color: var(--dark) !important;}*/
  a { font-size: 0.9em; font-weight: normal; color: var(--ffvlSecondary)}
  li:hover{ color: var(--dark) !important; background-color: var(--ffvlSecondary) !important;}
  li.selected, li.selected:hover{ background-color: var(--ffvlOrangePicto) !important; color: var(--dark) !important;}
  li.selected > a { color: var(--dark) !important;}
</style>
