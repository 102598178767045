<template>
    <div>A DEVELOPPER : MaritalStatusDialog</div>
</template>

<script>

export default {
    
}
</script>
