<template>
  <div>
    <b-modal
        title="Mon compte"
        centered
        :static="true"
        :lazy="true"
        size="xl"
        v-if="d_visible"
        v-model="d_visible"
        @hide="onHide()"
        button-size="lg"
    >
      <template #modal-title>
        <b-icon icon="person" /> Mon compte
      </template>
        <collapse-panel title="identification">
          <form-field caption="login" :required="true">
            <template #info-helper-placeholder>
              <info-helper>
                <b>Login = Username = Identifiant de connexion</b><br/>
                Représente le nom avec lequel vous pouvez vous connecter via l'écran de connexion vous demandant la saisie de votre <b>Login</b> et de votre <b>Mot de passe</b>.<br/>
                <u>Vous ne pouvez pas</u> changer votre login. Par contre vous pouvez demander à un administrateur de le faire pour vous.<br/>
              </info-helper>
            </template>
            <b-input type="text" readonly v-model="currentUser.username" placeholder="Login" />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="passwordChangeDialogVisible=true">Modifier mon mot de passe</b-button>
        </collapse-panel>
        <collapse-panel title="Etat civil">
          <form-field caption="Nom" :required="true">
            <b-input type="text" readonly v-model="currentUser.lastname" placeholder="Nom" />
          </form-field>
          <form-field caption="Prénom">
            <b-input type="text" readonly v-model="currentUser.firstname" placeholder="Prénom" />
          </form-field>
          <form-field caption="Sexe">
            <b-input type="text" readonly v-model="currentUserSexeLabel" placeholder="Sexe" />
          </form-field>
          <form-field caption="Date de naissance">
            <b-input type="text" readonly />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="maritalStatusDialogVisible=true">Corriger mon état-civil</b-button>
        </collapse-panel>
        <collapse-panel title="Me contacter">
          <form-field caption="Courriel" :required="true">
            <b-input type="mail" readonly />
          </form-field>
          <form-field caption="N° téléphone">
            <b-input type="phone" readonly />
          </form-field>
          <b-button size="xs" :disabled="isImpersonated" variant="primary" @click="contactMeInfoDialogVisible=true">Corriger mes informations de contact</b-button>
        </collapse-panel>
        <b-button size="xs" variant="outline-secondary" @click="ppdpVisible=true">Politique de confidentialité et protection des données personnelles</b-button>
        <collapse-panel title="Rôles applicatifs">
          <ul><li v-for="role in currentUserRoles" :key="role">{{ role}}</li></ul>
        </collapse-panel>
    </b-modal>

    <ppdp-dialog
      :visible="ppdpVisible"
      @hide="ppdpVisible=false"
    ></ppdp-dialog>

    <change-password-dialog
      :visible="passwordChangeDialogVisible"
      @hide="passwordChangeDialogVisible=false"
    ></change-password-dialog>

    <marital-status-dialog
      :visible="maritalStatusDialogVisible"
      @hide="maritalStatusDialogVisible=false"
    ></marital-status-dialog>

    <contact-me-info-dialog
      :visible="contactMeInfoDialogVisible"
      @hide="contactMeInfoDialogVisible=false"
    ></contact-me-info-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormField from '@/components/Panels/FormField.vue';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import InfoHelper from '@/components/InfoHelper.vue';
import PpdpDialog from "@/components/Dialogs/PpdpDialog.vue";
import ChangePasswordDialog from "@/components/Dialogs/ChangePasswordDialog.vue";
import MaritalStatusDialog from "@/components/Dialogs/MaritalStatusDialog.vue";
import ContactMeInfoDialog from "@/components/Dialogs/ContactMeInfoDialog.vue";

export default {
  model:{
    prop: 'visible',
    event: 'change'
  },
  props: { visible: { type:Boolean, default: false}},
  components: { FormField, CollapsePanel, InfoHelper, PpdpDialog, ChangePasswordDialog, MaritalStatusDialog, ContactMeInfoDialog },
  data(){
    return {
      d_visible: this.visible,
      ppdpVisible: false,
      passwordChangeDialogVisible: false,
      maritalStatusDialogVisible: false,
      contactMeInfoDialogVisible: false,
    }
  },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'isImpersonated' ]),
    currentUserSexeLabel(){
      return '';
    }
  },
  methods: {
    onHide(){
      this.$emit('change');
      this.$emit('hide');
    }
  },
  watch:{
    visible: function(newValue){
      this.d_visible = newValue;
    },
  }
}
</script>
