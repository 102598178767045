<template>
  <div class="info-helper">
  <span class="info-helper" v-if="hasSlot() || hasSlot('info-body')">
    <slot name="caption">{{ caption }}</slot>
    <b-icon :icon="icon" :size="iconSize" class="icon" :variant="variant" @click="icon_click"></b-icon>
    <b-modal
      :size="modalSize"
      v-model="showInfo"
      @ok="showInfo=false"
      :ok-title="infoModalCloseButtonText"
      :ok-variant="variant"
      ok-only
      :title="title"
      variant="info"
      centered
    >
      <template v-slot:modal-header>
        <slot name="info-title">
          <b class="info">
            <slot name="info-title-text">{{ title}}</slot>
          </b>
        </slot>
      </template>
      <slot name="info-body"><i class="info"><slot></slot></i></slot>
    </b-modal>
  </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type:String, default:"info-circle-fill"},
    iconSize: { type:String, default:"xl"},
    variant: { type:String, default:"info"},
    infoModalCloseButtonText: { type: String, default:"Fermer"},
    modalSize: { type: String, default: ""},
    title: { type:String, default: "Info-Aide"},
    caption: { type:String, default: ""},
  },
  data(){
    return {
      showInfo:false,
    }
  },
  methods:{
    hasSlot(slotName = 'default')
    {
      return !!this.$slots[slotName];
    },
    icon_click(e){
      console.log('InfoHelper.icon_click', e);
      e.preventDefault();
      this.showInfo=true;
    }
  }
}
</script>

<style scoped>
  div.info-helper{
    display:inline-block;
    flex-direction: row;
    align-content:space-around;
    align-items: center;
    margin-left: 10px !important;
  }
  .icon{
    margin-right:15px
  }
  .info{
    color: var(--info);
  }
</style>
