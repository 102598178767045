<template>
  <div>
    <slot>
      <login-dialog ref="loginDialog" v-model="showModal">
        <template #prepend>
          <b>Accès refusé</b>
          <p>Cette partie de l'application est réservée pour les personnes authentifiées.</p>
          <p>Si vous possédez un compte utilisateur vous pouvez vous authentifier maintenant</p>
        </template>
      </login-dialog>
<!--      <b>Accès refusé</b>
      <p>Cette partie de l'application est réservée pour les personnes authentifiées.</p>
      <p>Si vous possédez un compte utilisateur vous pouvez vous authentifier maintenant</p>
      <div class="row">
        <div class="col md-6">
          <Login />
        </div>
      </div>-->
    </slot>
  </div>
</template>

<script>
//  import Login from "@/components/Security/Login.vue";
  import LoginDialog from "@/components/Dialogs/LoginDialog.vue";

  export default {
    name: "AuthentificationRequired",
    components: { /*Login,*/ LoginDialog },
    data(){
      return {
        showModal: true,
      }
    }
  }
</script>
