import { Model } from "@vuex-orm/core";
import { v4 as uuid} from 'uuid';

/***************************/
/*     M E T A D A T A     */
/***************************/

class DATA_SYNC_SUBSCRIPTIONS extends Model {
    static entity = "_DATA_SYNC_SUBSCRIPTIONS";

    static primaryy = ['type', 'id'];

    static fields (){
      return {
        type: this.string(),
        id: this.string(),
      }
    }
}

/*-------------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Règlement"                */
/*-------------------------------------------------------------------*/
class REF_REGULATION extends Model{
  static entity = 'REF_REGULATION'

  static primaryKey = 'code';

  static fields(){
    return {
      code: this.string(),
      isFederal: this.boolean(false),
      shortname: this.string(),
      name: this.string(),
      longname: this.string(),
      isArchived: this.boolean(false),
      isCva: this.boolean(false),
      isIndoor: this.boolean(false),
      isFreestyle: this.boolean(false),
      isMonofil: this.boolean(false),
    }
  }
}

class REF_PENALTY extends Model{
  static entity = 'REF_PENALTY';

  static fields(){
    return {
      regulation: this.string(),
      name: this.string(),
      isAvailableForCompulsory: this.boolean(false),
      isAvailableForTechnicalRoutine: this.boolean(false),
      isAvailableForBallet: this.boolean(false),
      canBeSetBy: this.string(null),
      value: this.number(),
      isValueAbsolute: this.boolean(false),
      canBeSetMultipleTimes: this.boolean(false),
      isArchived: this.boolean(false)
    }
  }

  get isAvailableForRoutine(){
    return this.isAvailableForTechnicalRoutine;
  }
}
/*-------------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Modes d'inscription"      */
/*-------------------------------------------------------------------*/
class REF_REGISTRATION_MODE extends Model{
  static entity = 'REF_REGISTRATION_MODE'

  static primaryKey = 'code';

  static fields(){
    return {
      code: this.string(),
      shortname: this.string(),
      name: this.string(),
      displayOrder: this.number(),
      isArchived: this.boolean(false),
      description: this.string(),
    }
  }
}

/*------------------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Mode de calcul résultat final" */
/*------------------------------------------------------------------------*/
class REF_RESULT_CALCULATION_MODE extends Model{
  static entity = 'REF_RESULT_CALCULATION_MODE';

  static primaryKey = ['code', 'regulation'];

  static fields(){
    return {
      code: this.string(),
      regulation: this.string(),
      name: this.string(),
      agregation: this.string(),
      keepOrSkip: this.string(),
      bestOrWorst: this.string(),
      isKeepOrSkipLimitRelative: this.boolean(),
      splitPrecisionAndBallet: this.boolean(),
      displayOrder: this.number(),
      isArchived: this.boolean(),
      description: this.string(),
    }
  }

  get REGULATION(){ return REF_REGULATION.query().where("code", this.regulation).first(); }
}

class REF_CATEGORY extends Model{
  static entity = 'REF_CATEGORY';

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          name: this.string(),
          shortname: this.string(),
          longname: this.string(),
          isArchived: this.boolean(false),
          isIndividual: this.boolean(false),
          isPair: this.boolean(false),
          isTeam: this.boolean(false),
          isDualLines: this.boolean(false),
          isMultiLines: this.boolean(false),
          isCva: this.boolean(false),
          isIndoor: this.boolean(false),
          isFreestyle: this.boolean(false),
          displayOrder: this.number(1),
      }
  }
}
/*--------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Type de compétition" */
/*--------------------------------------------------------------*/
class REF_COMPETITION_TYPE extends Model{
  static entity = 'REF_COMPETITION_TYPE';

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          isFederal: this.boolean(false),
          shortname: this.string(),
          regulation: this.string(),
          name: this.string(),
          longname: this.string(),
          isArchived: this.boolean(false),
          isCva: this.boolean(false),
          isIndoor: this.boolean(false),
          isFreestyle: this.boolean(false),
          isIrbc: this.boolean(false),
          isChampionship: this.boolean(false),
      }
  }

  get isPartOfCdfCva(){
    return this.isFederal && this.isCva;
  }

  get isPartOfCdfIndoor(){
    return this.isFederal && this.isIndoor;
  }

  get REGULATION(){
    return REF_REGULATION.query().where("code", this.regulation).first();
  }

  get _CHAMPIONSHIPS(){
    return REF_CHAMPIONSHIP.query().where("competition_type", this.code);
  }
  get CHAMPIONSHIPS(){
    return this._CHAMPIONSHIPS.orderBy("year", "desc").get();
  }
}

/** Structure de stockage de la "presque" métadonnée "Championnat" */
class REF_CHAMPIONSHIP extends Model{
  static entity = "REF_CHAMPIONSHIP";

  static primaryKey = ["id"];

  static fields(){
    return {
      id: this.string(null),
      name: this.string(null),
      longname: this.string(null),
      year: this.number(),
      competition_type: this.string(null),
      isCompleted: this.boolean(false),
    }
  }
  
  get COMPETITION_TYPE(){
    return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first();
  }

  get SEASOON() {
    return SEASON.query().where("year", this.year).first();
  }

  get errors(){
    return this.getErrors();
  }

  get hasErrors(){
    return this.getErrors(true).length > 0;
  }

  getErrors(stopIfAny = false){
    var ret = new ErrorsBuilder(stopIfAny);

    if(this.id == null || this.id.trim() == '')
      if(ret.push("ID_MISSING", "L'id du championnat est obligatoire !", "Championship's Id is required !")) return ret.List;
    
    if(this.name == null || this.name.trim() == '')
      if(ret.push("NAME_MISSION", "Le nom du championnat est obligatoire !", "Championship's name is required !")) return ret.List;

    if(this.year == null || this.year == 0)
      if(ret.push("YEAR_MISSION", "Une année de référrence est obligatoire !", "Reference year is required !")) return ret.List;

    if(this.competition_type == null || this.competition_type.trim() == '')
      if(ret.push("COMPETTION_TYPE_MISSIN", "Le type de compétition est obligatoire !", "Competition type is required !")) return ret.List;

    if(this.SEASON == null)
      if(ret.push("INVALID_YEAR", "L'année ne correspond pas à une saison définie !", "Year do not match any known season !")) return ret.List;

    if(this.COMPETITION_TYPE == null)
      if(ret.push("INVALID_COMPETITION_TYPE", "Type de compétition inconnu !", "Unknown competition type !")) return ret.List;

    return ret.List;
  }
  
}

class REF_COMPETITION_TYPE_SCOPE extends Model{
  static entity = "REF_COMPETITION_TYPE_SCOPE";

  static primaryKey = ['competition_type', 'scope'];

  static fields(){
    return {
      competition_type : this.string(null),
      scope : this.string(null),
    }
  }

  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first()}
  get SCOPE(){ return REF_SCOPE.query().where("code", this.scope).first()}
}

/*-----------------------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Figure de précision de compétition" */
/*-----------------------------------------------------------------------------*/
class REF_COMPULSORY extends Model{
  static entity = "REF_COMPULSORY";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      code: this.string(),
      name: this.string(),
      isArchived: this.boolean(false),
      competition_type: this.string(),
      category : this.string(),
      level: this.string(),
    }
  }

  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get LEVEL(){ return REF_LEVEL.query().where("code", this.level).first(); }
  get CATEGORY() { return REF_CATEGORY.query().where("code", this.category).first(); }
}

/*------------------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Type d'épreuve de compétition" */
/*------------------------------------------------------------------------*/
class REF_EVENT extends Model{
  static entity = 'REF_EVENT';

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          shortname: this.string(),
          name: this.string(),
          longname: this.string(),
          isArchived: this.boolean(false),
          hasCompulsories: this.boolean(false),
          hasRoutine: this.boolean(false),
          hasBallet: this.boolean(false),
          displayOrder: this.number(),
      }
  }

  get componentsCount(){ var ret = 0;
    if(this.hasCompulsories) ret++;
    if(this.hasRoutine) ret++;
    if(this.hasBallet) ret++;
    return ret;
  }
}

/*----------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Niveau de compétition" */
/*----------------------------------------------------------------*/
class REF_LEVEL extends Model{
  static entity = 'REF_LEVEL';

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          shortname: this.string(),
          name: this.string(),
          longname: this.string(),
          isArchived: this.boolean(false),
      }
  }
}

/*----------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Règle de calcul" */
/*----------------------------------------------------------*/
class REF_RULE extends Model{
  static entity = "REF_RULE";

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          competition_type: this.string(),
          shortname: this.string(),
          name: this.string(),
          longname: this.number(0),
          isArchived: this.boolean(false),
      }
  }

  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }

}

/*----------------------------------------------------------------*/
/* Structure de stockage de la métadonnée "Portée de compétition" */
/*----------------------------------------------------------------*/
class REF_SCOPE extends Model {
  static entity = 'REF_SCOPE';

  static primaryKey = 'code';

  static fields(){
      return {
          code: this.string(),
          shortname: this.string(),
          name: this.string(),
          longname: this.string(),
          isArchived: this.boolean(false),
      }
  }
}


/**************************************************************************/
/*     D O N N E E S   P E R S O N N E S   ET   C O M P E T I T E U R S   */
/**************************************************************************/

/*-----------------------------------------------*/
/* Structure de stockage de la donnée "Personne" */
/*-----------------------------------------------*/
class PEOPLE extends Model {
  static entity = "PEOPLE";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      firstname: this.string(),
      lastname: this.string(),
      federalNumber: this.string(),
      sexe: this.string(),
      birthdate : this.attr(),
      isStaff: this.boolean(false),
      User_Id: this.attr(null),
      email: this.attr(null),

      isSync: this.boolean(false),
    }
  }

  get name(){
    return `${this.firstname} ${this.lastname}`;
  }

  get shortname(){
    return `${this.firstname} ${this.lastname.charAt(0)}.`;
  }

  get initials(){
    return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}`;
  }

  get trigram(){
    return `${this.firstname.charAt(0)}${this.lastname.charAt(0)}${this.lastname.charAt(this.lastname.length-1)}`;
  }

  get isFederal(){
    return this.federalNumber !== null && this.federalNumber !== '';
  }

  get age(){
    return (this.birthdate === null) ? null : 50;
  }

  get STAFF_MEMBER(){
    return STAFF.query().where("id", this.id).first();
  }

  get federal_number() { return this.federalNumber;}
  set feferal_number(val) { this.federalNumber = val; }

  get LICENSEE(){
    const currentYear = new Date().getFullYear();
    return LICENSEE.query().where("federal_number", this.federalNumber).where("year",currentYear).first();
  }

  get VISA(){
    return VISA.find(this.User_Id); //VISA.query().where('id', this.User_Id).first();
  }

  get visa(){
    return (this.VISA && this.VISA != null) ? this.VISA.visa : '';
  }
}

/*----------------------------------------------------------------------------------------------*/
/* Structure de stockage étendant le stockage de la donnée "Personne" pour les membres du Staff */
/*----------------------------------------------------------------------------------------------*/
class STAFF extends Model{
  static entity = "STAFF";

  static fields(){
    return {
      id: this.uid(),
      isFederalActive: this.boolean(),

    isSync: this.boolean(false),
    }
  }

  get PEOPLE() { return PEOPLE.query().where("id", this.id).first(); }

  get visa() { return this.PEOPLE.visa; }
}

/*--------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Capacité d'un membre de staff" */
/*--------------------------------------------------------------------*/
class STAFF_CAPABILITY extends Model{
  static entity = "STAFF_CAPABILITY";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      staff_id: this.uid(),
      competition_type: this.string(),
      scope: this.string(),
      isFederalValid: this.boolean(),
      isJudge: this.boolean(),
      isJudgeShadow: this.boolean(),
      isTechJudge: this.boolean(),
      isTechJudgeShadow: this.boolean(),
      isArtJudge: this.boolean(),
      isArtJudgeShadow: this.boolean(),
      isFieldDirector: this.boolean(),
      isFieldDirectorShadow: this.boolean(),
      isScorer: this.boolean(),
      isScorerShadow: this.boolean(),
      isTeacher: this.boolean(),
      isTeacherShadow: this.boolean(),

      level: this.attr(null),

      isSync: this.boolean(false),
    }
  }

  get STAFF() { return STAFF.query().where("id", this.staff_id).first(); }

  get COMPETITION_TYPE() { return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get SCOPE() { return REF_SCOPE.query().where("code", this.scope).first(); }
  get LEVEL() { return REF_LEVEL.query().where("code", this.level).first(); }
}

/*--------------------------------------------------*/
/* Structure de stockage de la donnée "Compétiteur" */
/*--------------------------------------------------*/
class COMPETITOR extends Model{
  static entity = "COMPETITOR";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      name: this.string(),
      shortname: this.string(),
      category: this.string(),
      competition_type: this.string(),

      people_id: this.uid(),

      isSync: this.boolean(false),
    }
  }

  get isPair() { return this.CATEGORY && this.CATEGORY.isPair; }
  get isTeam() { return this.CATEGORY && this.CATEGORY.isTeam; }
  get isIndividual() { return this.CATEGORY && this.CATEGORY.isIndividual; }
  get isFederal() {return !this.isIndividual ? false : this.PEOPLE && this.PEOPLE.isFederal; }

  get CATEGORY() { return REF_CATEGORY.query().where("code", this.category).first(); }
  get COMPETITION_TYPE() { return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get PEOPLE() { return this.people_id == null ? null : PEOPLE.query().where("id", this.people_id).first(); }

}

/*--------------------------------------------------------------*/
/* Structure de stockage de la donnée "Membre d'un Compétiteur" */
/*--------------------------------------------------------------*/
class COMPETITOR_MEMBER extends Model{
  static entity = "COMPETITOR_MEMBER";

  //static primaryKey = ['competitor_id', 'people_id'];

  static fields(){
    return {
      competitor_id: this.uid(),
      people_id: this.uid(),
      id: this.uid(()=>uuid()),
      isSubstitute: this.boolean(),
      isLeader: this.boolean(),
      hireDate: this.attr(),
      leaveDate: this.attr(),

      isSync: this.boolean(false),
    }
  }

  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }
  get PEOPLE() { return PEOPLE.query().where("id", this.people_id).first(); }
}

/*************************************************/
/*     D O N N E E S   S A I S O N N I E R E S   */
/*************************************************/

/*---------------------------------------------*/
/* Structure de stockage de la donnée "Saison" */
/*---------------------------------------------*/
class SEASON extends Model{
  static entity = "SEASON";

  static primaryKey = "year";

  static fields(){
    return {
      year: this.number(),
      yearFinalCdF: this.number(),
      yearSelectiveCdF: this.number(),

      isSync: this.boolean(false),
    }
  }
}

/*------------------------------------------------------------------------------------------------------*/
/* Structure de stockage définissant la relation entre saison, type de compétition et l'échelon         */
/* fournissant ainsi les compétitions qu'il est possible de réaliser, pour une année donnée et en       */
/* fonction de l'échelon                                                                                */
/*------------------------------------------------------------------------------------------------------*/
class SEASON_COMPETITION_TYPE_SCOPE extends Model{
  static entity = "SEASON_COMPETITION_TYPE_SCOPE"

  static primaryKey = ['year', 'competition_type', 'scope'];

  static fields(){
    return {
      year: this.number(),
      competition_type: this.string(),
      scope: this.string(),
      shortname: this.string(),
      name: this.string(),
      longname: this.string(),
      regulation: this.string(),
      defaultCompetitionNameFormat: this.string(),
      displayOrder: this.number(),
      id: this.uid(()=>uuid()),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get SCOPE() { return REF_SCOPE.query().where("code", this.scope).first(); }
  get REGULATION(){ return REF_REGULATION.query().where("code", this.regulation).first(); }
}

/*-------------------------------------------------------------------------------------------------------*/
/** Structure de stockage de la relation entre saison, championnat et échelon fournissant ainsi les 
 * championnat dans lesquels peuvent s'inscrire une compétition en fonction de son type, de la saison et
 * de son échelon (en relation donc, avec l'entité SEASON_COMPETITION_TYPE_SCOPE)
 * ----------------------------------------------------------------------------------------------------- */
class SEASON_CHAMPIONSHIP_SCOPE extends Model{
  static entity = "SEASON_CHAMPIONSHIP_SCOPE";
  static fields(){
    return {
      year: this.number(),
      championship: this.string(null),
      scope: this.string(null),
      regulation: this.string(),
      defaultCompetitionNameFormat: this.string(null),
      separatedRanks: this.boolean(false),
      defaultAverageRule: this.string(null),
      id: this.uid(()=>uuid()),
    }
  }
}

/*------------------------------------------------------------------------------------------------------*/
/* Structure de stockage définissant la relation entre saison, type de compétition, échelon et niveau   */
/* fournissant ainsi les niveaux possibles pour une année donnée et en fonction du type de compétition  */
/* et de l'échelon                                                                                      */
/*------------------------------------------------------------------------------------------------------*/
class SEASON_COMPETITION_TYPE_SCOPE_LEVEL extends Model{
  static entity = "SEASON_COMPETITION_TYPE_SCOPE_LEVEL"

  static primaryKey = ['year', 'competition_type', 'scope', 'level'];

  static fields(){
    return {
      year: this.number(),
      competition_type: this.string(),
      scope: this.string(),
      level: this.string(),
      displayOrder: this.number(),
      compulsories: this.number(),
      precision_events_compulsories: this.number(),

      id: this.uid(()=>uuid()),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get SCOPE() { return REF_SCOPE.query().where("code", this.scope).first(); }
  get LEVEL(){ return REF_LEVEL.query().where("code", this.level).first(); }

  get SEASON_COMPETITION_TYPE_SCOPE() { return SEASON_COMPETITION_TYPE_SCOPE.query().where("year", this.year).where("competition_type", this.competition_type).where("scope", this.scope).first(); }
}

/*------------------------------------------------------------------------------------------------------*/
/* Structure de stockage définissant la relation entre saison, type de compétition, échelon et niveau   */
/* fournissant ainsi les niveaux possibles pour une année donnée et en fonction du type de compétition  */
/* et de l'échelon                                                                                      */
/*------------------------------------------------------------------------------------------------------*/
class SEASON_COMPETITION_TYPE_SCOPE_EVENT extends Model{
  static entity = "SEASON_COMPETITION_TYPE_SCOPE_EVENT"

  static primaryKey = ['year', 'competition_type', 'scope', 'event'];

  static fields(){
    return {
      year: this.number(),
      competition_type: this.string(),
      scope: this.string(),
      event: this.string(),
      displayOrder: this.number(),

      id: this.uid(()=>uuid()),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get SCOPE() { return REF_SCOPE.query().where("code", this.scope).first(); }
  get EVENT(){ return REF_EVENT.query().where("code", this.event).first(); }

  get SEASON_COMPETITION_TYPE_SCOPE() { return SEASON_COMPETITION_TYPE_SCOPE.query().where("year", this.year).where("competition_type", this.competition_type).where("scope", this.scope).first(); }
}

/*------------------------------------------------------------------------------------------------------*/
/* Structure de stockage définissant la relation entre saison, type de compétition, niveau et catégorie */
/*------------------------------------------------------------------------------------------------------*/
class SEASON_COMPETITION_TYPE_CATEGORY_LEVEL extends Model{
  static entity = "SEASON_COMPETITION_TYPE_CATEGORY_LEVEL";

  static primaryKey = ['year', 'competition_type', 'category', 'level'];

  static fields(){
    return {
      year: this.number(),
      competition_type: this.string(),
      category: this.string(),
      level: this.string(),
      compulsories: this.number(),

      isSync: this.boolean(false),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get LEVEL(){ return REF_LEVEL.query().where("code", this.level).first(); }
  get CATEGORY(){ return REF_CATEGORY.query().where("code", this.category).first(); }
}

/*----------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Figures de précision de l'année" */
/*----------------------------------------------------------------------*/
class SEASON_COMPULSORY extends Model {
  static entity = "SEASON_COMPULSORY";

  static primaryKey = ['year', 'compulsory_id'];

  static fields(){
    return {
      year: this.number(),
      compulsory_id: this.uid(),

      isSync: this.boolean(false),
    }
  }

  get SEASON(){ return SEASON.query().where("year", this.year).first(); }
  get COMPULSORY() { return REF_COMPULSORY.query().where("id", this.compulsory_id).first(); }
}

/*---------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Détail des règles pour l'année" */
/*---------------------------------------------------------------------*/
class SEASON_RULE extends Model {
  static entity = "SEASON_RULE";

  static fields(){
    return{
      id: this.uid(()=>uuid()),
      year: this.number(),
      rule: this.string(),
      competition_type: this.string(),
      level: this.string(),
      hasCompulsories: this.boolean(),
      hasRoutine: this.boolean(),
      hasBallet: this.boolean(),
      numberOfCompulsories : this.number(),
      poundOfEachCompulsoryWithinPrecision : this.number(),
      poundOfRoutineWithinPrecision : this.number(),
      poundOfRoutineExecution: this.number(),
      poundOfRoutineContent: this.number(),
      poundOfPrecisionWithinOverall: this.number(),
      poundOfBalletExecution: this.number(),
      poundOfBalletChoreo : this.number(),
      poundOfBalletWithinOverall: this.number(),
      minJudgesRequiredToSkipWorseNote: this.number(),
      minJudgesRequiredToSkipBestNote: this.number(),

      minChiefJudgesRequiredForCompetition: this.number(0),
      maxAdditionalChiefJudgesPossibleForCompetition: this.number(0),
      minChiefJudgesRequiredForRounds: this.number(0),
      maxAdditionalChiefJudgesPossibleForRounds: this.number(0),
      minCompetitorsDelegatesForCompetition: this.number(0),
      maxAdditionalCompetitorsDelegatesForCompetition: this.number(0),

      minCompetitorsDelegatesForRouund: this.number(0),
      maxAdditionalCompetitorsDelegatesForRounds: this.number(0),

      isSync: this.boolean(false),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get LEVEL(){ return REF_LEVEL.query().where("code", this.level).first(); }
  get RULE(){ return REF_RULE.query().where("code", this.rule).first(); }
  get EVENTS(){ return SEASON_RULE_EVENT.query()
                    .where("competition_type", this.competition_type)
                    .where("level", this.level)
                    .where("year", this.year)
                    .where("rule", this.rule)
                    .get();
  }
  get code(){ return this.rule; }
  set code(val){ this.rule = val; }
  get hasPrecision(){ return this.hasCompulsories || this.hasRoutine; }
}

/*---------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Epreuves associées aux règles pour l'année" */
/*---------------------------------------------------------------------------------*/
class SEASON_RULE_EVENT extends Model{
  static entity = "SEASON_RULE_EVENT";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      event: this.string(),
      year: this.number(),
      rule: this.string(),
      competition_type: this.string(),
      level: this.string(),

      minJudgesRequiredToCompeteEvent: this.number(1),
      minTechJudgesRequiredToCompeteEvent: this.number(0),
      minArtJudgesRequiredToCompeteEvent: this.number(0),
      minFieldDirectorsRequiredToCompeteEvent: this.number(1),
      minScorersRequiredToCompeteEvent: this.number(0),

      isSync: this.boolean(false),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get COMPETITION_TYPE(){ return REF_COMPETITION_TYPE.query().where("code", this.competition_type).first(); }
  get LEVEL(){ return REF_LEVEL.query().where("code", this.level).first(); }
  get RULE(){ return REF_RULE.query().where("code", this.rule).first(); }
  get EVENT(){ return REF_EVENT.query().where("code", this.event).first(); }

}

/*------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Etat de la licence d'une personne" */
/*------------------------------------------------------------------------*/
class LICENSEE extends Model {
  static entity = "LICENSEE";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      federal_number: this.string(),
      year: this.number(),
      isValid: this.boolean(),
      isCompetitor: this.boolean(),
      isMedicalCertificateValid: this.boolean(),

      isSync: this.boolean(false),
    }
  }

  get SEASON() { return SEASON.query().where("year", this.year).first(); }
  get PEOPLE(){ return PEOPLE.query().where("federalNumber", this.federal_number).first(); }
}

/*************************************************************************/
/*     D O N N E E S   R E S U L T A T S   D E   C O M P E T I T I O N   */
/*************************************************************************/

/**
 * @class
 * @classdesc This is a class that can be used to define translatable error message identified with a code.
 */
class Error{
  _no = null;
  _fr = null;
  _en = null;
  _discriminant = null;

  /**
   * 
   * @constructor
   * @param {string} no - The code of the Error
   * @param {string} fr - The label of the Error (in French) 
   * @param {string} en  - The label of the Error (in English)
   */
  constructor(no, fr, en, discriminant = ''){ this._no = no; this._fr = fr; this._en = en; this._discriminant = discriminant; }

  /** 
   * The code (No) of the Error.
   * @returns {string} 
   */
  get NO(){ return this._no; }

  /**
   * The label of the error (in French).
   * @returns {string}
   */
  get FR(){ return this._fr;}

  /**
   * The label of the error (in English).
   * @returns {string}
   */
  get EN(){ return this._en;}

  /**
   * The discriminant (a value which can make 2 errors to be distinct even if they have the same NO).
   */
  get DISCRIMINANT(){ return this._discriminant; }

  /**
   * A computed key for the errors which results on a combination of the NO and the DISCRIMINANT.
   */
  get KEY(){ return this._no + ((this._discriminant != null && this._discriminant != '') ? '_' + this._discriminant : ''); }
}

class ErrorsBuilder{
  _errors = [];
  _stopIfAny = false;

  constructor(stopIfany){
    this._stopIfAny = stopIfany;
  }

  push(no, fr, en, discriminant){
    var error = new Error(no, fr, en, discriminant);
    this._errors.push(error);
    return this.stopIfAny;
  }

  get List(){
    return this._errors;
  }
}

/*--------------------------------------------------*/
/* Structure de stockage de la donnée "Compétition" */
/*--------------------------------------------------*/
class RST_COMPETITION extends Model{
  static entity = "RST_COMPETITION";

  static fields(){
    return {
      id: this.uid(()=>uuid()),
      /**
       * Name of the Competition.
       * @member 
       * @returns {String }
       */
      name: this.string(),
      /**
       * Year of the Competition.
       * @member
       * @returns {number}
       */
      year: this.number(),
      competition_type: this.string(),
      scope: this.string(),
      championship: this.string(null),
      predicted_start_date: this.attr(null),
      predicted_end_date: this.attr(null),
//      isLockedForChanges: this.string(),
      isLockedForChanges: this.boolean(false),
      isOpen:this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      isForSimulationPurposeOnly: this.boolean(false),
      location: this.string(),
      //roundAverageComputationRule: this.string(),
      //nbRoundToSkipOrKeep: this.number(),

      //isSync: this.boolean(false),
      regulation: this.string(),
      resultCalculationMode: this.string(),
      registrationMode: this.string(),
      isLockedForRegistration: this.boolean(false),
      isOver: this.boolean(false),
      chiefJudge_staff_id: this.attr(null),
      
      owner: this.attr(null),
    }
  }


  get ConfigurationErrors(){
    return this.getConfigurationErrors();
  }

  get hasErrors(){
    return this.getConfigurationErrors(true).length > 0
  }
  /**
   * 
   * @param {boolean} stopIfAny - Default : false
   * @returns {Error[]} 
   */
  getConfigurationErrors(stopIfAny = false)
  {
    var ret = [];
    //#region Basic errors

    if(this.year == null || this.year == '' || this.year == 0){
      ret.push(new Error('COMP_MISSING_YEAR', "L'année de la compétition est manquante.", "Year of the competition is required."));
      if(stopIfAny) return ret;
    }
    if(this.name == null || this.name == ''){
      ret.push(new Error('COMP_MISSING_NAME', 'Le nom de la compétition est manquant.', 'Competition name not set.'));
      if(stopIfAny) return ret;
    }
    if(this.location == null || this.location == ''){
      ret.push(new Error('COMP_MISSING_LOCATION', 'Le lieu de la compétition est manquant.', 'Competition location is not set.'));
      if(stopIfAny) return ret;
    }
    if(this.competition_type == null || this.competition_type == ''){
      ret.push(new Error('COMP_MISSING_TYPE', 'Le type de compétition est manquant.', 'Competition type is required.'));
      if(stopIfAny) return ret;
    }
    if(this.scope == null || this.scope == ''){
      ret.push(new Error('COMP_MISSING_SCOPE', "L'échelon est manquant.", 'Scope is required.'));
      if(stopIfAny) return ret;
    }
    if(this.LEVELS.length == 0){
      ret.push(new Error('COMP_MISSING_LEVELS', "Aucun niveau n'est défini pour la compétition.", "No level set."));
      if(stopIfAny) return ret;
    }
    if(this.regulation == null || this.regulation == ''){
      ret.push(new Error('COMP_MISSING_REGULATION', "Le règlement applicable pour cette compétition n'est pas défini.", 'The regulation to apply for this competition is required.'));
      if(stopIfAny) return ret;
    }
    if(this.resultCalculationMode == null || this.resultCalculationMode == ''){
      ret.push(new Error('COMP_MISSING_RESULT_CALCULATION_MODE', "Le mode de calcul du résultat final n'est pas défini pour cette compétition.", "Calculation mode for finale result is not set."));
      if(stopIfAny) return ret;
    }
    //#endregion Basic errors

    // Lorsque l'on arrive avec une ou plusieurs erreurs à cette étape, on n'est potentiellement pas en capacité de contrôler la suite
    // puisqu'il s'agit là d'erreurs basiques pouvant empêcher, par exemple, d'aller vérifier la cohérence des informations au niveau
    // des juges (nb. de juges requis en fonction du règlement, de l'échelon, du type de compétition, pour la saison donnée).
    // Si tel est le cas, on stop donc là l'analyse des erreurs
    if (ret.length > 0) return ret;

    //#region "Before run" errors
    
    // SANS DOUTE FAUDRAIT-IL REVOIR cette partie de vérification pour la baser sur les règles définies pour la saison et le type de compétition ou le règlement
    // A COURT TERME ON SE BASE SUR LES règles pour le CdF 2022
    if(this.STAFF_MEMBERS.length == 0){
      ret.push(new Error('COMP_NO_STAFF_MEMBER', "Aucun membre de staff n'a été sélectionné", "No staff member declared."));
      if(stopIfAny) return ret;
    }else{
      var nbJudges = this._STAFF_MEMBERS.where(s => s.CAPABILITIES.isJudge == true).get().length;
      if(nbJudges == 0){
        ret.push(new Error("COMP_NO_JUDGE", "Aucun potentiel juge n'a été sélectionné", "No possible judge is selected"));
        if(stopIfAny) return ret;
      } 
      var nbDTs = this._STAFF_MEMBERS.where(s => s.CAPABILITIES.isFieldDirector == true).get().length;
      if(nbDTs == 0){
          ret.push(new Error("COMP_NO_FIELD_DIRECTOR", "Aucun potentiel directeur de terrain n'a été sélectionné", "No possible Field Director is selected"));
          if(stopIfAny) return ret;
      }
      var nbJudgesOrDTs = this._STAFF_MEMBERS.where(s => s.CAPABILITIES.isJudge === true || s.CAPABILITIES.isFieldDirector === true).get().length;
      if(nbJudges == 1 && nbDTs == 1 && nbJudgesOrDTs == 1){
          ret.push(new Error('COMP_NO_DISTINCT_JUDGE_FD', "La compétition requiert au moins un juge et un directeur de terrain distincts", 'There should be at least one distinct judge and field director'));
          if(stopIfAny) return ret;
      }
      /*
      if(this.chiefJudge_staff_id == null){
        ret.push(new Error('COMP_MISSING_CHIEF_JUDGE', 'Chef juge non défini.', 'Chief Judge not set.'));
        if(stopIfAny) return ret;
      }*/
      var nbChiefJudges = this._STAFF_MEMBERS.where(s => s.isChiefJudge == true).get().length;
      if(nbChiefJudges == 0){
        ret.push(new Error("COM_MISSING_CHIEF_JUDGE", "Aucun juge n'est désigné comme chef juge de compétition", "No jugde set as Chief Judge for the competition."));
        if(stopIfAny) return ret;
      }
      if(nbChiefJudges > 2){
        ret.push(new Error('COMP_TOO_MUCH_CHIEF_JUDGE', 'Le nombre de chef juge de compétition ne devrait pas excéder 2', "No more than 2 judges should be set as Chief Judge for the competition"));
        if(stopIfAny) return ret;
      }
    }

    if(!this._COMPETITORS.exists())
    {
        ret.push(new Error('COMP_NO_COMPETITOR', "Aucun compétiteur inscrit dans aucune catégorie/niveau", 'No registration for any of the possible categories/levels'));
        if(stopIfAny) return ret;
    }else if(this.LEVELS.length > 1){
        this.LEVELS.forEach(lvl => {
          if(!RST_COMPETITOR.query().where('competition_id', this.id).where('level', lvl.level).exists()){
            ret.push(new Error('COMP_NO_LVL_COMPETITOR', "Aucun compétiteur inscrit pour le niveau " + lvl.name + ".", "No competitor set for level " + lvl.name + "."));
            if(stopIfAny) return ret;
          }
        })
    }

    if(!this._RST_REF_EVENTS.exists())
    {
      ret.push(new Error('COMP_NO_EVENT', "Aucune épreuve n'est définie.", "No event set."));
      if(stopIfAny) return ret;
    }else if(this.LEVELS.length > 1){
      this.LEVELS.forEach(lvl => {
        //var awaitedCompulsoriesCount = (lvl.level == "expert") ? 3 : 2;
        if(!RST_REF_EVENT.query().where('competition_id', this.id).where('level', lvl.level).exists()){
          ret.push(new Error('COMP_NO_LVL_EVENT', "Aucune épreuve définie pour le niveau " + lvl.name + ".", "No event set for level " + lvl.name + "."));
          if(stopIfAny) return ret;
        }
        /*
        var evtsWithCompulsories = RST_REF_EVENT.query().where('competition_id', this.id).where('level', lvl.level).where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get();
        if(evtsWithCompulsories.length > 0){
          var categs = lvl._CATEGORIES.where(c => c.effective_competitors_count > 0).get();
          evtsWithCompulsories.forEach(evt => {
            categs.forEach(cat => {
              var compSet = RST_EVENT_COMPULSORY.query().where("competition_id", this.id).where("round_number", evt.round_number).where("level", evt.level).where("category", cat.category).where("event", evt.event).get().length;
              var eventRef = evt.name + "-" + cat.CATEGORY.name + "-" + lvl.name;
              if(compSet == 0)
              {
                ret.push(new Error('COMP_NO_COMPULSORY_SET', "Aucune figure sélectionnée pour l'épreuve \"" + eventRef + "\".", "No compulsory set for event \"" + eventRef + "\"."));
                if(stopIfAny) return ret;
              } else if(compSet < awaitedCompulsoriesCount)
              {
                ret.push(new Error('COMP_NOT_ENOUGH_COMPULSORY_SET', "Sélection de figure(s) incomplète pour l'épreuve \"" + eventRef + "\" : " + compSet + " sélectionnée(s) / " + awaitedCompulsoriesCount + " attendue(s).", "Imconplete selection of compulsories for event \"" + eventRef + "\" : " + compSet + " selected / " + awaitedCompulsoriesCount + " awaited."));
                if(stopIfAny) return ret;
              }
            });
          });
        }
        */
      });
    }

    /*
    if(!this._DELEGATES.where('delegate_type', 'C').exists())
    {
      ret.push(new Error('COMP_NO_PILOTS_DELEGATE', "Pas de délégué de pilotes déclaré.", "No pilot delegate set."));
      if(stopIfAny) return ret;
    }
    */
    //#endregion "Before run" errors
    return ret;
  }
  //get isStarted(){ return RST_EVENT.query().where("competition_id", this.id).where("isStarted", true).exists(); }
  //set isStarted(val){ console.log("Set RST_COMPETITION.isStarted", val); }
  //get isCompleted(){ return this.isStarted && !RST_EVENT.query().where("competition_id", this.id).where("isCompleted", false); }
  //set isCompleted(val){ console.log("Set RST_COMPETITION.isCompleted", val); }

  get COMPETITION_TYPE(){
    return REF_COMPETITION_TYPE.query().where('code', this.competition_type).first();
  }
  get SCOPE(){
    return REF_SCOPE.query().where("code", this.scope).first();
  }
  get SEASON(){
    return SEASON.query().where("year", this.year).first();
  }
  get REF_CHAMPIONSHIP(){
    return REF_CHAMPIONSHIP.query().where('id', this.championship).first();
  }
  get CHAMPIONSHIP(){
    return SEASON_CHAMPIONSHIP_SCOPE.query().where("championship", this.championship).where("year", this.year).where("scope", this.scope).first();
  }

  get REGULATION(){
    return REF_REGULATION.query().where('code', this.regulation).first();
  }
  get RESULT_CALCULATION_MODE(){
    return REF_RESULT_CALCULATION_MODE.query().where('code', this.resultCalculationMode).first();
  }

  get _RST_REF_EVENTS(){
    return RST_REF_EVENT.query().where('competition_id', this.id);
  }

  get FIELDS(){
    return RST_FIELD.query().where('competition_id', this.id).get();
  }

  get _LEVELS(){
    return RST_LEVEL.query().where('competition_id', this.id);
  }
  get LEVELS(){
    return this._LEVELS.get();
  }

  get ROUNDS(){
    return RST_ROUND.query().where('competition_id', this.id).orderBy('round_number').get();
  }

  get _DELEGATES(){
    return RST_DELEGATE.query().where('competition_id', this.id);
  }
  get DELEGATES(){
    return this._DELEGATES.get();
  }
  get _PILOTS_DELEGATES(){
    return this._DELEGATES.where('delegate_type', 'C');
  }
  get PILOTS_DELEGATES(){
    return this._PILOTS_DELEGATES.get();
  }
  get PILOTS_DELEGATE(){
    return this._PILOTS_DELEGATES.first();
  }
  /**
   * Query for a List of the all the staff members declared to act for the whole competition
   * @returns {Query of RST_COMPETITION_STAFF}
   */
  get _STAFF_MEMBERS(){
    return RST_COMPETITION_STAFF.query()
                .where("competition_id", this.id)
                .where("round_number", null)
                .where('level', null)
                .where('category', null)
                .where('event', null)
  }

  /**
   * List of all the staff members declared to act for the whole competition
   * @returns {RST_COMPETITION_STAFF[]}
   */
  get STAFF_MEMBERS(){
    return this._STAFF_MEMBERS.get();
  }

  /**
   * Query for a List or all the people set to act as Administrator for the competition.
   * @returns {Query of RST_COMPETITION_ADMIN}
   */
  get _ADMINS(){
    return RST_COMPETITION_ADMIN.query().where('competition_id', this.id)
  }
  /**
   * List of all the people declared to act as Administrator for the competition.
   * @returns {RST_COMPETITION_ADMIN[]}
   */
  get ADMINS(){
    return this._ADMINS.get();
  }

  /**
   * Query for a list of all the competitors registered for the competition.
   * @returns {Query of RST_COMPETITOR}
   */
  get _COMPETITORS(){
    return RST_COMPETITOR.query().where("competition_id", this.id).where("round_number",null);
  }
  /**
   * List of all the competitors registered for the competition
   * @returns {RST_COMPETITOR[]}
   */
  get COMPETITORS(){
    return this._COMPETITORS.get();
  }

  get REGISTRATION_MODE(){
    return REF_REGISTRATION_MODE.query().where("code", this.registrationMode).get();
  }

  get competition_id(){ return this.id; }
  set competition_id(val){ this.id = val; }

  get OWNER(){
    return PEOPLE.query().where('visa', this.owner).first();
  }

  get CHIEF_JUDGE(){
    var unique = RST_COMPETITION_STAFF.query().where('competition_id', this.id).where(c => c.round_number == null).where('staff_id', this.chiefJudge_staff_id).first();
    if(unique != null)
      return unique;
    var firstOne = RST_COMPETITION_STAFF.query().where('competition_id', this.id).where(c => c.round_number == null).where(c => c.isChiefJudge == true).first();
    return firstOne;
  }

  get isFederal(){
    return this.COMPETITION_TYPE.isFederal;
  }
}

class RST_COMPETITION_ADMIN extends Model{
  static entity = "RST_COMPETITION_ADMIN"

  static fields(){
    return {
      competition_id: this.attr(),
      admin: this.attr(),

      id: this.attr()
    };
  }

  get COMPETITION(){
    return RST_COMPETITION.find(this.competition_id);
  }

  get _VISA(){
    return VISA.query().where("visa", this.admin).first();
  }

  get PEOPLE(){
    return VISA.query().where('visa', this.admin).first().PEOPLE;
  }

  get visa(){
    return this.admin;
  }

  get name(){
    return this.PEOPLE.name || '';
  }
}

/*------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Membre du staff d'une Compétition" */
/*------------------------------------------------------------------------*/
class RST_COMPETITION_STAFF extends Model{
  static entity = "RST_COMPETITION_STAFF";

  //static primaryKey = ["competition_id", "round_number", "event", "staff_id"];

  static fields(){
    return {
      competition_id : this.uid(),
      //event_id: this.attr(),
      round_number: this.attr(null),
      level: this.attr(null),
      category: this.attr(null),
      event: this.attr(null),
      staff_id: this.attr(),
      isChiefJudge: this.boolean(),
      isJudge: this.boolean(),
      isTechJudge: this.boolean(),
      isArtJudge: this.boolean(),
      isFieldDirector: this.boolean(),
      isScorer: this.boolean(),
      isShadow: this.boolean(),

      //COMPETITION: this.belongsTo(RST_COMPETITION, 'competition_id'),

      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get EVENT(){
    return RST_EVENT.query()
                        .where("competition_id", this.competition_id)
                        .where("round_number", this.round_number)
                        .where("level", this.level)
                        .where("category", this.category)
                        .where("event", this.event)
                        .first();
    //return RST_EVENT.query().where("id", this.event_id).first();
  }
  get STAFF_MEMBER(){ return STAFF.query().where("id", this.staff_id).first(); }

  get PEOPLE(){ return this.STAFF_MEMBER.PEOPLE; }

  get CAPABILITIES(){ return STAFF_CAPABILITY.query()
                                .where("competition_type", this.COMPETITION.competition_type)
                                .where('scope', this.COMPETITION.scope)
                                .where('staff_id', this.staff_id)
                                .first();}
  get hasAction(){
    if(this.event != null && this.EVENT.isStarted){
      return this.isChiefJudge || this.isJudge || this.isTechJudge || this.isArtJudge || this.isFieldDirector || this.isScorer;
    }
    var found = RST_COMPETITION_STAFF.query()
                    .where("competition_id", this.competition_id)
                    .where((item) => { return item.event != null && item.EVENT.isStarted
                                        && (item.isChiefJudge || item.isJudge || item.isTechJudge || item.isArtJudge || item.isFieldDirector || item.isScorer)
                    }).exists();
    return found;
  }

  get isPlannedForEvent(){
    console.log('isPlannedForEvent', this.event);
    if(this.event && this.event != null && this.event.trim() != '') return true;
    return RST_COMPETITION_STAFF.query()
              .where("competition_id", this.competition_id)
              .where("staff_id", this.staff_id)
              .where(item => item.event != null && item.event.trim() != '')
              .exists();
  }

  get lastname(){ return this.STAFF_MEMBER.PEOPLE.lastname; }
  get firstname(){ return this.STAFF_MEMBER.PEOPLE.firstname; }
  get name(){ return this.STAFF_MEMBER.PEOPLE.name; }
  get shortname(){ return this.STAFF_MEMBER.PEOPLE.shortname; }
  get visa(){ return this.STAFF_MEMBER.PEOPLE.visa; }
}

/*-----------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Compétiteur pour une compétition" */
/*-----------------------------------------------------------------------*/
class RST_COMPETITOR extends Model{
  static entity = "RST_COMPETITOR";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "people_id"];

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      round_number: this.attr(null),
      subscription_order: this.number(0), /** NEW **/
      random_order: this.number(0),       /** NEW **/
      predicted_order: this.number(0),    /** NEW **/
      effective_order: this.number(0),    /** NEW **/
      order: this.number(0),              /** DROP **/
      category: this.attr(null),
      level: this.attr(null),
      isSelected: this.attr(false),
      isOpen: this.boolean(false),
      isWithdrawn: this.boolean(false),
      isForfeited: this.boolean(false),
      isAbsent: this.boolean(false),
      note: this.attr(null),
      rank: this.attr(null),
      federal_rank: this.attr(null),
      
      precision_note: this.attr(null),
      precision_rank: this.attr(null),
      precision_federal_rank: this.attr(null),

      ballet_note: this.attr(null),
      ballet_rank: this.attr(null),
      ballet_federal_rank: this.attr(null),

      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }
  get LEVEL(){ return RST_LEVEL.query()
                  .where("competition_id", this.competition_id)
                  .where("round_number", this.round_number)
                  .where("level", this.level)
                  .first();
  }
  get MEMBERS(){
    if(this.COMPETITOR.isIndividual)
      return null;
    return RST_COMPETITOR_COMPOSITION.query()
      .where("competition_id", this.competition_id)
      //.where("round_number", this.round_number)
      .where("competitor_id", this.competitor_id)
      .orderBy("memberOrder")
      .get();
  }
  get name(){ return this.COMPETITOR.name; }
  get shortname(){ return this.COMPETITOR.shortname; }

  get CATEGORY(){ return REF_CATEGORY.query().where("code", this.category).first(); }

  hasAtLeastOneNote(round_number = null){
    var notes = RST_JUDGE_EVENT_NOTES.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => !(item.compulsories_total == null
                                   && item.routine_execution == null
                                   && item.routine_content == null
                                   && item.ballet_execution == null
                                   && item.ballet_choreo == null));
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;

    notes = RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => item.noteEntry != null);
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;

    return false;
  }

  get isEffective(){return !(this.isAbsent || this.isWithdrawn || this.isForfeited); }

  get isFederal(){
    var ret = this.COMPETITION.isFederal && !this.isOpen;
    return ret;
  }
}

/*------------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Membre(s) de compétiteur pour une compétition" */
/*------------------------------------------------------------------------------------*/
class RST_COMPETITOR_COMPOSITION extends Model{
  static entity = "RST_COMPETITOR_COMPOSITION";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "people_id"];

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      round_number: this.attr(null),
      people_id: this.attr(null),
      memberOrder: this.number(null),
      
      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get RST_COMPETITOR(){ return RST_COMPETITOR.query()
                      .where("competition_id", this.competition_id)
                      .where("competitor_id", this.competitor_id)
                      //.where("round_number", this.round_number)
                      .first();
  }
  get COMPETITOR(){ return COMPETITOR.query()
                      .where('competitor_id', this.competitor_id)
                      .first();
  }
  get PEOPLE(){ return PEOPLE.query().where("id", this.people_id).first(); }
  get LICENSEE(){ return LICENSEE.query().where("federal_number", this.PEOPLE.federalNumber).where("year", this.COMPETITION.year).first(); }
}

/*---------------------------------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Note sur figure de précision pour un compétiteur d'une compétition" */
/*---------------------------------------------------------------------------------------------------------*/
class RST_JUDGE_EVENT_COMPULSORY_NOTE extends Model
{
  static entity = "RST_JUDGE_EVENT_COMPULSORY_NOTE";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "level", "category", "compulsory_id", "judge_id"];

  static fields(){
    return {
      competitor_id: this.uid(),

      competition_id: this.uid(),
      round_number: this.attr(null),
      level: this.string(),
      category: this.string(),
      event: this.string(),

      compulsory_id: this.uid(),

      judge_id: this.attr(null),

      noteEntry: this.attr(null),
      noteValue: this.attr(null),
      rank: this.attr(null),
      federal_rank: this.attr(null),
      isCompleted: this.boolean(false),

      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }

  get RST_COMPETITOR(){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', this.round_number)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    if(ret == null)
        ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', null)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    return ret;
  }

  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }

  get RST_JUDGE() {
    if (this.judge_id == null)
      return null;

    return RST_COMPETITION_STAFF.query()
                        .where("competition_id", this.competition_id)
                        //.where('round_number', this.round_number)
                        //.where("level", this.level)
                        //.where("category", this.category)
                        .where("staff_id", this.judge_id)
                        .first();
  }

  get JUDGE(){
    return (this.judge_id == null) ? null : STAFF.query().where("id", this.judge_id).first();
  }

  get COMPULSORY() { return RST_EVENT_COMPULSORY.query().where("id", this.compulsory_id).first(); }
}

/*-----------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Notes  pour un compétiteur d'une compétition" */
/*-----------------------------------------------------------------------------------*/
class RST_JUDGE_EVENT_NOTES extends Model{
  static entity = "RST_JUDGE_EVENT_NOTES";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "level", "category", "judge_id" ];

  static fields(){
    return {
      competitor_id: this.uid(),

      competition_id: this.uid(),
      round_number: this.attr(null),
      level: this.string(),
      category: this.string(),
      event: this.string(),

      judge_id: this.attr(null),

      compulsories_total: this.attr(null),
      compulsories_rank: this.attr(null),
      compulsories_federal_rank: this.attr(null),
      compulsories_isCompleted: this.boolean(false),

      routine_execution: this.attr(null),
      routine_execution_rank: this.attr(null),
      routine_execution_federal_rank: this.attr(null),
      routine_content: this.attr(null),
      routine_content_rank: this.attr(null),
      routine_content_federal_rank: this.attr(null),
      routine_total: this.attr(null),
      routine_total_rank: this.attr(null),
      routine_total_federal_rank: this.attr(null),
      routine_isCompleted: this.boolean(false),

      precision_total: this.attr(null),
      precision_total_rank: this.attr(null),
      precision_total_federal_rank: this.attr(null),
      precision_isCompleted: this.boolean(false),

      ballet_execution: this.attr(null),
      ballet_execution_rank: this.attr(null),
      ballet_execution_federal_rank: this.attr(null),
      ballet_choreo: this.attr(null),
      ballet_choreo_rank: this.attr(null),
      ballet_choreo_federal_rank: this.attr(null),
      ballet_total: this.attr(null),
      ballet_total_rank: this.attr(null),
      ballet_total_federal_rank: this.attr(null),
      ballet_isCompleted: this.boolean(false),

      overall: this.attr(null),
      overall_rank: this.attr(null),
      overall_federal_rank: this.attr(null),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),

      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }
  get RST_COMPETITOR(){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', this.round_number)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    if(ret == null)
        ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', null)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    return ret;
  }

  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }

  get RST_JUDGE() {
    if (this.judge_id == null)
      return null;

    return RST_COMPETITION_STAFF.query()
                        .where("competition_id", this.competition_id)
                        //.where('round_number', this.round_number)
                        //.where("level", this.level)
                        //.where("category", this.category)
                        .where("staff_id", this.judge_id)
                        .first();
  }

  get JUDGE(){
    return (this.judge_id == null) ? null : STAFF.query().where("id", this.judge_id).first();
  }
}

/*---------------------------------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Note sur figure de précision pour un compétiteur d'une compétition" */
/*---------------------------------------------------------------------------------------------------------*/
class RST_COMPETITOR_COMPULSORY_NOTE extends Model
{
  static entity = "RST_COMPETITOR_COMPULSORY_NOTE";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "level", "category", "compulsory_id", "judge_id"];

  static fields(){
    return {
      competitor_id: this.uid(),
      competition_id: this.uid(),
      round_number: this.attr(null),
      compulsory_id: this.uid(),
      level: this.string(),
      category: this.string(),
      //judge_id: this.attr(null),
      noteEntry: this.attr(null),
      noteValue: this.attr(null),
      rank: this.attr(null),
      federal_rank: this.attr(null),
      isCompleted: this.boolean(false),
      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }

  get RST_COMPETITOR(){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', this.round_number)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    if(ret == null)
        ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', null)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    return ret;
  }

  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }

  /*
  get RST_JUDGE() {
    if (this.judge_id == null)
      return null;

    return RST_COMPETITION_STAFF.query()
                        .where("competition_id", this.competition_id)
                        //.where('round_number', this.round_number)
                        //.where("level", this.level)
                        //.where("category", this.category)
                        .where("staff_id", this.judge_id)
                        .first();
  }

  get JUDGE(){
    return (this.judge_id == null) ? null : STAFF.query().where("id", this.judge_id).first();
  }*/

  get COMPULSORY() { return RST_EVENT_COMPULSORY.query().where("id", this.compulsory_id).first(); }
}

/*-----------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Notes  pour un compétiteur d'une compétition" */
/*-----------------------------------------------------------------------------------*/
class RST_COMPETITOR_NOTES extends Model{
  static entity = "RST_COMPETITOR_NOTES";

  //static primaryKey = ["competition_id", "competitor_id", "round_number", "level", "category", "judge_id" ];

  static fields(){
    return {
      competitor_id: this.uid(),
      competition_id: this.uid(),
      round_number: this.attr(null),
      level: this.string(),
      category: this.string(),
      //judge_id: this.attr(null),
      compulsories_total: this.attr(null),
      compulsories_rank: this.attr(null),
      compulsories_federal_rank: this.attr(null),
      compulsories_isCompleted: this.boolean(false),

      routine_execution: this.attr(null),
      routine_execution_rank: this.attr(null),
      routine_execution_federal_rank: this.attr(null),
      routine_content: this.attr(null),
      routine_content_rank: this.attr(null),
      routine_content_federal_rank: this.attr(null),
      routine_total: this.attr(null),
      routine_total_rank: this.attr(null),
      routine_total_federal_rank: this.attr(null),
      routine_isCompleted: this.boolean(false),

      precision_total: this.attr(null),
      precision_total_rank: this.attr(null),
      precision_total_federal_rank: this.attr(null),
      precision_isCompleted: this.boolean(false),

      ballet_execution: this.attr(null),
      ballet_execution_rank: this.attr(null),
      ballet_execution_federal_rank: this.attr(null),
      ballet_choreo: this.attr(null),
      ballet_choreo_rank: this.attr(null),
      ballet_choreo_federal_rank: this.attr(null),
      ballet_total: this.attr(null),
      ballet_total_rank: this.attr(null),
      ballet_total_federal_rank: this.attr(null),
      ballet_isCompleted: this.boolean(false),

      overall: this.attr(null),
      overall_rank: this.attr(null),
      overall_federal_rank: this.attr(null),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),

      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }
  get RST_COMPETITOR(){
    var ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', this.round_number)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    if(ret == null)
        ret = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where('round_number', null)
                .where("level", this.level)
                .where("category", this.category)
                .where("competitor_id", this.competitor_id)
                .first();
    return ret;
  }

  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }
/*
  get RST_JUDGE() {
    if (this.judge_id == null)
      return null;

    return RST_COMPETITION_STAFF.query()
                        .where("competition_id", this.competition_id)
                        //.where('round_number', this.round_number)
                        //.where("level", this.level)
                        //.where("category", this.category)
                        .where("staff_id", this.judge_id)
                        .first();
  }

  get JUDGE(){
    return (this.judge_id == null) ? null : STAFF.query().where("id", this.judge_id).first();
  }*/
}

class RST_REF_EVENT extends Model{
  static entity = "RST_REF_EVENT";

//  static primaryKey = 'id';

  static fields(){
    return {
      competition_id: this.attr(),
      level: this.attr(),
      event: this.attr(),
      round_number: this.attr(),
      isBriefingStarted: this.boolean(false),
      isBriefingCompleted: this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      //displayOrder: this.boolean(false),

      isSync: this.boolean(false),

      id: this.uid(()=>uuid()),
    };
  }

  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  //get ROUND() { return RST_ROUND.query().where('competition_id', this.competition_id).where("round_number", this.round_number).first(); }
  get LEVEL() { return RST_LEVEL.query().where('competition_id', this.competition_id)/*.where("round_number", this.round_number)*/.where("level", this.level).first(); }
  get LEVEL_TYPE() { return REF_LEVEL.query().where('code', this.level).first();}
  //  get CATEGORY() { return RST_CATEGORY.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).first(); }
  get EVENT() { return REF_EVENT.query().where("code", this.event).first(); }

  get shortname(){ return this.EVENT.shortname + '-' + this.round_number; }
  get name(){ return this.EVENT.name + '-' + this.round_number; }
  get longname(){ return this.EVENT.longname + '-' + this.round_number; }
  get displayOrder(){ return this.EVENT.displayOrder; }
  
  get RST_EVENT(){
    return RST_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("event", this.event).first();
  }
}
/*-----------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Epreuve d'une manche d'une compétition" */
/*-----------------------------------------------------------------------------*/
class RST_EVENT extends Model{
  static entity = "RST_EVENT";

  //static primaryKey = ['competition_id', 'round_number', 'level', 'category', 'event'];

  static fields(){
    return {
      competition_id: this.attr(),
      round_number: this.attr(),
      category: this.attr(),
      level: this.attr(),
      event: this.attr(),
      chiefJudge_staff_id: this.attr(null),
      isBriefingStarted: this.boolean(false),
      isBriefingCompleted: this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      current_competitor_id: this.attr(null),
      current_step: this.attr(null),
      current_compulsory_id: this.attr(null),
      isShowingStepSummary: this.boolean(false),
      field_number: this.attr(),
      field_stage_in: this.attr(),
      field_stage_out: this.attr(),

      isSync: this.boolean(false),

      id: this.uid(()=>uuid()),
    }
  }
  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get ROUND() { return RST_ROUND.query().where('competition_id', this.competition_id).where("round_number", this.round_number).first(); }
  get LEVEL() { return RST_LEVEL.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).first(); }
  get CATEGORY()
  {
    return REF_CATEGORY.query().where('code', this.category).first();
    //return RST_CATEGORY.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).first();
  }
  get EVENT() { return REF_EVENT.query().where("code", this.event).first(); }
  get REF_EVENT() { return this.EVENT; }
  get EVENT_TYPE() { return this.EVENT; }
/*  get COMPETITORS() {
    var ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).get();
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", null).where("level", this.level).where("category", this.category).get();
    if(ret == null) ret = [];
      return ret;
  }*/
  get _COMPETITORS(){
    return RST_EVENT_COMPETITOR.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("event", this.event);
  }
  get COMPETITORS(){
    return this._COMPETITORS.orderBy("effective_order").get();
  }
  get _EFFECTIVE_COMPETITORS(){
    return this._COMPETITORS.where(c => !c.isAbsent && !c.isForfeited);
  }
  get EFFECTIVE_COMPETITORS(){
    return this._EFFECTIVE_COMPETITORS.orderBy("effective_order").get();
  }

  get CURRENT_COMPETITOR(){
    var comp = this.COMPETITORS.filter(comp => comp.competitor_id == this.current_competitor_id);
    return (comp != null && comp.length == 1) ? comp[0] : null;
  }
  get CURRENT_COMPULSORY(){
    var comp = this.COMPULSORIES.filter(comp => comp.compulsory_id == this.current_compulsory_id);
    return (comp != null && comp.length == 1) ? comp[0] : null;
  }

  get _STAFF_MEMBERS(){
    return RST_COMPETITION_STAFF
                .query()
                .where("competition_id", this.competition_id)
                .where("round_number", this.round_number)
                .where('level', this.level)
                .where('category', this.category)
                .where('event', this.event);
  }
  get STAFF_MEMBERS(){
    return this._STAFF_MEMBERS.get();
  }
  get CHIEF_JUDGE(){
    if(this.chiefJudge_staff_id == null)
      return null;
    return this._STAFF_MEMBERS.where("staff_id", this.chiefJudge_staff_id).first();
  }

  get JUDGES(){ return this.STAFF_MEMBERS.filter(item => item.isJudge); }
  get ART_JUDGES(){ return this.STAFF_MEMBERS.filter(item => item.isArtJudge); }
  get TECH_JUDGES() { return this.STAFF_MEMBERS.filter(item => item.isArtJudge); }
  get FIELD_DIRECTORS() { return this.STAFF_MEMBERS.filter(item => item.isFieldDirector); }
  get SCORERS() { return this.STAFF_MEMBERS.filter(item => item.isScorer); }
  get RULE(){ return this.CATEGORY.RULE; }
  get COMPULSORIES(){ return RST_EVENT_COMPULSORY.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category",this.category).where("event", this.event).orderBy("order").get(); }
  get PREVIOUS_ROUND_EVENT(){
    if(this.round_number <= 1)
      return null;
    return RST_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number-1).where("level", this.level).where("category",this.category).first();
  }
  get event_code(){ return this.event; }
  set event_code(val){ this.event = val; }
  get current_compulsory_index(){
    return (this.CURRENT_COMPULSORY != null)
           ? this.COMPULSORIES.findIndex(item => item.compulsory_id == this.CURRENT_COMPULSORY.compulsory_id)
           : null;
  }

  get skipWorseNote(){
    return this.ROUND.applySkipWorseNoteRule;
  }

  get skipBestNote(){
    return this.ROUND.applySkipBestNoteRule;
  }

  get configurationErrors(){
    return this.getConfigurationErrors();
  }

  get hasErrors(){
    return this.getConfigurationErrors(true).length > 0;
  }

  getConfigurationErrors(stopIfAny=false){
    var ret = [];

    if(this.JUDGES.filter(j => !j.isShadow).length == 0)
    {
      ret.push(new Error('EVT_NO_JUDGE', "Aucun juge \"titulaire\" affecté pour l'épreuve", "No judge set for the event"));
      if(stopIfAny) return ret;
    }

    if(this.FIELD_DIRECTORS.filter(fd => !fd.isShadow).length == 0)
    {
      ret.push(new Error('EVT_NO_FIELD_DIRECTOR', "Aucun directeur de terrain \"titulaire\" affecté à l'épreuve", "No field director is set for the event"));
      if(stopIfAny) return ret;
    }

    if(this.chiefJudge_staff_id == null || this.chiefJudge_staff_id == ''){
      ret.push(new Error('EVT_NO_CHIEF_JUDGE', "Le chef juge de l'épreuve n'est pas choisi", "Event chief judge not set"));
      if(stopIfAny) return ret;
    }else if(this.JUDGES.findIndex(j => j.staff_id == this.chiefJudge_staff_id) < 0){
      ret.push(new Error('EVT_NO_CHIEF_JUDGE', "Le chef juge de l'épreuve n'est pas choisi", "Event chief judge not set"));
      if(stopIfAny) return ret;
    } else if(this.JUDGES.find(j => j.staff_id == this.chiefJudge_staff_id).isShadow){
      ret.push(new Error('EVT_SHADOW_CHIEF_JUDGE', "Le chef juge de l'épreuve choisi doit être titulaire, pas ombre", "Event selected chief judge must not be shadow"));
      if(stopIfAny) return ret;
    }

    if(this.ROUND.hasErrors)
    {
      ret.push(new Error('EVT_ERRORS_ON_ROUND', 'La manche ou la compétion comportent des erreurs non corrigées.', 'Some error(s) found on round or competition themselves that need to be corrected.'))
    }
    return ret;
  }

  get isReadyToRun(){
    if(this.hasErrors) return false;

    return this.COMPETITION._DELEGATES.where('delegate_type', 'C').exists();
  }
  canBeLaunchedByUser(visa, includingFieldDirectors = false){
    var ret = includingFieldDirectors;
    ret = this.CHIEF_JUDGE != null && this.CHIEF_JUDGE.visa == visa;
/*    var ret = this.JUDGES.findIndex(j => !j.isShadow && j.visa == visa) >= 0;
    if(!ret && includingFieldDirectors)
      ret = this.FIELD_DIRECTORS.findIndex(j => !j.isShadow && j.visa == visa) >= 0;*/
    return ret;
  }
}

/*-----------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Compétiteur pour une compétition" */
/*-----------------------------------------------------------------------*/
class RST_EVENT_COMPETITOR extends Model{
  static entity = "RST_EVENT_COMPETITOR";

  static get ExecutionStatus_Waiting(){ return 'W' }
  static get ExecutionStatus_Running(){ return 'R' }
  static get ExecutionStatus_Completed(){ return 'C' }
  
  //static primaryKey = ["competition_id", "competitor_id", "round_number", "people_id"];

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      level: this.attr(null),
      category: this.attr(null),
      round_number: this.attr(null),
      event: this.attr(null),
      predicted_order: this.number(0),    /** NEW **/
      order: this.number(0),              /** DROP **/
      effective_order: this.number(0),    /** NEW **/
      isForfeited: this.boolean(false),
      isAbsent: this.boolean(false),
//      note: this.attr(null),
      executionStatus: this.string(RST_EVENT_COMPETITOR.ExecutionStatus_Waiting), // W=waiting, R=running, C=completed


      note: this.attr(null),
      rank: this.attr(null),
      federal_rank: this.attr(null),
      
      precision_note: this.attr(null),
      precision_rank: this.attr(null),
      precision_federal_rank: this.attr(null),

      ballet_note: this.attr(null),
      ballet_rank: this.attr(null),
      ballet_federal_rank: this.attr(null),

      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }
  
  get hasCompletedEvent(){ return this.executionStatus == RST_EVENT_COMPETITOR.ExecutionStatus_Completed; }
  get isRunningEvent() { return this.executionStatus == RST_EVENT_COMPETITOR.ExecutionStatus_Running; }
  get isWaiting() { return this.executionStatus == RST_EVENT_COMPETITOR.ExecutionStatus_Waiting; }
  get subscription_order(){ return this.RST_COMPETITOR.subscription_order; }

  get isFederal(){
    return this.RST_COMPETITOR.isFederal;
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }
  get RST_COMPETITOR() { return RST_COMPETITOR.query().where("competition_id", this.competition_id).where("competitor_id", this.competitor_id).where("level", this.level).where("category", this.category).first(); }
  /*get LEVEL(){ return RST_LEVEL.query()
                  .where("competition_id", this.competition_id)
                  .where("round_number", this.round_number)
                  .where("level", this.level)
                  .first();
  }*/
  /*get MEMBERS(){
    if(this.COMPETITOR.isIndividual)
      return null;
    return RST_COMPETITOR_COMPOSITION.query()
      .where("competition_id", this.competition_id)
      //.where("round_number", this.round_number)
      .where("competitor_id", this.competitor_id)
      .orderBy("memberOrder")
      .get();
  }*/
  get name(){ return this.COMPETITOR.name; }
  get shortname(){ return this.COMPETITOR.shortname; }

  hasAtLeastOneNote(round_number = null){
    var notes = RST_JUDGE_EVENT_NOTES.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => !(item.compulsories_total == null
                                   && item.routine_execution == null
                                   && item.routine_content == null
                                   && item.ballet_execution == null
                                   && item.ballet_choreo == null));
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;

    notes = RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => item.noteEntry != null);
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;

    return false;
  }

}

/**
 * Structure de stockage d'une note de ballet pour un compétiteur lors d'une épreuve.
 * Représente la note total (moyenne de tous les juges) lorsque le champ judge_id est null.
 * Représente la note d'un juge donné lorsque le champ judge_id n'est pas null.
 */
class RST_BALLET_NOTE extends Model{
  static entity = "RST_BALLET_NOTE";

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      level: this.attr(null),
      category: this.attr(null),
      round_number: this.attr(null),
      event: this.attr(null),
      judge_id: this.attr(null),   
      note: this.attr(null),
      isShadowNote: this.boolean(false),
      rank: this.attr(null),
      isFederal: this.boolean(true),
      federal_rank: this.attr(null),
      execution: this.attr(null),
      execution_rank: this.attr(null),
      execution_federal_rank: this.attr(null),
      choreo: this.attr(null),
      choreo_rank: this.attr(null),
      choreo_federal_rank: this.attr(null),
      penalty: this.attr(null),
      penalties: this.attr(null),
      cartouche: this.attr(null),
      grossTotal: this.attr(null),
      grossExecution: this.attr(null),
      grossChoreo: this.attr(null),

      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }
  
  get isCompleted(){
    return this.note != null || (this.penalties != null && this.penalties.zero);
  }

  get JUDGE(){
    if(this.judge_id == null) return null;
    return STAFF.query().where(s => s.id == this.judge_id).first();
  }

  get EVENT(){
    return RST_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("event", this.event).first();
  }

  get ROUND(){
    return RST_ROUND.query().where("competition_id", this.competition_id).where("round_number", this.round_number).first();
  }

  get COMPETITION(){
    return RST_COMPETITION.query().where("id", this.competition_id).first();
  }
}

/**
 * Structure de stockage d'une note de figure imposée pour un compétiteur lors d'une épreuve.
 * Représente la note total (moyenne de tous les juges) lorsque le champ judge_id est null.
 * Représente la note d'un juge donné lorsque le champ judge_id n'est pas null.
 * Conjointement, représente le total des n figures imposées de l'épreuve lorsque le champ compulsory_id est null.
 * Alors que l'enregistrement représente une figure en particulier lorsque le champ compulsory_id n'est pas null.
 * On peut donc trouver :
 *    - judge_id null ET compulsory_id null => Total des notes moyennes (tout juge confondu) des n figures de l'épreuve
 *    - judge_id non null et compulsory_id null => Total des n figures de l'épreuve pour le juge donné
 *    - judge_id null et compulsory_id non null => Moyenne des notes (tout juge confondu) pour la figure donnée
 *    - judge_id non null et compulsory_id non null => Note du juge donné pour la figure donnée
 */
class RST_COMPULSORY_NOTE extends Model{
  static entity = "RST_COMPULSORY_NOTE";
  
  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      level: this.attr(null),
      category: this.attr(null),
      round_number: this.attr(null),
      event: this.attr(null),
      judge_id: this.attr(null),   
      compulsory_number: this.attr(null),  
      compulsory_id: this.attr(null),   
      isAverage: this.boolean(false),
      noteEntry: this.attr(null),
      noteValue: this.attr(null),
      isShadowNote: this.boolean(false),
      rank: this.attr(null),
      isFederal: this.boolean(true),
      federal_rank: this.attr(null),
      penalty: this.attr(null),
      penalties: this.attr(null),
      cartouche: this.attr(null),

      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }
  
  get isCompleted(){
    return this.noteValue != null || this.isAverage || (this.penalties != null && this.penalties.zero);
  }

  get JUDGE(){
    if(this.judge_id == null) return null;
    return STAFF.query().where(s => s.id == this.judge_id).first();
  }

  get EVENT(){
    return RST_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("event", this.event).first();
  }

  get ROUND(){
    return RST_ROUND.query().where("competition_id", this.competition_id).where("round_number", this.round_number).first();
  }

  get COMPETITION(){
    return RST_COMPETITION.query().where("id", this.competition_id).first();
  }
}

/**
 * Structure de stockage d'une note de ballet pour un compétiteur lors d'une épreuve.
 * Représente la note total (moyenne de tous les juges) lorsque le champ judge_id est null.
 * Représente la note d'un juge donné lorsque le champ judge_id n'est pas null.
 */
 class RST_ROUTINE_NOTE extends Model{
  static entity = "RST_ROUTINE_NOTE";

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      level: this.attr(null),
      category: this.attr(null),
      round_number: this.attr(null),
      event: this.attr(null),
      judge_id: this.attr(null),   
      note: this.attr(null),
      isShadowNote: this.boolean(false),
      rank: this.attr(null),
      isFederal: this.boolean(true),
      federal_rank: this.attr(null),
      execution: this.attr(null),
      execution_rank: this.attr(null),
      execution_federal_rank: this.attr(null),
      content: this.attr(null),
      content_rank: this.attr(null),
      content_federal_rank: this.attr(null),
      penalty: this.attr(null),
      penalties: this.attr(null),
      cartouche: this.attr(null),
      grossTotal: this.attr(null),
      grossExecution: this.attr(null),
      grossContent: this.attr(null),
      
      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }
  
  get isCompleted(){
    return this.note != null || (this.penalties != null && this.penalties.zero);
  }

  get JUDGE(){
    if(this.judge_id == null) return null;
    return STAFF.query().where(s => s.id == this.judge_id).first();
  }

  get EVENT(){
    return RST_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("event", this.event).first();
  }

  get ROUND(){
    return RST_ROUND.query().where("competition_id", this.competition_id).where("round_number", this.round_number).first();
  }

  get COMPETITION(){
    return RST_COMPETITION.query().where("id", this.competition_id).first();
  }
}
/*---------------------------------------------*/
/* Structure de stockage de la donnée "Manche" */
/*---------------------------------------------*/
class RST_ROUND extends Model{
  static entity = 'RST_ROUND';

  //static primaryKey = ['competition_id', 'round_number', 'level', 'category'];

  static fields(){
    return {
      competition_id: this.uid(),
      round_number: this.number(),
      competitors_order_rule: this.attr(),
      applySkipWorseNoteRule: this.boolean(false),
      applySkipBestNoteRule: this.boolean(false),
      isBriefingStarted : this.boolean(false),
      isBriefingCompleted: this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      date: this.attr(null),
      chiefJudge_staff_id: this.attr(null),
      isDebriefingCompletedOrSkipped: this.attr(null),
      id: this.uid(()=>uuid()),

      isSync: this.boolean(false),
    }
  }

  get _STAFF_MEMBERS(){
    return RST_COMPETITION_STAFF
                .query()
                .where("competition_id", this.competition_id)
                .where("round_number", this.round_number)
                .where('level', this.level)
                .where('category', this.category)
                .where(s => s.event != null);
  }
  get STAFF_MEMBERS(){
    return this._STAFF_MEMBERS.get();
  }

  get canBePrepared(){
    return !this.hasErrors;
  }

  /** Read-only indicator that is set to trus when everything is ok to let the briefind being done */
  get isReadyToBriefing(){
    if(this.isCompleted) return false;
    if(this.isBriefingCompleted) return false;
    if(this.chiefJudge_staff_id == null) return false;
    if(this.COMPETITION.hasErrors) return false;
    if(this.hasErrors) return false;
    return true;
  }

  /** Read-only indicator that is set to true when everything is ok to let the round be started
   * @returns {Boolean}
   */
  get isReadyToProcess(){
    if(this.isCompleted) return false;
    if(this.isBriefingCompleted) return true;
    if(this.chiefJudge_staff_id == null) return false;
    if(this.COMPETITION.hasErrors) return false;
    if(this.isBriefingStarted && !this.hasErrors) return true;
    return !this.hasErrors; 
  }

  /** Read-only indicator that is set to true when everything is ok to let the round be ran (executed) 
   * @returns {Boolean}
  */
  get isReadyToRun(){
    return this.isReadyToProcess && this.isBriefingCompleted;
  }

  /** Property that list all the configuration errors which may prevent from executing the round
   * @returns { Error[] }
   */
  get configurationErrors(){
    return this.getConfigurationErrors();
  }

  /** Read-only indicator that is set to true when there is at least one configuration error preventing the round from being executed
   * @returns { Boolean }
   */
  get hasErrors(){
    return this.getConfigurationErrors(true).length > 0;
  }

  /** Method which return the list of possible configuration errors preventing the round from being executed.
   * @param { Boolean } stopIfAny - Flag used to force the method to stop as soon as it detect an error. The default value is false.
   * @returns { Error[] }
   */
  getConfigurationErrors(stopIfAny = false)
  {
    var ret = [];

    if((ret.length == 0 || !stopIfAny) && this.chiefJudge_staff_id == null){
      ret.push(new Error('RND_MISSING_CHIEF_JUDGE', 'Chef juge non défini pour la manche.', 'Chief Judge is required.'));
    }
    if((ret.length == 0 || !stopIfAny) && this.competitors_order_rule == null){
      ret.push(new Error('RND_MISSING_EXE_ORDER_RULE', 'Ordre de passage des compétiteurs non défini pour la manche.', 'Execution order is required.'));
    }

    if((ret.length == 0 || !stopIfAny) && this.COMPETITION._RST_REF_EVENTS.exists())
    {
      var singleLevel = this.COMPETITION.LEVELS.length == 1;

      this.COMPETITION.LEVELS.forEach(lvl => {
        var awaitedCompulsoriesCount = (lvl.level == "expert") ? 3 : 2;
        var evtsWithCompulsories = RST_REF_EVENT.query().where('competition_id', this.competition_id).where('round_number', this.round_number).where('level', lvl.level).where(e => e.EVENT.hasCompulsories).orderBy(e => e.EVENT.displayOrder).get();
        //console.log('configurationErrors', {events: evtsWithCompulsories, round: this});
        if(evtsWithCompulsories.length > 0){
          var categs = lvl._CATEGORIES.where(c => c.effective_competitors_count > 0).get();
          evtsWithCompulsories.forEach(evt => {
            categs.forEach(cat => {
              var compSet = RST_EVENT_COMPULSORY.query().where("competition_id", this.competition_id).where("round_number", this.round_number).where("level", evt.level).where("category", cat.category).where("event", evt.event).get().length;
              var eventRef = evt.name + "-" + cat.CATEGORY.name;
              var discr = evt.name + evt.level + cat.category + this.round_number;
              if(!singleLevel) eventRef = eventRef + "-" + lvl.name;
              if(compSet == 0)
              {
                ret.push(new Error('RND_NO_COMPULSORY_SET', "Aucune figure sélectionnée pour l'épreuve \"" + eventRef + "\".", "No compulsory set for event \"" + eventRef + "\".", discr));
                if(stopIfAny) return ret;
              } else if(compSet < awaitedCompulsoriesCount)
              {
                ret.push(new Error('RND_NOT_ENOUGH_COMPULSORY_SET', "Sélection de figure(s) incomplète pour l'épreuve \"" + eventRef + "\" : " + compSet + " sélectionnée(s) / " + awaitedCompulsoriesCount + " attendue(s).", "Imconplete selection of compulsories for event \"" + eventRef + "\" : " + compSet + " selected / " + awaitedCompulsoriesCount + " awaited.", discr));
                if(stopIfAny) return ret;
              }
            });
          });
        }
      });
    }

    if((ret.length == 0 || !stopIfAny) && (this.round_number > 1 || this.isBriefingStarted) && !this.COMPETITION._DELEGATES.where('delegate_type', 'C').exists())
    {
      ret.push(new Error('COMP_NO_PILOTS_DELEGATE', "Pas de délégué de pilotes déclaré.", "No pilot delegate set."));
      if(stopIfAny) return ret;
    } else if((ret.length == 0 || !stopIfAny) && this.COMPETITION.hasErrors){
      ret.push(new Error('RND_ERRORS_ON_COMPETITION', 'La compétition comporte des erreurs non corrigées.', 'Some error(s) found on competition itself that need to be corrected.'))
    }
    
    return ret;
  }

/*
  get isStarted(){ return RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("isStarted", true).exists(); }
  set isStarted(val){ console.log("Set RST_ROUND.isStarted", val); }
  get isCompleted(){ return this.isStarted && !RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("isCompleted", false); }
  set isCompleted(val){ console.log("Set RST_ROUND.isCompleted", val); }
*/
  get COMPETITION () { return RST_COMPETITION.query().where('id', this.competition_id).first(); }

  get COMPETITORS() {
    var ret = RST_ROUND_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", this.round_number).get();
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).get()
            .map(item => {
                  item.id = uuid();
                  item.round_number = this.round_number;
                  return item;
            });
    if(ret == null) ret = [];
      return ret;
  }

  /** Navigation property to access to the RST_COMPETITION_STAFF object corresponding to the Chief Judge for the Round 
   * @returns { RST_COMPETITION_STAFF }
  */

  get RST_REF_EVENTs(){
    return RST_REF_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number).get();
  }

  get CHIEF_JUDGE(){
    return RST_COMPETITION_STAFF.query()
      .where("competition_id", this.competition_id)
      .where("staff_id", this.chiefJudge_staff_id)
      .where("event", null)
      .first();
  }

  /** Property which provides a predefined query to access to all the RST_REF_EVENT objects linked to the Round 
   * @return { Query for RST_REF_EVENT }
  */
  get _EVENTS(){
    return RST_REF_EVENT.query().where("competition_id", this.competition_id).where("round_number", this.round_number);
  }

  /** Property listing all the RST_REF_EVENT objects linked to the Round, sorted on their REF_EVENT's displayOrder property 
   * @returns { RST_REF_EVENT[] }
  */
  get EVENTS(){
    return this._EVENTS.orderBy(e => e.EVENT.displayOrder).get();
  }

  get areAllEventsCompleted(){
    return !RST_EVENT.query().where('competition_id', this.competition_id).where('round_number', this.round_number).where(e => !e.isCompleted).exists();
  }
}

/*-----------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Compétiteur pour une compétition" */
/*-----------------------------------------------------------------------*/
class RST_ROUND_COMPETITOR extends Model{
  static entity = "RST_ROUND_COMPETITOR";

  static fields(){
    return {
      competition_id: this.uid(),
      competitor_id: this.uid(),
      level: this.attr(null),
      category: this.attr(null),
      round_number: this.attr(null),
      predicted_order: this.number(0),    /** NEW **/
      isForfeited: this.boolean(false),
      isAbsent: this.boolean(false),

      note: this.attr(null),
      rank: this.attr(null),
      federal_rank: this.attr(null),
      
      precision_note: this.attr(null),
      precision_rank: this.attr(null),
      precision_federal_rank: this.attr(null),

      ballet_note: this.attr(null),
      ballet_rank: this.attr(null),
      ballet_federal_rank: this.attr(null),

      id: this.uid(() => uuid()),

      isSync: this.boolean(false),
    }
  }
  
  get subscription_order(){ return this.RST_COMPETITOR.subscription_order; }

  get isFederal(){
    return this.RST_COMPETITOR.isFederal;
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get COMPETITOR(){ return COMPETITOR.query().where("id", this.competitor_id).first(); }
  get RST_COMPETITOR() { 
    console.log('RST_ROUND_COMPETITOR.RST_COMPETITOR (a)');
    var ret = RST_COMPETITOR.query().where("competition_id", this.competition_id).where("competitor_id", this.competitor_id)/*.where("level", this.level).where("category", this.category).first()*/; 
    console.log('RST_ROUND_COMPETITOR.RST_COMPETITOR', ret);
    return ret;
  }

  get name(){ return this.COMPETITOR.name; }
  get shortname(){ return this.COMPETITOR.shortname; }

  /** DEPRECATED ! */
  hasAtLeastOneNote_TODEL(round_number = null){
/*    var notes = RST_JUDGE_EVENT_NOTES.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => !(item.compulsories_total == null
                                   && item.routine_execution == null
                                   && item.routine_content == null
                                   && item.ballet_execution == null
                                   && item.ballet_choreo == null));
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;

    notes = RST_JUDGE_EVENT_COMPULSORY_NOTE.query()
                  .where("competition_id", this.competition_id)
                  .where("competitor_id", this.competitor_id)
                  .where(item => item.noteEntry != null);
    if(round_number != null)
      notes = notes.where("round_number", this.round_number);
    else if(this.round_number != null)
      notes = notes.where('round_number', this.round_number);

    if(notes.exists())
      return true;
*/
console.log('TODEL DEPRECATED hasAtLeastOneNote', round_number);
    return false;
  }
}


/*-------------------------------------------------------------*/
/* Structure de stockage de la donnée "Niveau dans une Manche" */
/*-------------------------------------------------------------*/
class RST_LEVEL extends Model {
  static entity = "RST_LEVEL";

  static fields(){
    return {
      competition_id: this.uid(),
      round_number: this.number(),
      level: this.string(),
      rule: this.attr(null),
      applySkipWorseNoteRule: this.boolean(false),
      applySkipBestNote: this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      id: this.uid(()=>uuid()),
      isSync: this.boolean(false),
    }
  }

  //get isStarted(){ return RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("level", this.level).where("isStarted", true).exists(); }
  //set isStarted(val){ console.log("Set RST_LEVEL.isStarted", val); }
  //get isCompleted(){ return this.isStarted && !RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("level", this.level).where("isCompleted", false); }
  //set isCompleted(val){ console.log("Set RST_LEVEL.isCompleted", val); }

  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get ROUND() { return RST_ROUND.query().where('competition_id', this.competition_id).where("round_number", this.round_number).first(); }
  get LEVEL() { return REF_LEVEL.find(this.level); }// REF_LEVEL.query().where("code", this.level).first(); }
  get RULE() {
      return (this.rule == null)
                ? null
                : SEASON_RULE.query().where("competition_type", this.COMPETITION.competition_type).where("year", this.COMPETITION.year).where("rule", this.rule).first();
  }

  get _CATEGORIES(){
    return RST_REF_CATEGORY.query().where('competition_id', this.competition_id).where('level', this.level);
  }
  get CATEGORIES(){
    return this._CATEGORIES.orderBy(c => c.CATEGORY.displayOrder).get();
  }
 /* get COMPETITORS() { return RST_COMPETITOR.query()
    .where('competition_id', this.competition_id)
    .where("round_number", this.round_number)
    .where("level", this.level)
    .get();
  }*/
  get _COMPETITORS(){
    return this.ROUND._COMPETITORS.where("level", this.level);
  }
  get COMPETITORS() {
    return this._COMPETITORS.get();
    /*
    var ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).get();
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", null).get()
      .map(item => {
        item.id = uuid();
        item.round_number = this.round_number;
        item.level = this.level;
        return item;
    });
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).get()
      .map(item => {
        item.id = uuid();
        item.round_number = this.round_number;
        item.level = this.level;
        return item;
    });

    if(ret == null) ret = [];
      return ret;*/
  }
  get code(){ return this.level; }
  get name(){ return this.LEVEL.name; }
}

class RST_REF_CATEGORY extends Model{
  static entity = "RST_REF_CATEGORY";

  static fields(){
    return {
      competition_id: this.uid(),
      level: this.string(),
      category: this.string(),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),
      subscribers_count: this.number(0),
      effective_competitors_count: this.number(0),
      withdraw_count: this.number(0),
      forfeit_count: this.number(0),
      absent_count: this.number(0),

      id: this.uid(()=>uuid()),
      isSync: this.boolean(false),
    }
  }

  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get LEVEL() { return RST_LEVEL.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).first(); }
  get CATEGORY() { return REF_CATEGORY.query().where("code", this.category).first(); }

  get _COMPETITORS(){
    var ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("level", this.level).where("category", this.category);
    return ret;
  }
  get COMPETITORS() {
    var ret = this._COMPETITORS.get();
    return ret;
  }

  get EFFECTIVE_COMPETITORS(){
    return this.COMPETITORS.filter(competitor => {
      return !(competitor.isWithdrawn || competitor.isForfeited || competitor.isAbsent);
    })
  }

}

/*-------------------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Catégorie pour un niveau dans une Manche" */
/*-------------------------------------------------------------------------------*/
class RST_CATEGORY extends Model{
  static entity = "RST_CATEGORY";

  static fields(){
    return {
      competition_id: this.uid(),
      round_number: this.number(),
      level: this.string(),
      category: this.string(),
      rule: this.attr(null),
      applySkipWorseNoteRule: this.boolean(false),
      applySkipBestNote: this.boolean(false),
      isStarted: this.boolean(false),
      isCompleted: this.boolean(false),

      id: this.uid(()=>uuid()),
      isSync: this.boolean(false),
    }
  }

 // get isStarted(){ return RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).where("isStarted", true).exists(); }
 // set isStarted(val){ console.log("Set RST_CATEGORY.isStarted", val); }
 // get isCompleted(){ return this.isStarted && !RST_EVENT.query().where("competition_id", this.id).where("round_number", this.round_number).where("level", this.level).where("category").where("isCompleted", false); }
 // set isCompleted(val){ console.log("Set RST_CATEGORY.isCompleted", val); }

  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get ROUND() { return RST_ROUND.query().where('competition_id', this.competition_id).where("round_number", this.round_number).first(); }
  get LEVEL() { return RST_LEVEL.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).first(); }
  get CATEGORY() { return REF_CATEGORY.query().where("code", this.category).first(); }
  get RULE() {
      return (this.rule == null)
                ? this.LEVEL.RULE
                : SEASON_RULE.query().where("competition_type", this.COMPETITION.competition_type).where("year", this.COMPETITION.year).where("rule", this.rule).first();
  }
  get COMPULSORIES(){
      if(!this.RULE.hasCompulsories)
        return null;
      return RST_EVENT_COMPULSORY.query()
                .where('competition_id', this.competition_id)
                .where("round_number", this.round_number)
                .where("level", this.level)
                .where("category", this.category)
                .orderBy("order")
                .get();
  }

  get _COMPETITORS(){
    return this.ROUND._COMPETITORS.where("level", this.level).where("category", this.category);
  }
  get COMPETITORS() {
    return this._COMPETITORS.orderBy("predicted_order").get();
    /*
    var ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).get();
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("round_number", null).where("category", this.category).get();
    if(ret == null || ret.length == 0)
      ret = RST_COMPETITOR.query().where('competition_id', this.competition_id).where("category", this.category).get();
    if(ret == null) ret = [];
      return ret;*/
  }
  get code(){ return this.category; }

  get _EFFECTIVE_COMPETITORS(){
    return this._COMPETITORS.where(c => !(c.isWithdrawn || c.isForfeited || c.isAbsent));
  }
  get EFFECTIVE_COMPETITORS(){
    return this._EFFECTIVE_COMPETITORS.orderBy("predicted_order").get();
    /*return this.COMPETITORS.filter(competitor => {
      return !(competitor.isWithdrawn || competitor.isForfeited || competitor.isAbsent);
    })*/
  }

}

/*------------------------------------------------------------------*/
/* Structure de stockage de la donnée "Figure imposée d'une manche" */
/*------------------------------------------------------------------*/
class RST_EVENT_COMPULSORY extends Model{
  static entity = 'RST_EVENT_COMPULSORY';

  // static primaryKey = ['competition_id', 'round_number', 'level', 'category', 'compulsory_id'];

  static fields(){
    return {
      competition_id: this.uid(),
      round_number: this.number(),
      level: this.string(),
      event: this.string(),
      category: this.string(),
      compulsory_id: this.uid(),
      order: this.number(),

      isSync: this.boolean(false),

      id: this.uid(()=>uuid())
    }
  }
  get COMPETITION() { return RST_COMPETITION.query().where('id', this.competition_id).first(); }
  get ROUND() { return RST_ROUND.query().where('competition_id', this.competition_id).where("round_number", this.round_number).first(); }
  get LEVEL() { return RST_LEVEL.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).first(); }
  get REF_EVENT() { return REF_EVENT.query().where('code', this.event).first(); }
  get CATEGORY() { return RST_CATEGORY.query().where('competition_id', this.competition_id).where("round_number", this.round_number).where("level", this.level).where("category", this.category).first(); }
  get COMPULSORY() { return REF_COMPULSORY.query().where("id", this.compulsory_id).first(); }
  get code() { return this.COMPULSORY.code; }
  get name() { return this.COMPULSORY.name; }
  get number() { return this.order; }
}


class RST_FIELD extends Model {
  static entity = "RST_FIELD";

  static fields(){
    return {
      competition_id: this.attr(),
      field_number: this.attr(),
      field_name: this.attr(),
      size_A: this.number(0),
      size_B: this.number(0),
      isSonorized: this.boolean(false),
      stage_in: this.string(),
      stage_out: this.string(),
      isMain: this.boolean(false),
      isHot: this.boolean(false),

      id: this.uid(()=>uuid()),
      isSync: this.boolean(false),
      synchro: this.string(),
    }
  }

  get COMPETITION(){ return RST_COMPETITION.query().where('id', this.competition_id).first(); }
}

class RST_DELEGATE extends Model{
  static entity = "RST_DELEGATE";

  static fields(){
    return {
      competition_id: this.attr(),
      people_id: this.attr(),
      delegate_type: this.attr('C'),

      id: this.uid(() => uuid()),
      isSync: this.boolean(false),
    }
  }

  get PEOPLE(){
    return PEOPLE.query().where(p => p.id == this.people_id).first();
  }

  get COMPETITION(){
    return RST_COMPETITION.query().where(c => c.id == this.competition_id).first();
  }
}

class RST_LOG extends Model {
  static entity = "RST_LOG";

  static fields(){
    return {
      id: this.attr(),

      object_type: this.string(),
      operation: this.string(),
      object_id: this.attr(),
      value: this.attr(),
      action: this.attr(),
      lastError: this.attr(),

      timestamp: this.attr(),
      user: this.string(),
      inBehalfOf: this.string(),

      synchronizationPoint: this.attr(null),
    };
  }
}

class REF_GN_NOTATION_GRID extends Model{
  static entity = "REF_GN_NOTATION_GRID";

  static fields(){
    return {
      id: this.attr(),

      name: this.string(),
      description: this.attr(),
      isArchived: this.boolean(),
      isDegracedMode: this.boolean(),
      criterionDefaultMinimumValue: this.attr(),
      criterionDefaultMaximumValue: this.attr(),
      criterionDefaultValueStep: this.attr(),
    };
  }

  get CRITERIONS(){
    var ret = REF_GN_CRITERION.query().where('grid_id', this.id).get();
    return ret == null ? [] : ret;
  }
  get PENALTIES(){
    var ret = REF_GN_PENALTY.query().where('grid_id', this.id).where('criterion_id', null).get();
    return ret == null ? [] : ret;
  }
}

class REF_GN_CRITERION extends Model{
  static entity = "REF_GN_CRITERION";

  static fields(){
    return {
      id: this.attr(),

      grid_id: this.attr(),
      parent_criterion_id: this.attr(),

      name: this.string(),
      description: this.string(),
      isEntryStep: this.boolean(),
      isComputed: this.boolean(),
      isPrecision: this.boolean(),
      isCompulsory: this.boolean(),
      minimumValue: this.number(),
      maximumValue: this.number(),
      valueStep: this.attr(),
      pound: this.number(),
      isValueRequired: this.boolean(),
      nonSetDisplayedValue: this.attr(),
      isOptional: this.boolean(),
      displayOrder: this.number(),
    };
  }
  get GRID() { return REF_GN_NOTATION_GRID.query().where('id', this.grid_id).first(); }
  get PARENT() {
                if(this.parentCriterionId != null)
                  return REF_GN_CRITERION.query().where('id', this.parentCriterionId).first();
                return null;
              }
  get PENALTIES(){
    var ret = REF_GN_PENALTY.query().where('criterion_id', this.id).get();
    return ret == null ? [] : ret;
  }

  get CHILDREN() {
    return REF_GN_CRITERION.query().where('parentCriterionId', this.id).get();
  }

  get HasChild(){
    return REF_GN_CRITERION.query().where('parentCriterionId', this.id).length > 0;
  }
}

class REF_GN_PENALTY extends Model{
  static entity = "REF_GN_PENALTIES";

  static fields(){
    return {
      id: this.attr(),

      grid_id: this.attr(),
      criterion_id: this.attr(),

      name: this.string(),
      canBeSetBy: this.attr(),
      value: this.number(),
      isValueAbsolute: this.boolean(),
      canBeSetMultipleTimes: this.boolean(),
      isArchived: this.boolean(),
    };
  }

  get GRID() { return REF_GN_NOTATION_GRID.query().where('id', this.grid_id).first(); }
  get PARENT() { return this.criterion_id != null
                                              ? REF_GN_CRITERION.query().where('id', this.criterion_id).first()
                                              : null; }

}

class VISA extends Model{
  static entity = 'VISA';

  static fields(){
    return {
      id: this.attr(),
      visa: this.attr(),
    }
  }

  get PEOPLE(){ return PEOPLE.query().where("User_Id", this.id).first(); }
}

class DBTools{
  
  static cleanUpLocalDB(includeMetaData=false, includePersonData=false, includeSeasonConfigurationData=false){
    //DATA_SYNC_SUBSCRIPTIONS,

    if(includeMetaData){
      VISA.deleteAll();
  
      REF_REGULATION.deleteAll();
      REF_REGISTRATION_MODE.deleteAll();
      REF_RESULT_CALCULATION_MODE.deleteAll();
      REF_CATEGORY.deleteAll();
      REF_COMPETITION_TYPE.deleteAll();
      REF_COMPETITION_TYPE_SCOPE.deleteAll();
      REF_CHAMPIONSHIP.deleteAll();
      REF_COMPULSORY.deleteAll();
      REF_EVENT.deleteAll();
      REF_LEVEL.deleteAll();
      REF_RULE.deleteAll();
      REF_SCOPE.deleteAll();
  
      REF_GN_NOTATION_GRID.deleteAll();
      REF_GN_CRITERION.deleteAll();
      REF_GN_PENALTY.deleteAll();
    }

    if(includePersonData){
      PEOPLE.deleteAll();
      STAFF.deleteAll();
      STAFF_CAPABILITY.deleteAll();
      COMPETITOR.deleteAll();
      COMPETITOR_MEMBER.deleteAll();
      RST_DELEGATE.deleteAll();
    }

    if(includePersonData || includeSeasonConfigurationData){
      LICENSEE.deleteAll();
    }


    if(includeSeasonConfigurationData){
      SEASON.deleteAll();
      SEASON_COMPETITION_TYPE_SCOPE.deleteAll();
      SEASON_CHAMPIONSHIP_SCOPE.deleteAll();
      SEASON_COMPETITION_TYPE_SCOPE_LEVEL.deleteAll();
      SEASON_COMPETITION_TYPE_SCOPE_EVENT.deleteAll();
      SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.deleteAll();
      SEASON_COMPULSORY.deleteAll();
      SEASON_RULE.deleteAll();
      SEASON_RULE_EVENT.deleteAll();
    }

    RST_CATEGORY.deleteAll();
    RST_COMPETITION.deleteAll();
    RST_COMPETITION_ADMIN.deleteAll();
    RST_COMPETITION_STAFF.deleteAll();
    RST_REF_EVENT.deleteAll();
    RST_REF_CATEGORY.deleteAll();
    RST_COMPETITOR.deleteAll();
    RST_COMPETITOR_COMPOSITION.deleteAll();
    RST_JUDGE_EVENT_COMPULSORY_NOTE.deleteAll();
    RST_JUDGE_EVENT_NOTES.deleteAll();
    RST_COMPETITOR_COMPULSORY_NOTE.deleteAll();
    RST_COMPETITOR_NOTES.deleteAll();
    RST_EVENT.deleteAll();
    RST_EVENT_COMPETITOR.deleteAll();
    RST_LEVEL.deleteAll();
    RST_ROUND.deleteAll();
    RST_EVENT_COMPULSORY.deleteAll();
    RST_FIELD.deleteAll();
    RST_LOG.deleteAll();
    RST_COMPULSORY_NOTE.deleteAll();
    RST_ROUTINE_NOTE.deleteAll();
    RST_BALLET_NOTE.deleteAll();
    RST_DELEGATE.deleteAll();
  }
}
export {
  DBTools,

  DATA_SYNC_SUBSCRIPTIONS,

  VISA,

  REF_REGULATION,
  REF_PENALTY,
  REF_REGISTRATION_MODE,
  REF_RESULT_CALCULATION_MODE,
  REF_CATEGORY,
  REF_COMPETITION_TYPE,
  REF_COMPETITION_TYPE_SCOPE,
  REF_CHAMPIONSHIP,
  REF_COMPULSORY,
  REF_EVENT,
  REF_LEVEL,
  REF_RULE,
  REF_SCOPE,

  REF_GN_NOTATION_GRID,
  REF_GN_CRITERION,
  REF_GN_PENALTY,
  //--------------
  PEOPLE,
  STAFF,
  STAFF_CAPABILITY,
  COMPETITOR,
  COMPETITOR_MEMBER,
  //--------------
  SEASON,
  SEASON_COMPETITION_TYPE_SCOPE,
  SEASON_COMPETITION_TYPE_SCOPE_LEVEL,
  SEASON_COMPETITION_TYPE_SCOPE_EVENT,
  SEASON_COMPETITION_TYPE_CATEGORY_LEVEL,
  SEASON_CHAMPIONSHIP_SCOPE,
  SEASON_COMPULSORY,
  SEASON_RULE,
  SEASON_RULE_EVENT,
  LICENSEE,
  //--------------
  RST_CATEGORY,
  RST_COMPETITION,
  RST_COMPETITION_ADMIN,
  RST_COMPETITION_STAFF,
  RST_REF_EVENT,
  RST_REF_CATEGORY,
  RST_COMPETITOR,
  RST_COMPETITOR_COMPOSITION,
  RST_ROUND_COMPETITOR,
  RST_JUDGE_EVENT_COMPULSORY_NOTE,
  RST_JUDGE_EVENT_NOTES,
  RST_COMPETITOR_COMPULSORY_NOTE,
  RST_COMPETITOR_NOTES,
  RST_EVENT,
  RST_EVENT_COMPETITOR,
  RST_BALLET_NOTE,
  RST_COMPULSORY_NOTE,
  RST_ROUTINE_NOTE,
  RST_LEVEL,
  RST_ROUND,
  RST_EVENT_COMPULSORY,
  RST_FIELD,
  RST_LOG,
  RST_DELEGATE,
};

