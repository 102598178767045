<template>
  <div v-if="isMobileApp">
    <slot></slot>
  </div>
</template>

<script>
//import { Capacitor } from "@capacitor/core";
//import config from "@/services/store/modules/config.js";
import { mapGetters } from 'vuex';

export default {
//  name: 'MobileAppArea',
  computed:{
    ...mapGetters('config', ['isNativePlatform', 'isMobileAppSimulationMode']),
    isMobileApp(){ 
      return this.isNativePlatform || this.isMobileAppSimulationMode;
      //return Capacitor.isNativePlatform(); 
    }
  }
}
</script>


