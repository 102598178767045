<template>
  <div v-if="isWeb">
    <slot></slot>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { mapGetters } from 'vuex';

export default {
  name: 'WebArea',
  computed:{
    ...mapGetters('config', ['isMobileAppSimulationMode']),
    isWeb(){ return !Capacitor.isNativePlatform() && !this.isMobileAppSimulationMode; }
  }
}
</script>


