<template>
  <div>
    <p><b>Accès refusé</b></p>
    <p>Vous n'êtes pas autorisé à accéder à cette partie de l'application.</p>
  </div>
</template>

<script>
  export default {
    name: "AccessDenied"
  }
</script>
