<template>
    <b-modal
      v-model="showDialog"
      title="Connexion"
      centered
      :static="false"
      :lazy="true"
      size="sm"
      button-size="lg"
      ref="dialog"
    >
      <slot name="prepend"></slot>
      <fieldset class="fieldset">
          <b-form-group label="Identifiant">
              <b-form-input type="text" v-model="identifiant" @keypress="cleanError"/>
          </b-form-group>

          <b-form-group label="Mot de passe">
              <b-form-input type="password" v-model="password" @keypress="cleanError"/>
          </b-form-group>

          <b-alert
            :show="lastLoginError != null && lastLoginError != ''"
            variant="danger"
          >{{ lastLoginError }}
          </b-alert>
      </fieldset>
      <slot name="postpend"></slot>
          <template #modal-footer="{cancel}">
            <b-button @click="cancel()" variant="secondary">Annuler</b-button>
            <b-button :disabled="!canLogin" @click="submitCredentials" variant="primary">Connexion</b-button>
          </template>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    props:{ value: { type:Boolean, default:false }
    },
    data(){
        return {
            identifiant: '',
            password: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['lastLoginError', 'isAuthenticated']),
        canLogin(){
            return this.identifiant.trim() != '' && this.password.trim() != '';
        },
        showDialog:{
          get(){
            return this.value && !this.isAuthenticated;
          },
          set(){

          }
        }
    },
    methods:{
        ...mapActions('auth', ['login']),
        ...mapMutations('auth', ['setLastLoginError']),
        submitCredentials(){
         const user = { loginName: this.identifiant, password: this.password };
          if(!this.login(user)){
            if(this.lastLoginError == null || this.lastLoginError == '')
//              this.value="false";
            this.hide();
          }
          else
            this.hide();
        },
        cleanError(){
            this.setLastLoginError('');
        },
        show(){
          //console.log('show() invoked');
          this.$refs.dialog.show();
        },
        hide(){
          //console.log('hide() invoked');
          this.$refs.dialog.hide();
        },
    },
    mounted(){
        this.cleanError();
    }
}
</script>
