const RolesEnum = {
    Admin : 'Admin',
    Developper : 'Developper',
    Competitor : 'Competitor',
    Guest : 'Guest',
    Tester : 'Tester',
    Staff : 'StaffMember',
    TechAdmin : 'TechAdmin'
}

const CompetitorExecOrderRuleEnum = {
  Alea: 'Alea',
  Alpha: 'Alpha',
  AlphaReverse: 'AlphaReverse',
  PrevRoundReverse: 'PrevRoundReverse',
  Rank: 'Rank',
  RankReverse: 'RankReverse',
  Subscription: 'Registration',
  Registration: 'Registration',
  getDisplayName: function(value){
    switch(value){
      case this.Registration:
          return "Ordre d'inscription";
      case this.Alea:
          return "Ordre aléatoire";
      case this.Alpha:
          return "Ordre alphabétique";
      case this.AlphaReverse:
          return "Ordre alphabétique inversé";
      case this.PrevRoundReverse:
          return "Ordre inverse du tour précédent";
      case this.Rank:
          return "Classement provisoire";
      case this.RankReverse:
          return "Classement provisoire inversé";
    }
  },
}

const EventExecutionStepEnum = {
  Compulsories: 'compulsories',
  Routine: 'routine',
  Ballet: 'ballet',
}
export { RolesEnum, CompetitorExecOrderRuleEnum, EventExecutionStepEnum }
