<template>
  <secured-area hideWhenAccessIsRejected :authenticationRequired="isAuthenticationRequired" :operations="dOperations">
      <li class="side-bar-groupitem-menu-header" v-show="title && title.trim()!=''">
        <b-iconstack v-show="collapsable" font-scale="0.9" class="collapse-btn" @click="contentShown=!contentShown" style="margin-top:1px;">
          <b-icon stacked icon="circle" ></b-icon>
          <b-icon stacked :icon="collapseIcon" scale="0.65" ></b-icon>
        </b-iconstack>&nbsp;
        <slot name="title-text"><span @click="contentShown=!contentShown">{{ title }}</span></slot>
        <b-collapse class="side-bar-groupitem-menu-content" v-model="isVisible">
          <ul>
          <slot>
          </slot>
          </ul>
        </b-collapse>
      </li>
  </secured-area>
</template>

<script>
import SecuredArea from '../Security/NewSecuredArea.vue';

  export default{
  components: { SecuredArea },
    props:{
      title: { type:String, default:null },
      contentShown: { type:Boolean, default: true, },
      collapsable: { type:Boolean, default: true },
      authenticationRequired: { type:Boolean, default: false},
      operations: {type:Array },
    },
    computed:{
      isVisible: {
        get() {
          if(!this.collapsable) return true;
          return this.contentShown;
        },
        set (newVal){
          this.contentShown = newVal;
        }
      },
      isAuthenticationRequired:{
        get() {
          if(this.operations && this.operations.length > 0) return true;
          return this.authenticationRequired
        }
      },
      collapseIcon(){
        return this.isVisible ? 'chevron-down' : 'chevron-right';
      }
    },
    data(){
      return {
        contentVisible: this.contentShown,
        dOperations: this.operations && Array.isArray(this.operations) ? this.operations : [],
      }
    }
  }
</script>

<style scoped>
  /*.fixed{ position:fixed; width:auto; min-width:300px;}*/
  .side-bar-groupitem-menu-header { /*padding-left: 5px;*/cursor:pointer; margin-left:-20px; padding-right: 5px; /*border-radius: 3px; border: solid 1px var(--ffvlOrangePicto)*/ }
  .side-bar-groupitem-menu-content { overflow-wrap: auto; /*background-color: var(--dark); *//*whitesmoke;*/ /*border-radius: 3px; border: solid 1px var(--ffvlOrangePicto); margin-left:0px; margin-right:0px;*/ width:100%; }
  .side-bar-groupitem-menu-header > svg { color: var(--ffvlOrangePicto);}
  /*.side-bar-groupitem-menu-content ul > li { list-style-type: none; }*/
  /*.side-bar-groupitem-menu-content ul:first-child { margin-block-start: 5px; padding-inline-start: 5px;}*/
  li.side-bar-groupitem-menu-header {  /*background-color: var(--ffvlOrangeLight);*/ color: var(--ffvlSecondary); font-weight: normal; list-style-type: none; font-size:0.9em; }
  li.side-bar-groupitem-menu-header li::before{ content: "\2022"; color: var(--ffvlOrangePicto); font-weight: bold; display: inline-block; width:1em; margin-left:-1em;  }
  li.side-bar-groupitem-menu-header::before{ content:' ' !important;};
</style>
