<template>
    <platform-area>
        <template #web>
            <app-web @backToHome="backToHome" @showAccount="showAccount" @showLogin="showLogin" @disconnect="disconnect" @doSynchronizeNow="doSynchronizeNow">
                <template #environment-marker>
                    <slot name="environment-marker" isMobileApp="false"></slot>
                </template>
                <template #synchro-indicator-placeholder>
                    <slot name="synchro-indicator-placeholder" isMobileApp="false"></slot>
                </template>
                <template #test-mode-indicator>
                    <slot name="test-mode-indicator" isMobileApp="false"></slot>
                </template>
                <template #impersonation-mode-indicator>
                    <slot name="impersonation-mode-indicator" isMobileApp="false"></slot>
                </template>
                <template #sidebar-menu-placeholder>
                    <slot name="sidebar-menu-placeholder" isMobileApp="false"></slot>
                </template>
                <template #authenticated-icon-placeholder>
                    <slot name="authenticated-icon-placeholder" isMobileApp="false"></slot>
                </template>
                <slot isMobileApp="false"></slot>
            </app-web>
        </template>
        <template #mobile>
            <app-mobile @backToHome="backToHome" @showAccount="showAccount" @showLogin="showLogin" @disconnect="disconnect" @doSynchronizeNow="doSynchronizeNow">
                <template #environment-marker>
                    <slot name="environment-marker" isMobileApp="true"></slot>
                </template>
                <template #synchro-indicator-placeholder>
                    <slot name="synchro-indicator-placeholder" isMobileApp="true"></slot>
                </template>
                <template #sidebar-menu-placeholder>
                    <slot name="sidebar-menu-placeholder" isMobileApp="true"></slot>
                </template>
                <slot isMobileApp="true"></slot>
            </app-mobile>
        </template>
    </platform-area>
</template>

<script>
import PlatformArea from '@/components/PlatformArea.vue';
import AppMobile from '@/views/app.mobile.vue';
import AppWeb from '@/views/app.web.vue';

export default{
    components:{ PlatformArea, AppWeb, AppMobile },
    methods:{
        backToHome(){
            this.$emit('backToHome');
        },
        showAccount(){
            this.$emit('showAccount');
        },
        showLogin(){
            this.$emit('showLogin');
        },
        disconnect(){
            this.$emit('disconnect');
        },
        doSynchronizeNow(){
            this.$emit('doSynchronizeNow');
        }
    }
}
</script>

