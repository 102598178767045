<template>
    <!--<div>
        <div class="helperNote" :class="variant">
            <div class="text">
                <slot>
                </slot>
            </div>
        </div>
    </div>-->
    <div class="helperNote">
    <b-alert show :variant="variant">
      <slot></slot>
    </b-alert>
    </div>
</template>

<script>

export default {
  props: {
    variant: { type:String, default: 'info'}

  }
}
</script>

<style scoped>
    /*.helperNote{ background-color: black; }
    .helperNote.primary { background-color: var(--primary); }
    .helperNote.secondary { background-color: var(--secondary); }
    .helperNote.warning { background-color: var(--warning); }
    .helperNote.danger { background-color: var(--danger); }
    .helperNote.success { background-color: var(--success); }
    .helperNote.info { background-color: var(--info); }
    .helperNote.light { background-color: var(--light); }
    .helperNote.dark { background-color: var(--dark); }
    .helperNote > .text{ opacity: 100%; color:white; font-size: 0.8em; font-style: italic; }*/
    .helperNote { font-size: 0.7em; font-style: italic; }
</style>

