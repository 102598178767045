<template>
  <div style="border: solid 1px darkgrey; border-radius:4px; margin-bottom: 1.5rem; margin-top: 1.5rem; background-color:whitesmoke">
    <div class="title-bar" :class="dynamicClass" @click="title_toggleCollapseExpand" >
      <slot name="title"><div class="title"><slot name="title-text">{{ (!title || title.trim() == '') ? '&nbsp;' : title }}</slot></div></slot>
      <div class="acions" v-show="hasSlot('titleBarActionsArea')"><slot name="titleBarActionsArea"></slot></div>
      <b-iconstack v-show="collapsable" font-scale="1.5" class="collapse-btn" @click="toggleCollapseExpand('icon')" style="margin-top:1px;">
        <b-icon stacked icon="circle" ></b-icon>
        <b-icon stacked :icon="collapseIcon" scale="0.65" ></b-icon>
      </b-iconstack>
    </div>
    <b-collapse v-model="isVisible">
      <div class="collapse-area">
        <slot></slot>
      </div>
      <div class="panel-bottom-bar">
        <slot name="panelBottom"></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  components: {  },
  props:{
    title: { type:String, default:''},
    collapsable: { type:Boolean, default: true },
    visible: { type:Boolean, default: true },
    titleBarClass: { type:String, default:'' },
    variant: { type:String, default:''},
    allTitleBarClickable: { type: Boolean, default: true},

/*    id: { Type:String, required:true},*/
  },

  data(){
    return {
      visibilityBuffer: this.visible,
    }
  },
  computed:{
    isVisible: {
      get() {
        if(!this.collapsable) return true;
        return this.visibilityBuffer;
      },
      set (newVal){
        this.visibilityBuffer = newVal;
      }
    },
    dynamicClass(){
      return 'title-bar ' + this.titleBarClass + ' ' + this.variant;
    },
    collapseIcon(){
      return this.isVisible ? 'chevron-down' : 'chevron-up';
    }
  },
  mounted(){
    this.visibilityBuffer = this.visible;
  },
  methods:{
    hasSlot(slotName = 'default'){
      return this.$slots[slotName] != null;
    },
    toggleCollapseExpand(e='')
    {
      if(e!='') return;
      this.visibilityBuffer = !this.visibilityBuffer;
      if(this.isVisible){
        this.$emit('expand');
        this.$emit('toggleVisiblity', true)
      } else {
        this.$emit('collapse');
        this.$emit('toggleVisiblity', false)
      }
    },
    title_toggleCollapseExpand()
    {
      if(this.allTitleBarClickable)
        this.toggleCollapseExpand();
    },
    expand()
    {
      if(!this.visibilityBuffer){
        this.visibilityBuffer = true;
        this.$emit("expand");
      }
    },
    collapse(){
      if(this.visibilityBuffer){
        this.visibilityBuffer = false;
        this.$emit("collapse");
      }
    }
  },
  watch:{
    visible: function(newVal, /*oldVal*/){
      this.visibilityBuffer = newVal;
    }
  }
}
</script>

<style scoped>
  div.title-bar {
    width:100%;
    /*display: inline-block;*/
    background-color: darkgray;
    color: whitesmoke;
    text-align: left;
    padding:2px;
    padding-left:10px;
    vertical-align: middle;
    border-radius: 4px;
  }
  div.title-bar > { min-height: 12pt;}
  div.title-bar > .title { display: inline; font-size: 1.2rem; min-height:10pt;}
  div.title-bar > .actions { display: inline; float: right; }
  div.title-bar > .collapse-btn { display: inline; float: right; margin:4px; }

  div.title-bar.success { background-color: var(--success);}
  div.title-bar.warning { background-color: var(--warning); }

  div.title-bar.danger { background-color: var(--danger); }
  div.collapse-area > { border-color: darkgray; border-width: 1px; border-style: solid; border-radius: 4px; padding:10px; padding-top:30px !important;}
</style>
