<template>
  <component :is="mode=='icon-bar' ? 'b-nav-item' : 'b-dropdown-item'" @click="onClick" :disabled="$route.name == routeName" :title="title">
    <slot>
      <b-icon :icon="icon" v-show="icon != null && icon != ''" :class="mode" />
      <!--<span class="d-none d-md-inline">&nbsp;{{ text }}</span>-->
      <span v-show="mode!='icon-bar'">&nbsp;{{ text }}</span>
    </slot>
  </component>
</template>

<script>
export default{
  props: {
    routeName: { type:String, required:true },
    title: { type:String, default: '' },
    icon: { type:String, default: ''},
    text: { type:String, default: ''},
    mode: { type:String, default: ''},
    "security-operations": { type: Array, defaultValue: null},
  },
  methods:{
    onClick()
    {
      console.log('NavBarMenuItem.onClick =>', this.routeName);
      if(this.$route.name != this.routeName){
        this.$showLoader()
          .then(() => {
            this.$router.push({ name: this.routeName });
          })
      }
    },
  }
}
</script>

<style scoped>
  .b-icon{ width:0.8em; height:0.8em; }
  .b-icon.icon-bar{
    width: 1em; height:1em;
  }
</style>
