<template>
    <b-modal
      v-model="d_visible"
      title="Changement mot de passe"
      centered
      :static="false"
      :lazy="true"
      size="sm"
      button-size="lg"
      ref="dialog"
      @hide="onHide()"
    >
      <slot name="prepend"></slot>
      <fieldset class="fieldset">
          <b-form-group label="Mot de passe actuel">
              <b-form-input type="password" v-model="oldP" @keypress="cleanError"/>
          </b-form-group>

          <b-form-group label="Nouveau mot de passe">
              <b-form-input type="password" v-model="newP" @keypress="cleanError"/>
          </b-form-group>

          <b-form-group label="Nouveau mot de passe">
              <b-form-input type="password" v-model="confP" @keypress="cleanError"/>
          </b-form-group>
          
          <b-alert
            :show="lastError != null && lastError != ''"
            variant="danger"
          >{{ lastError }}
          </b-alert>
      </fieldset>
      <slot name="postpend"></slot>
          <template #modal-footer="{cancel}">
            <b-button @click="cancel()" variant="secondary">Annuler</b-button>
            <b-button @click="submitNewPass" variant="primary">Changer</b-button>
          </template>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    model:{
        prop: 'visible',
        event: 'change'
    },
    props:{ visible: { type:Boolean, default:false }
    },
    data(){
        return {
            d_visible: this.visible,
            oldP: '',
            newP: '',
            confP:'',
            lastError: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['lastLoginError', 'isAuthenticated']),
        canSetChanges(){
            if(this.oldP.trim() == ''){
                //this.lastError = "Vous devez indiquer votre mot de passe actuel";
                return false;
            } else if(this.newP.trim() == '' ){
                //this.lastError = "Vous devez renseigner le nouveau mot de passe";
                return false;
            } else if(this.newP.trim() == this.oldP.trim()){
                //this.lastError = "L'ancien et le nouveau mot de passe ne doivent pas être identiques";
                return false;
            } else if(this.confP.trim() == ''){
                //this.lastError = 'Vous devez confirmer le nouveau mot de passe'; 
                return false;  
            } else if(this.newP.trim() != this.confP.trim()){
                //this.lastError = "La saisie de confirmation du nouveau mot de passe ne correspond pas au nouveau mot de passe !";
                return false;
            } else {
                //this.lastError = "";
                return true;
            }
        },
    },
    methods:{
        ...mapActions('auth', ['changePassword']),
        async submitNewPass(){
            if(this.canSetChanges){
                const user = { oldPass: this.oldP, newPass: this.newP };
                if(await this.changePassword(user)){
                    this.toastSuccess('Votre mot de passe a bien été modifié.');
                    this.d_visible = false;
                    this.onHide();
                }
                else
                    this.toastFailure('Echec de la modification de votre mot de passe');
            } else {
                this.lastError = this.getLastErrorMessage();
            }
        },
        getLastErrorMessage(){
            if(this.oldP.trim() == ''){
                return "Vous devez indiquer votre mot de passe actuel";
            } else if(this.newP.trim() == '' ){
                return "Vous devez renseigner le nouveau mot de passe";
            } else if(this.newP.trim() == this.oldP.trim()){
                return "L'ancien et le nouveau mot de passe ne doivent pas être identiques";
            } else if(this.confP.trim() == ''){
                return 'Vous devez confirmer le nouveau mot de passe';    
            } else if(this.newP.trim() != this.confP.trim()){
                return "La saisie de confirmation du nouveau mot de passe ne correspond pas au nouveau mot de passe !";
            } else {
                return "";
            }
        },
        /*show(){
          //console.log('show() invoked');
          this.$refs.dialog.show();
          this.$emit('change');
          this.$emit('show');
        },
        hide(){
          //console.log('hide() invoked');
          this.$refs.dialog.hide();
          this.$emit('change');
          this.$emit('hide');
        },*/
        onHide(){
            this.$emit('change');
            this.$emit('hide');
        },
        cleanError(){
            this.lastError = '';
        },
        cleanFields(){
            this.oldP = '';
            this.newP = '';
            this.confP = '';
        },
    },
    mounted(){
        this.cleanError();
        this.cleanFields();
    },
    watch:{
        visible: function(newValue){
            this.d_visible = newValue;
            if(newValue)
                this.cleanFields();
        }
    }

}
</script>
