<template>
  <div >
    <side-bar-menu-link 
      routeName="Home" 
      :to="{name:'Home'}" 
      icon="house-door" 
      :authenticationRequired="false">
      {{ $t("main-menu.home_page") }}
    </side-bar-menu-link>

    <side-bar-menu-group-item 
      :title="$t('main-menu.competition_management')"
      v-if="currentUser != null && (userCanOneOf(securityMap.competitionsManagement_group_operations) || hasUserRunningCompetitionsAsync())"
    >
        <side-bar-menu-link :to="{name:'CompetitionCreate'}" :operations="securityMap.create_competition_operations">
          {{ $t("main-menu.competition_creation") }}
        </side-bar-menu-link>
        <side-bar-menu-link :to="{name:'CompetitionsRunning'}" v-if="userCanOneOf(securityMap.existingCompetitions_operations) || hasUserRunningCompetitionsAsync()">
          {{ $t("main-menu.competition_running") }}
        </side-bar-menu-link>
    </side-bar-menu-group-item>

    <!--<side-bar-menu-link v-if="true" routeName="Results-Default" icon="award-fill" text="Résultats" title="Résultats des compétitions terminées">Résultats</side-bar-menu-link>

    <side-bar-menu-group-item tile="Règlements" :authenticationRequired="false">
      <side-bar-menu-link :authenticationRequired="false">Cerf-volant acrobatique</side-bar-menu-link>
      <side-bar-menu-link :authenticationRequired="false">Indoor</side-bar-menu-link>
    </side-bar-menu-group-item>
    -->
    <side-bar-menu-link routeName="CdfCVA" :to="{name:'CdfCVA'}" :authenticationRequired="false">
      {{ $t("main-menu.cdf_cva") }}
    </side-bar-menu-link>
    
    <web-area>
      <side-bar-menu-link :to="{name:'Downloads'}" :authenticationRequired="false" icon="download" text="Téléchargements" title="Téléchargements de l'application mobile">
        {{ $t("main-menu.downloads") }}
      </side-bar-menu-link>
    </web-area>

    <side-bar-menu-group-item :title="$t('main-menu.administration')" :operations="securityMap.administration_group_operations">
        <side-bar-menu-link :to="{name:'StaffListAdmin'}" ref="staffAdmin" authenticationRequired :operations="securityMap.staffAdmin_operations">
          {{ $t("main-menu.admin_staff") }}
        </side-bar-menu-link>
        <side-bar-menu-link ref="competitorAdmin" v-if="false" :operations="securityMap.competitorAdmin_operations">
          {{ $t("main-menu.admin_competitors") }}
        </side-bar-menu-link>
        <side-bar-menu-group-item :title="$t('main-menu.master_data')" ref="referentialDataAdmin" :operations="securityMap.referentialDataAdmin_operations">
            <side-bar-menu-link :to="{name:'MasterDataGlobalView'}" ref="masterDataGlobalView" authenticationRequired :operations="securityMap.masterDataGlobalView_operations">
              {{ $t("main-menu.master_data_overview") }}
            </side-bar-menu-link>
        </side-bar-menu-group-item>
        <side-bar-menu-group-item :title="$t('main-menu.admin_season_data')" :operations="securityMap.seasonAdmin_group_operations">
            <side-bar-menu-link :to="{name: 'SeasonCompulsoriesAdmin'}" ref="seasonCompulsoriesAdmin" authenticationRequired :operations="securityMap.seasonAdmin_compulsories_operations">
              {{ $t("main-menu.admin_season_compulsories")}}
            </side-bar-menu-link>
        </side-bar-menu-group-item>
    </side-bar-menu-group-item>

    <side-bar-menu-group-item :title="$t('main-menu.security')" :operations="securityMap.security_group_operations">
      <side-bar-menu-link :to="{name: 'SecurityUsersList'}" :operations="securityMap.securityUsers_operations">
        {{ $t("main-menu.security_users") }}
      </side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'SecurityRolesList'}" :operations="securityMap.securityRoles_operations">
        {{ $t("main-menu.security_roles") }}
      </side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'SecurityOperationsList'}" :operations="securityMap.securityOperations_operations">
        {{ $t("main-menu.security_operations")}}
      </side-bar-menu-link>
    </side-bar-menu-group-item>

    <side-bar-menu-group-item :title="$t('main-menu.tools')" :operations="securityMap.tools_group_operations">
      <side-bar-menu-link :to="{name:'TestApi'}" :operations="securityMap.testApi_operations">Test d'appel ajax ver Api</side-bar-menu-link>
      <side-bar-menu-group-item :to="{name: 'GraphicalCharter'}" :operations="securityMap.graphicalCharter_operations" title="Charte graphique" >
          <side-bar-menu-link :to="{name: 'GraphicalCharter', hash: '#Formulaires'}">Formulaires</side-bar-menu-link>
          <side-bar-menu-link :to="{name: 'GraphicalCharter', hash: '#Champs'}">Champs de Formulaire</side-bar-menu-link>
      </side-bar-menu-group-item>
      <side-bar-menu-link :to="{name: 'JsTips'}" :operations="securityMap.jsTips">Javascript tips</side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'DevUsefulLinks'}" :operations="securityMap.devUsefulLinks">Liens utiles</side-bar-menu-link>
    </side-bar-menu-group-item>

    <side-bar-menu-link :to="{name:'ConfigurationHome'}" v-if="displayConfiguration && (isNativePlatform || currentUser!=null)">
      {{ $t("main-menu.configuration") }}
    </side-bar-menu-link>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import SideBarMenuGroupItem from '@/components/Panels/SideBarMenuGroupItem.vue';
import SideBarMenuLink from '@/components/Panels/SideBarMenuLink.vue';
import WebArea from '../WebArea.vue';
import SecurityMap from "@/Constants/securityMap.js";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';

export default{
  components: {  SideBarMenuGroupItem, SideBarMenuLink, WebArea,  },
  props: {
    menuTitle: { type:String, default:'Menu' },
    lazy: Boolean,
    bgVariant: { type:String, default:'dark'},
    textVariant: { type:String, default: 'light'},
    buttonVariant: { type:String, default: 'primary'},
    displayConfiguration: { type: Boolean, default: true},
  },
  data(){
    return {
    }
  },
  computed:{
    securityMap(){ return SecurityMap; },
  },
  methods:{
    ...mapActions('auth', ['userCanOneOf'] ),
    async hasUserRunningCompetitionsAsync(){
      return await CompetitionsConfigurationHelper.hasUserRunningCompetitionsAsync(this.isTestMode);
    }
  }
}
</script>

<style >
  .b-side-bar-body { overflow-wrap: auto; background-color: whitesmoke; border-radius: 3px; border: solid 1px var(--ffvlOrangePicto); margin-left:0px; margin-right:2px; width: calc(100%-2px); }
  .b-side-bar-body { padding-left: 2px;  }
  .b-side-bar-body ul  li { list-style-type: none; 
    /*background-color: var(--bg-dark); */font-size:2em;
    background-color: transparent !important;
    color: var(--text-light); margin-left: 0px; }
  .b-side-bar-body ul:first-child { margin-block-start: 5px; padding-inline-start: 20px;}
 .btn-ffvlOrangePicto {
    color: var(--ffvlOrangeLight) !important;
    margin:0px !important;
    position: fixed;
    top: 55px;
    z-index: 100;
  }  
  .left-aside-menu{ margin-top: -30px;}
</style>
<style >
  .b-sidebar-body ul, .b-sidebar-body ul  li { font-size:1.2rem;}
  .side-bar-menu ul, .side-bar-menu ul li { font-size:1rem;}
</style>
