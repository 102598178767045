<template>
    <div>
        <h2 v-if="includeTitle">Politique de Protection des Données Personnelles</h2>
        Les données personnelles collectées sur ce site (ou récupérées depuis les bases de données de la FFVL) ne sont utilisées, conformément au <b>RGPD</b>
        <info-helper title="Collecte des données personnelles">
            <rgpd :includeTitle="false" />
        </info-helper> 
        , qu'à des fins de gestion, d'historique et de statistiques en rapport avec la compétition.<br/>
        Ces données ne seront pas utilisées pour tout autre usage que celui prévu dans l'application, ou feront l'objet, si nécessaire, d'une demande d'accord explicite pour être utilisées, ponctuellement, à d'autres fins que celles prévues dans le cadre de ce site.<br/>
        En pareil cas, la demande d'accord explicite devra lister les données utilisées et stipuler l'objet exact de celles-ci.<br/>
        Notamment, les données personnelles ne seront ni données, ni échangées, ni vendues avec un quelconque tiers.<br/>
        S'agissant de publication de résultats de compétition, cependant, les données présentées sont, par définition, publiques et la FFVL ne peut en aucun cas garantir qu'elles ne seront pas récupérées par un tiers.<br/>
        <h2>Données personnelles</h2>
        Les données de type "donnée personnelle" gérées par l'application sont :
        <ul>
            <li>Nom &amp; Prénom
                <info-helper>
                    Ces informations sont utilisées pour vous identifier en tant que pilote ou membre de staff (ou autre) notamment pour le rattachement de données de notation, de classement, de jugement...
                </info-helper>
            </li>
            <li>N° de licence
                <info-helper>
                    Cette information est utilisé uniquement en interne, c'est-à-dire pour les seuls utilisateurs pour lesquels il peut être nécessaire de connaitre cette information.<br/>
                    Elle ne sera jamais indiquée publiquement dans des pages présentant des résultats, par exemple, mais elle sera affichée aux juges lors d'une inscription à une compétition afin de leur permettre, notamment, de vérifier la validité de celle-ci.<br/>
                    De même, un organisateur ou chef juge pourra voir cette information concernant les autres membres de staff puisqu'il ne sont pas habilités à juger sans une licence à jour.
                </info-helper>
            </li>
            <li>
                Sexe
                <info-helper>
                    Cette information est principalement utilisée à des fins statistiques afin d'être en mesure de connaitre la répartition hommes/femmes parmi les compétiteurs, les juges..., élément souvent demandé
                    au nom de la parité homme-femme.<br/>
                    Cette information peut également permettre à la Commission Compétition Cerf-Volant d'organiser une communication ciblée si nécessaire pour une information, manifestation, classement... qui ne s'adresserait qu'aux femmes ou qu'aux hommes 
                </info-helper>
            </li>

            <li>Date de naissance <small>(<i>faculatitive</i>)</small>
                <info-helper>
                    Cette information n'est pas obligatoire et n'a pas vocation à être exploitée autrement que pour des actions ou traitements qui 
                    ne s'adresseraient qu'à certaines tranche d'âge afin de pouvoir vérifier ou simplement n'informer que les personnes concernées<br/>
                    <i>Par exemple, si une compétition devait avoir lieu qui ne s'adresse qu'aux juniors (moins de 25 ans, ou mineurs, ou au contraire au séniors de plus de 60 ans ou autre...), la connaissance
                    de la date de naissance des compétiteurs permettant de calculer dynamiquement et à tout moment leur âge, il sera possible de filtrer les personnes concernées.
                    </i>
                </info-helper>
            </li>
            <li>Adresse e-mail
                <info-helper>
                    Cette information est utilisée uniquement en interne afin de permettre la communication avec vous par ce biais et ne sera jamais communiquée à quiconque pour une autre utilisation que celle-ci.<br/>
                    Notamment, elle ne sera pas utilisée pour vous envoyer des informations publicitaires ou à caractère informatif général n'ayant pas trait à la compétition.<br/>
                    Par contre, en tant que compétiteur individuel ou membre d'une équipe compétitrice ou encore membre du staff (juge, DT, scoreur...), la Commission Compétition Cerf-Volant peut être amenée à devoir vous joindre,
                    pour vous signifier une qualification, vous demander de confirmer votre participation, vous informer de l'état potentiellement "incomplet" de votre licence... et c'est uniquement dans 
                    ce type de contextes précis que votre adresse e-mail sera utilisée.
                </info-helper>
            </li>
            <li>Numéro de téléphone
                <info-helper>
                    Cette information est utilisée uniquement en interne afin de permettre la communication avec vous par ce biais et ne sera jamais communiquée à quiconque pour une autre utilisation que celle-ci.<br/>
                </info-helper>
            </li>
        </ul>
    </div>
</template>

<script>
import InfoHelper from '../../components/InfoHelper.vue'
import Rgpd from './rgpd.vue'
export default{
  components: { InfoHelper, Rgpd },
  props: { includeTitle: { type:Boolean, default: true,}}
}
</script>