<template>
  <div>
    <b-modal
        title="Politique de confidentialité et protection des données"
        centered
        :static="true"
        :lazy="true"
        size="lg"
        v-if="d_visible"
        v-model="d_visible"
        button-size="lg"
        @hide="onHide()"
    >
        <ppdp :includeTitle="false"></ppdp>
    </b-modal>
  </div>
</template>

<script>
import Ppdp from '@/views/StaticPages/ppdp.vue';

export default {
  model:{
    prop: 'visible',
    event: 'change'
  },
  props: { visible: { type:Boolean, default: false}},
  components: { Ppdp },
  data(){
    return {
      d_visible: this.visible,
    }
  },
  methods: {
    onHide(){
      this.$emit('change');
      this.$emit('hide');
    }
  },
  watch:{
    visible: function(newValue){
      this.d_visible = newValue;
    }
  }
}
</script>
