const VERSION = "1.0.5";
const WEB_VERSION = "1.0.5";

import Vue from 'vue'
import VueI18n from 'vue-i18n';
import App from './App'
import router from './services/router'
import store from "./services/store"
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import { Fragment }  from "vue-fragment"


Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(Fragment)
Vue.use(VueI18n);

import IconCategory from "@/components/IconCategory"; Vue.use(IconCategory);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import HelperNote from '@/components/HelperNote'
Vue.use(HelperNote);
import ModelViewsHelper from "@/services/store/models/ModelViews";
Vue.use(ModelViewsHelper);
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
Vue.use(CompetitionsConfigurationHelper);
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { v4 as uuid} from 'uuid';
import { RolesEnum } from "@/Constants";

import customScssModule from '@/scss/custom.scss'
Vue.use(customScssModule);

Vue.mixin({
  data(){
    return {
      caller: '',
      version: VERSION,
      webVersion: WEB_VERSION,
    }
  },
  computed: {
    ...mapGetters('auth', ['userHasRole', 'userHasOneOfRoles', 'currentUserRoles', 'currentUser']),
    ...mapGetters("config", ['isDebugMode', 'isTestMode', 'isDevEnvironment', 'isNativePlatform', 'cfgVerboseNoteSummary', 'cfgConfirmNoteEntryByPopup', 'cfgSkipNoteByComponent']),
    ...mapGetters("current", ['currentCompetition', 'currentRound', 'currentLevel', 'currentCategory', 'currentEventType', 'currentEvent', 'currentRule', 'currentCompetitor'
                             ,'currentCompetition_id', 'currentRound_number', 'currentLevel_code', 'currentCategory_code', 'currentEvent_code', 'currentRule_code', 'currentCompetitor_id'
                             ]),

    Roles(){
      return RolesEnum;
    },
    context(){
      return this.dtaContext;
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    ...mapActions('currentState', ['trySyncData']),
    ...mapMutations('current', ['setCurrentCompetition_id', 'setCurrentRound_number', 'setCurrentLevel_code', 'setCurrentCategory_code', 'setCurrentEvent_code' ]),
    ...mapMutations('config', ['setLoader']),
    ...mapActions("synchro", ["updateWhenRequired"]),
    ...mapGetters('config', ['serverBaseUrl']),
    ...mapGetters('auth', ['userCanNew']),
    getUrl(url){
      var baseUrl = this.serverBaseUrl();
      var ret = baseUrl + url;
      return ret;
    },
    hasSlot(slotName = 'default') {
      return !!this.$slots[slotName];
    },
    arrondir: function(valeur, nbDecimales=1){
      var roundDec = Math.pow(10, nbDecimales);
      var ret = Math.round(valeur * roundDec) / roundDec;
      return ret;
    },
    getRandomInt(max){
      return Math.floor(Math.random() * max);
    },
    getRandomArray(max){
      var ret = [];
      while(ret.length < max){
        var test = this.getRandomInt(max);
        if(ret.indexOf(test) < 0)
          ret.push(test);
      }
      return ret.map(i => i+1);
    },
    compare(a, b, direction = 'asc'){
      var d = (!direction || (direction == null) || direction.toLowerCase() == 'asc') ? 1 : -1;
      var t = ( typeof a === 'string' || a instanceof String);
      var ret = t
        ? a.localeCompare(b) * d
        : (a-b) * d;
      return ret;
    },
    SortResultsByRank(results, rankType = null, direction = 'asc'){
      var d = (direction === 'asc') ? 1 : -1;
      if(rankType == null || rankType == 'mix'){
        return results.sort(function(a,b){
          if(a.rank > b.rank) return d * 1;
          if(a.rank < b.rank) return d * -1;
          if(a.competitor.name > b.competitor.name) return -1;
          if(a.competitor.name < b.competitor.name) return 1;
          return 0;
        })
      }

      if(rankType == 'ballet'){
        return results.sort(function(a,b){
          if(a.ballet_rank > b.ballet_rank) return d * 1;
          if(a.ballet_rank < b.ballet_rank) return d * -1;
          if(a.competitor.name > b.competitor.name) return -1;
          if(a.competitor.name < b.competitor.name) return 1;
          return 0;
        })
      }

      if(rankType == 'precision'){
        return results.sort(function(a,b){
          if(a.precision_rank > b.precision_rank) return d * 1;
          if(a.precision_rank < b.precision_rank) return d * -1;
          if(a.competitor.name > b.competitor.name) return -1;
          if(a.competitor.name < b.competitor.name) return 1;
          return 0;
        })
      }

      if(rankType == 'routine'){
        return results.sort(function(a,b){
          if(a.routine_rank > b.routine_rank) return d * 1;
          if(a.routine_rank < b.routine_rank) return d * -1;
          if(a.competitor.name > b.competitor.name) return -1;
          if(a.competitor.name < b.competitor.name) return 1;
          return 0;
        })
      }

      if(rankType == 'figures'){
        return results.sort(function(a,b){
          if(a.figures_rank > b.figures_rank) return d * 1;
          if(a.figures_rank < b.figures_rank) return d * -1;
          if(a.competitor.name < b.competitor.name) return -1;
          if(a.competitor.name > b.competitor.name) return 1;
          return 0;
        })
      }
    },
    SortResultsByCompetitorName(results, direction = 'asc'){
      var d = (direction === 'asc') ? 1 : -1;
      return results.sort(function(a,b){
        if(a.competitor.name < b.competitor.name) return d * -1;
        if(a.competitor.name > b.competitor.name) return d * 1;
        return 0;
      });
    },
    toast(text, title, variant = null) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true
      });
    },
    toastSaved(text, title)
    {
      if(!title || title.length == 0) title = "Enregistrement effectué !";
      if(!text || text.length == 0) text = "Les données ont bien été enregistrées";
      this.toastSuccess(text, title);
    },
    toastAsyncSaved(success, text, title)
    {
      if(success)
        this.toastSaved(text,title);
      else{
        if(!title || title.length == 0) title = "Enregistrement effectué localement !";
        if(!text || text.length == 0)
          text = "Les données ont bien été enregistrées mais la synchronisation avec le serveur a échoué";
        else
          text += " mais la synchronisation avec le serveur a échoué";
        this.toast(text, title, 'warning');
      }
    },
    toatAsyncSaveResult(result, text, title)
    {
      if(result && result.success)
        this.toastAsyncSaved(result.success, text, title);
      else if(result && result.error)
        this.toastSaveFailure(text, title);
      else
        this.toastAsyncSaved(false, text, title);
    },
    toastSaveFailure(text, title)
    {
      if(!title || title.length == 0) title = "Attention ! Données non enregistrées";
      if(!text || text.length == 0) text = "Les données n'ont pas pu être enregistrées";
      this.toastError(text, title);
    },
    toastSuccess(text, title){
      if(!title || title.length == 0) title = 'Félicitations !';
      if(!text ||text.length == 0) text = "L'opération s'est déroulée correctement.";
      this.toast(text, title, "success");
    },
    toastError(text, title){
      if(!title || title.length == 0) title = "Erreur !";
      if(!text || text.length == 0) text = "L'opération a échoué suite à une erreur innatendue !";
      this.toast(text, title, "danger");
    },
    toastFailure(text, title){
      this.toastError(text, title);
    },
    toastInfo(text, title)
    {
      if(!title || title.lenght == 0) title = "Information";
      this.toast(text, title, 'info');
    },
    toastReset(text, title){
      if(!title || title.lenght == 0) title = "Réinitialisation";
      if(!text || text.length == 0) text = "Les saisies en cours on été annulées !";
      this.toast(text, title, 'info');
    },
    toastWarning(text, title){
      if(!title || title.length == 0) title = "Attention !";
      this.toast(text, title, "warning");
    },
    tryToSyncData(){
      console.log('Tentative de synchronisation');
      this.trySyncData();
      this.$hideLoader();
    },
    newGuid(){
      return uuid();
    },
    goBack(){
      this.$showLoader()
        .then(() => {
          this.$router.back();
        });
    },
    isValidLicenseeNumber(number){
      return (number && number!=null && number.length == 8 && number.match(/^\d{7}[a-zA-Z]$/));
    },
    checkSynchro(){
      this.$showRefresher()
        .then(() => {
          this.updateWhenRequired();
          this.$hideRefresher();
        })
    },

    objectsEquals(o1, o2){
      return typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => this.objectsEquals(o1[p], o2[p]))
            : o1 === o2;
    },
    arraysEquals(a1, a2){
      return a1.length === a2.length && a1.every((o, idx) => this.objectsEquals(o, a2[idx]));
    },

    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
})

var initLocale = store.getters["config/locale"];
const app = new Vue({
  elt:"#app",
  store,
  router,
  i18n : new VueI18n({
    locale: initLocale,
    fallbackLocale: 'fr',
    messages:{
      en: require('@/locales/en.json'),
      fr: require('@/locales/fr.json')
    },
    dateTimeFormats:{
      'fr': {
        short: { year: 'numeric', month: 'short', day: 'numeric' },
        long: { year: 'numeric', month: 'long', day:'numeric'}, 
        date: { year: 'numeric', month: 'numeric', day: 'numeric' },
        longDay: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'},
        time: { hour: 'numeric', minute: 'numeric'},
        longTime: { hour: 'numeric', minute: 'numeric', second: 'numeric'},
        shortDateTime: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
        longDateTime: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
      },
      'en': {
        date: { year: 'numeric', month: 'numeric', day: 'numeric' },
        short: { year: 'numeric', month: 'short', day: 'numeric' },
        long: { year: 'numeric', month: 'long', day:'numeric'},
        longDay: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'},
        time: { hour: 'numeric', minute: 'numeric', hour12: true},
        longTime: { hour: 'numeric', minute: 'numeric', second: 'numeric',hour12: true},
        shortDateTime: { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',hour12: true },
        longDateTime: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',hour12: true },
      }
    }
  }),
  render: h => h(App)
}).$mount('#app');



export default app;
