
import { PEOPLE, STAFF, STAFF_CAPABILITY, LICENSEE, REF_COMPETITION_TYPE } from "@/LocalDB";
import { RST_COMPETITION } from "../../../LocalDB";


class DetailedStaffListItem{
    isSelected;
    cannotBeSelected;
    id;
    name;
    firstname;
    lastname;
    shortname;
    initials;
    trigram;
    isFederal;
    federalNumber;
    isFederalSubscriptionValid;
    sexe;
    birthdate;
    age;
    isFederalActive;
    isFederalValid;
    canBeJudge;
    canBeFieldDirector;
    canBeJudgeShadow;
    canBeFieldDirectorShadow;
    canBeScorer;
    canBeScorerShadow;
    canBeTechJudge;
    canBeTechJudgeShadow;
    canBeArtJudge;
    canBeArtJudgeShadow;
    isJudge;
    isJudgeShadow;
    isFieldDirector;
    isFieldDirectorShadow;
    isScorer;
    isScorerShadow;
    isTechJudge;
    isTechJudgeShadow;
    isArtJudge;
    isArtJudgeShadow;
}

const ModelViewsHelper = {
    
    getDetailedStaffListItems(competitionTypeCode, scopeCode, year, selectedItems){
        var result = [];

        var competitionType = REF_COMPETITION_TYPE.query().where('code', competitionTypeCode).get()[0];

        var staffQry = PEOPLE.query().where("isStaff", true);
        if(competitionType.isFederal === true)
        {
            staffQry = staffQry.where(item => item.isFederal === true);
        }
        staffQry.get().forEach(staff => {
            var listItem = new DetailedStaffListItem();

            listItem.id = staff.id;
            listItem.staff_id = staff.id;
            listItem.firstname = staff.firstname;
            listItem.lastname = staff.lastname;
            listItem.name = staff.name;
            listItem.shortname = staff.shortname;
            listItem.initials = staff.initials;
            listItem.trigram = staff.trigram;
            listItem.isFederal = staff.isFederal;
            listItem.federalNumber = staff.federalNumber;
            listItem.sexe = staff.sexe;
            listItem.birthdate = staff.birthdate;
            listItem.age = staff.age;

            var peopleAsStaff = STAFF.query().where("id", staff.id).get()[0];
            listItem.isFederalActive = peopleAsStaff != null && (peopleAsStaff.isFederalActive === true);

            var Licensee = LICENSEE.query().where("federal_number", staff.federalNumber).where("year", year).first();
            listItem.LICENSEE = Licensee;
            listItem.isFederalSubscriptionValid = (Licensee != null) && (Licensee.isValid === true);

            //console.log('getDetailedStaffListItems', staff, Licensee);

            var capabilities = STAFF_CAPABILITY.query()
                .where("staff_id", staff.id)
                .where("competition_type", competitionTypeCode)
                .where("scope", scopeCode)
                .get()[0];

            listItem.isFederalValid = (capabilities != null) ? capabilities.isFederalValid : false;
            listItem.canBeJudge = (capabilities != null) ? capabilities.isJudge : false;
            listItem.canBeJudgeShadow = (capabilities != null) ? capabilities.isJudgeShadow : false;
            listItem.canBeFieldDirector = (capabilities != null) ? capabilities.isFieldDirector : false;
            listItem.canBeFieldDirectorShadow = (capabilities != null) ? capabilities.isFieldDirectorShadow : false;
            listItem.canBeScorer = (capabilities != null) ? capabilities.isScorer : false;
            listItem.canBeScorerShadow = (capabilities != null) ? capabilities.isScorerShadow : false;
            listItem.canBeTechJudge = (capabilities != null) ? capabilities.isTechJudge : false;
            listItem.canBeTechJudgeShadow = (capabilities != null) ? capabilities.isTechJudgeShadow : false;
            listItem.canBeArtJudge = (capabilities != null) ? capabilities.isArtJudge : false;
            listItem.canBeArtJudgeShadow = (capabilities != null) ? capabilities.isArtJudgeShadow : false;

            var selected = (selectedItems != null) ? selectedItems.find(s => s.id === staff.id) : null;
            var isSelected = (selected != null);
            listItem.isSelected = isSelected;
            listItem.cannotBeSelected = isSelected && selected.hasAction;
            listItem.isJudge = isSelected && selected.isJudge && !selected.isShadow;
            listItem.isJudgeShadow = isSelected && selected.isJudge && selected.isShadow;
            listItem.isFieldDirector = isSelected && selected.isFieldDirector && !selected.isShadow;
            listItem.isFieldDirectorShadow = isSelected && selected.isFieldDirector && selected.isShadow;
            listItem.isScorer = isSelected && selected.isScorer && !selected.isShadow;
            listItem.isScorerShadow = isSelected && selected.isScorer && selected.isShadow;
            listItem.isTechJudge = isSelected && selected.isTechJudge && !selected.isShadow;
            listItem.isTechJudgeShadow = isSelected && selected.isTechJudge && selected.isShadow;
            listItem.isArtJudge = isSelected && selected.isArtJudge && !selected.isShadow;
            listItem.isArgJudgeShadow = isSelected && selected.isArtJudge && selected.isShadow;

            if(capabilities != null)
              result.push(listItem);
        });

        result.sort((a,b)=> {return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0) });
        return result;
    },

    getAllDetailedStaffListItems(year){
        var result = [];

        if(year == null)
            year = new Date().getFullYear();

        var staffQry = PEOPLE.query().where("isStaff", true);

        staffQry.get().forEach(staff => {
            var listItem = new DetailedStaffListItem();

            listItem.id = staff.id;
            listItem.firstname = staff.firstname;
            listItem.lastname = staff.lastname;
            listItem.name = staff.name;
            listItem.shortname = staff.shortname;
            listItem.initials = staff.initials;
            listItem.trigram = staff.trigram;
            listItem.isFederal = staff.isFederal;
            listItem.federalNumber = staff.federalNumber;
            listItem.sexe = staff.sexe;
            listItem.birthdate = staff.birthdate;
            listItem.age = staff.age;

            var Licensee = LICENSEE.query().where("federal_number", staff.federalNumber).where("year", year).first();
            listItem.LICENSEE = Licensee;
            
            var peopleAsStaff = STAFF.query().where("id", staff.id).get()[0];
            listItem.isFederalActive = peopleAsStaff != null && (peopleAsStaff.isFederalActive === true);

//            var Licensee = LICENSEE.query().where("id", staff.id).where("year", year).get()[0];
            if(Licensee == null)
                listItem.isFederalSubscriptionValid = false;
            else
                listItem.isFederalSubscriptionValid = (Licensee.isValid === true);
            result.push(listItem);
        });

        result.sort((a,b)=> {return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0) });
        return result;
    },

    getDetailedCompetitionStaffListItems(competition, selectedItems){
      var result = [];

      if(typeof competition ==='string' || competition instanceof String){
        competition = RST_COMPETITION.find(competition);
      }
      
      var staffQry = competition.STAFF_MEMBERS;

      staffQry.forEach(staff => {
          var listItem = new DetailedStaffListItem();

          listItem.id = staff.staff_id;
          listItem.firstname = staff.STAFF_MEMBER.PEOPLE.firstname;
          listItem.lastname = staff.STAFF_MEMBER.PEOPLE.lastname;
          listItem.name = staff.STAFF_MEMBER.PEOPLE.name;
          listItem.shortname = staff.STAFF_MEMBER.PEOPLE.shortname;
          listItem.initials = staff.STAFF_MEMBER.PEOPLE.initials;
          listItem.trigram = staff.STAFF_MEMBER.PEOPLE.trigram;
          listItem.isFederal = staff.STAFF_MEMBER.PEOPLE.isFederal;
          listItem.federalNumber = staff.STAFF_MEMBER.PEOPLE.federalNumber;
          listItem.sexe = staff.STAFF_MEMBER.PEOPLE.sexe;
          listItem.birthdate = staff.STAFF_MEMBER.PEOPLE.birthdate;
          listItem.age = staff.STAFF_MEMBER.PEOPLE.age;

          listItem.isFederalActive = (staff.STAFF_MEMBER.isFederalActive === true);

          var Licensee = LICENSEE.query().where("federal_number", staff.STAFF_MEMBER.PEOPLE.federalNumber).where("year", competition.year).first();
          listItem.isFederalSubscriptionValid = (Licensee !== null) && (Licensee.isValid === true);

          var capabilities = STAFF_CAPABILITY.query()
              .where("staff_id", staff.staff_id)
              .where("competition_type", competition.competition_type)
              .where("scope", competition.scope)
              .first();

          listItem.isFederalValid = capabilities.isFederalValid;
          listItem.canBeJudge = capabilities.isJudge;
          listItem.canBeJudgeShadow = capabilities.isJudgeShadow;
          listItem.canBeFieldDirector = capabilities.isFieldDirector;
          listItem.canBeFieldDirectorShadow = capabilities.isFieldDirectorShadow;
          listItem.canBeScorer = capabilities.isScorer;
          listItem.canBeScorerShadow = capabilities.isScorerShadow;
          listItem.canBeTechJudge = capabilities.isTechJudge;
          listItem.canBeTechJudgeShadow = capabilities.isTechJudgeShadow;
          listItem.canBeArtJudge = capabilities.isArtJudge;
          listItem.canBeArtJudgeShadow = capabilities.isArtJudgeShadow;

          var selected = (selectedItems != null) ? selectedItems.find(s => s.staff_id === staff.staff_id) : null;
          var isSelected = (selected != null);
          listItem.isSelected = isSelected;
          listItem.cannotBeSelected = isSelected && selected.hasAction;
          listItem.isJudge = isSelected && selected.isJudge && !selected.isShadow;
          listItem.isJudgeShadow = isSelected && selected.isJudge && selected.isShadow;
          listItem.isFieldDirector = isSelected && selected.isFieldDirector && !selected.isShadow;
          listItem.isFieldDirectorShadow = isSelected && selected.isFieldDirector && selected.isShadow;
          listItem.isScorer = isSelected && selected.isScorer && !selected.isShadow;
          listItem.isScorerShadow = isSelected && selected.isScorer && selected.isShadow;
          listItem.isTechJudge = isSelected && selected.isTechJudge && !selected.isShadow;
          listItem.isTechJudgeShadow = isSelected && selected.isTechJudge && selected.isShadow;
          listItem.isArtJudge = isSelected && selected.isArtJudge && !selected.isShadow;
          listItem.isArgJudgeShadow = isSelected && selected.isArtJudge && selected.isShadow;

          result.push(listItem);
      });

      result.sort((a,b)=> {return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0) });
      return result;
  },
}
export default ModelViewsHelper ;
