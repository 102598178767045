<template>
    <div>

         <header class="header page-header"  style=" display:flex; flex-direction: column;">  
          <div class="header" >
            <div class="menu-icon" style="justify-self:flex-start; align-self: center; ">
                <b-icon id="app-burger-menu" v-if="displayBurgerButton" icon="list" style="margin: 0.5em; margin-left:0.5em; padding: 2px; width:3em; height:3em; color: white; border: solid 2px var(--ffvlOrangeLight); border-radius: 6px;" v-b-toggle.sidebar-menu  />
                <b-icon id="app-back" v-if="displayBackButton" icon="chevron-left" style="margin: 0.5em; margin-left: 0.5em; padding: 2px; width:3em; height:3em; color: white; border: solid 2px var(--ffvlOrangeLight); border-radius: 6px;" @click="goBack" />
            </div>
            <div class="header-menu-title" style="width:100%">
                <div style="display:flex; justify-content: space-between; align-items: center; align-items: center;" >
                    <div class="menu-icon" style="justify-self:flex-start; align-self: center; ">
                        <span style="font-size:1.5em; font-weight: bold; margin-left:0.4em; color: white;">Compétition Cerf-Volant</span>
                    </div>
                </div>
                <!--<div style="display:flex; justify-content: flex-end;">
                    <slot name="menu-shortcut-1"><div class="col"></div></slot>
                    <slot name="menu-shortcut-2"><div class="col"></div></slot>
                    <slot name="menu-shortcut-3"><div class="col"></div></slot>
                    <slot name="menu-shortcut-4"><div class="col"></div></slot>
                    <slot name="menu-shortcut-5"><div class="col"></div></slot>
                    <div class="col"><slot name="synchro-indicator-placeholder"></slot></div>
                </div>-->
            </div>
            <div class="app-icon">
              <b-nav-item right class="synchro" :class="{ 'required': needSynchro}" @click="doSynchronizeNow">
                <b-icon :icon="needSynchro ? 'cloud-slash' : 'cloud-check'" :variant="needSynchro ? 'danger' : 'success'" style="width: 1.5em; height: 1.5em;" />
                <b-badge variant="danger" pill v-text="nbLogs" v-show="nbLogs > 0" />
              </b-nav-item>
            </div>
          </div>
          <slot name="environment-marker"></slot>
        </header>

        <main>
        <slot>

        </slot>  
        </main>   
        <b-nav></b-nav>  

      
      <slot name="sidebar-menu-placeholder"></slot>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SynchroHelper from "@/services/helpers/synchroHelper";

export default{
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'isImpersonated', 'currentUserFullname', 'userHasOneOfRoles', 'currentUserFirstname' ]),
    ...mapGetters('config', ['isTestMode', 'isDevEnvironment', 'isProdEnvironment', 'isNativePlatform', 'environment', 'isDebugMode', 'isMobileAppSimulationMode' ]),
    needSynchro(){
      return SynchroHelper.IsThereRemainingLogsToSynchronize();
    },
    nbLogs(){
      return SynchroHelper.RemainingLogsCount;
    },
    displayBurgerButton(){
      return this.$route.name != 'ConfigurationHome';
    },
    displayBackButton(){
      return this.$route.name == 'ConfigurationHome';
    }
  },
  methods:{
        backToHome(){
            this.$emit('backToHome');
        },
        showAccount(){
            this.$emit('showAccount');
        },
        showLogin(){
            this.$emit('showLogin');
        },
        disconnect(){
            this.$emit('disconnect');
        },
        doSynchronizeNow(){
            this.$emit('doSynchronizeNow');
        },
        goBack(){
          this.$router.go(-1);
        }
      },

}
</script>

<style>

div#app.mobile-app {
  font-family: Arial /*Avenir, Helvetica, Arial, sans-serif*/;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 10px;
  size:10pt;
}
div#app.mobile-app.test-mode{
  background-color: #e2f5ae ;
}
div#app.mobile-app header.modal-header{
  background: linear-gradient(90deg, var(--ffvlBleuLogo50) 0%, var(--ffvlBleuLight50) 100%);
}
div#app.mobile-app header{
  box-shadow: 0px 6px 12px var(--ffvlBleuLogo50);
}
div#app.mobile-app header div.header {
  background: linear-gradient(90deg, var(--ffvlBleuBaseline) 0%, var(--ffvlBleuLight50) 100%) !important;
  display:flex !important; justify-content:space-between !important; align-items:center !important; 
}
div#app.mobile-app header{
  box-shadow: 0px 6px 12px var(--ffvlBleuLogo50);
}
div#app.mobile-app header.page-header {
  box-shadow: 0px 20px 20px var(--ffvlBleuLogo50);
}
div#app.mobile-app .b-sidebar-body ul{
  background-color: white;
  border-radius: 1em;
  margin-left: 0.5em; margin-right: 0.5em;
}

div#app.mobile-app h1 { font-size: 1.6em;  text-align: center; }
div#app.mobile-app h2 { font-size: 1.5em; }
div#app.mobile-app h3 { font-size: 1.4em; }
div#app.mobile-app h4 { font-size: 1.3em; }
div#app.mobile-app h5 { font-size: 1.2em; }
div#app.mobile-app h6 { font-size: 1.1em; }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color:var(--ffvlBleuLogo);
}

div#app.mobile-app .modal-dialog{
  margin:2rem;
}
.main-header{text-align: center;}
.tools-menu-header{text-align:left;}

div#app.mobile-app main{
  min-height: 100%;
  /*padding-bottom:25px;*/
  padding-top: 78px;
}
#app.mobile-app.test-mode > main {
  padding-bottom: 60px;
}

div#app.mobile-app #sidebar-menu{
    width: 85%;
    background-color: var(--ffvlBleuLight) !important;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    box-shadow: 20px 0px 20px var(--ffvlBleuLogo50) !important;
}
#app.mobile-app header.page-header{
  position:fixed;
  width:100%;
  top:0;
  z-index:100;
}



header > div.impersonated-user,
footer > div.impersonated-user
{
  letter-spacing: normal !important;
}
.impersonated-user{
  background-color: var(--impersonatedUserBackground) !important;
  color:var(--impersonatedUserColor)!important;
}
.nav-dropdown.impersonated-user{
  color:var(--impersonatedUserColor) !important;
}

footer.page-footer > div{
background-color: var(--ffvlHeaderAndFooter);
position:fixed;
color:white;
width:100%;
text-align:center;
bottom:0;
height:18px;
font-size:12px;
padding:5px;
padding-top:2px;
padding-bottom:2px;
display:flex;
justify-content: space-between;
z-index:999;
}

footer.page-footer > div :first-child{
  margin-left:10px;
}
footer.page-footer > div:last-child{
  margin-right: 10px;
}
header  div.test-mode,
footer > div.test-mode
{
  background-color: black; color:white;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:10px;
  letter-spacing: 20px;
}
footer > div.test-mode{
  margin-bottom: 18px;
}
footer > div.impersonated-user{
  margin-bottom: 48px !important;
}


html{
  height: 100%;
  font-size:12pt;
}
body{
  margin: 0;
  min-height: 100%;
  position:relative;
  font-family: var(--ffvlFontFamily);
}
.nav-item{

  list-style: none;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--ffvlBleuBaseline);
  font-weight:bold;
}
h1{
  font-size: 2em;
}
h2{
  font-size: 1.75em;
}
h3{
  font-size: 1.5em;
}
h4{
  font-size: 1.25em;
}
.btn{ margin: 2px;}

:root{
  --sortedHeader: #20C997;
  --sortedCell: #40e9b7;
  --resultTotalHeader : #aaaaaa;
  --resultTotal : #eeeeee;
  --resultRankHeader : #aaaaaa;
  --resultRank : #cccccc;
  --inputBackground: #aaaaee;
  --ffvlAlert: #cf0a10;
  --ffvlFontFamily: Arial;
  --ffvlLinkFontFamily: 'Arial Narrow';
  --ffvlLinkColor: #589ed0;
  --ffvlHeaderAndFooter: var(--ffvlOrangePicto);

  --ffvlPrimary: var(--ffvlBleuBaseline) !important;
  --ffvlSecondary: var(--ffvlBleuLogo) !important;
  --ffvlDanger: var(--ffvlAlert) !important;
  --ffvlInactif: darkgrey;
  --ffvlOrangeLight: #f1dcc6;
  --ffvlError: var(--ffvlAlert) !important;
  --ffvlErrorBackground: #ffdae0;
  --ffvlWarning: var(--ffvlOrangePicto);

  --impersonatedUserBackground: #cc00cc;
  --impersonatedUserColor: #ffffff;
}

input[type="number"] { text-align:  right; padding-right:2px; padding-left:2px;}
fieldset.form-group { padding-left: calc(0.475rem + 1px); padding-right: calc(0.475rem + 1px);}
fieldset.form-group > legend { color: var(--ffvlOrangePicto); text-align: left; padding-bottom: calc(0.175rem + 1px); font-size: 0.9rem;}
fieldset.form-group > div > legend span.label { color: var(--ffvlOrangePicto); text-align: left; padding-bottom: calc(0.175rem + 1px); font-size: 0.9rem;}
fieldset.fieldset {border:solid 1px var(--dark); margin: 10px; margin: calc(0.375rem + 1px); margin-bottom: calc(2rem + 1px) !important;  }
fieldset.fieldset > legend { font-size:1rem; text-align: left; width: auto; margin-left: 5px; padding-left: 10px; padding-right: 10px; color: var(--ffvlSecondary); font-weight: bold;  }
fieldset.form-group > div { display:flex; justify-content: flex-start; align-items: center;}
fieldset.form-group.readonly > input { color:darkgrey;}
.ffvlLogo { font-family: 'HandelGothicBT Regular', 'Arial'}
.ffvlText, .ffvlTitle { font-family: var(--ffvlFontFamily);}
.ffvlTitle { font-weight: bold; }
.ffvlLevalMentions { font-family: Helvetica; }
.ffvlTechnicalInfos { font-family: Helvetica; }
.ffvlLink { font-family: 'Arial Narrow', arial; color: var(--ffvlBleuLogo); }
.ffvlAlert { color: var(--ffvlAlert); }

div.info{ display: inline;}

.nav.bg-dark { background-color: var(--ffvlHeaderAndFooter) !important;   padding-left:5px; padding-right: 5px;
}
.nav-brand { font-family: 'HandelGothicBT Regular', 'Arial' !important;}
.nav-link { font-family: var(--ffvlLinkFontFamily) !important; color: var(--ffvlOrangeLight); }
.btn-primary { background-color: var(--ffvlPrimary) !important;}
.btn-outline-primary { border-color: var(--ffvlPrimary) !important; color: var(--ffvlPrimary) !important;}
.btn-secondary { background-color: var(--ffvlSecondary) !important;}
.btn-outline-primary.inactif { border-color: var(--ffvlInactif) !important; color: var(--ffvlInactif)!important; }

.nav-tabs > .nav-item{ border: solid 1px var(--ffvlBleuLogo);
  color: var(--ffvlBleuLogo) !important;
  border-radius: 5px 5px 0 0;
}
.nav-tabs > .nav-item > .nav-link{ color: var(--ffvlBleuLogo) !important; }
.nav-tabs > .nav-item > .nav-link.active{ color: var(--ffvlBleuBaseline); font-weight: bolder;}
.nav-pills > .nav-item > .nav-link { color: var(--ffvlOrangePicto)};
.nav-pills > .nav-item > .nav-link.active{ color: var(--ffvlOrangePicto); background-color: var(--ffvlOrangeLight) !important; }
a.btn.back {
  margin : 10px 20px 10px 20px !important;
  border: var(--ffvlOrangePicto) 1px solid;
  border-radius: 5px;
  background-color:darksalmon;
  color: white;
  text-decoration: none;
  padding: 3px;
}

helper-note {
  background-color: var(--ffvlOrangePicto);
  opacity:60%;
  border: solid 1px var(--ffvlOrangePicto);
  border-radius: 2px;
  font-size: 0.8rem;
  color: var(--ffvlDanger);
  font-style: italic;
  opacity:60%;
}

.synchro { color: green; }
.synchro.required { color: white; background-color:red;}

  div.header { font-size: 0.7rem; }
  fieldset.fieldset.danger{ border-color: var(--danger); }
  fieldset.fieldset.danger > legend { color: var(--danger); }
  fieldset.fieldset.danger > ul { color: var(--danger); }

  fieldset.fieldset.warning{ border-color: var(--warning); }
  fieldset.fieldset.warning > legend { color: var(--warning); }
  fieldset.fieldset.warning > ul { color: var(--warning); }
hr.env{
  margin: 0px !important;
  border-width: 5px;
  border-style: solid;
}

hr.dev-env{
  border:solid 5px yellow !important;
  margin: 0px !important;
  border-bottom: solid 1px black !important;
}
hr.debug-env{
  border: solid 5px red !important;
  margin: 0px !important;
  border-bottom: solid 1px black !important;;
}
hr.production{
  border-color: #a0abbd !important;
}
hr.tests{
  border-color: yellow !important;
}
hr.DEBUG{
  border-color: rgb(101, 251, 124) !important;
}
hr.env-top{
  border-bottom: 1px solid var(--ffvlHeaderAndFooter) !important;
}
hr.debug-top{
  border-bottom: 2px solid red !important;
}
hr.env-bottom{
  margin-bottom: 38px !important;
  border-top: 1px solid var(--ffvlHeaderAndFooter) !important;
}
hr.debug-bottom{
  border-top: 2px solid red !important;
}
y { background-image: "@/assets/bg.jpg";}
@media screen and (max-width: 767px) and (orientation: portrait) {

}
@media screen and (max-width: 600px){
  html{font-size: 11pt;}
}
@media screen and (max-midth: 1024px){
  html{font-size: 14pt;}
}

</style>