<template>
  <div :class="mode">

    <!-- Accueil -->
    <nav-bar-menu-item routeName="Home" icon="house-door" text="Accueil" :mode="mode" class="d-none d-xl-block left-aside-menu" />

    <!-- Création de compétition -->
    <secured-area
      :hideWhenAccessIsRejected="true"
      :operations="['Create_Competition']">
      <nav-bar-menu-item routeName="CompetitionCreate" icon="plus-circle" text="Nouvelle compétition" :mode="mode" title="Commencer une nouvelle compétition" class="d-none d-xl-block left-aside-menu" />
    </secured-area>

    <!-- Compétitions en cours -->
    <secured-area
      :hideWhenAccessIsRejected="true"
      :operations="securityMap.existingCompetitions_operations/*['List_Competitions', 'View_Competition', 'Edit_Competition', 'Judge_Event', 'Delete_Competition']*/">
      <nav-bar-menu-item routeName="CompetitionsRunning" icon="controller" text="Compétition(s) en cours" :mode="mode" title="Reprendre une compétition existante en cours" />
    </secured-area>

    <!-- Résultats de compétitions passées -->
    <nav-bar-menu-item v-if="false" routeName="Results-Default" icon="award-fill" text="Résultats" :mode="mode" title="Résultats des compétitions terminées" class="d-none d-xl-block left-aside-menu" />

    <!-- Téléchargements -->
    <!--<web-area>
      <nav-bar-menu-item routeName="Downloads" icon="download" text="Téléchargements" :mode="mode" title="Téléchargements de l'application mobile" />
    </web-area>-->

    <!-- Administration des données, référentiel... -->
    <secured-area
      :hideWhenAccessIsRejected="true"
      :operations="securityMap.administration_group_operations/*['List_StaffMembers', 'View_StaffMember', 'Create_StaffMember', 'Edit_StaffMember', 'Remove_StaffMember', 'View_StaffMember_Capabilities', 'Edit_StaffMember_Capabilities'
                   ,'List_Season_Compulsories', 'Set_Season_Compulsories'
                   ]*/"
    >
      <nav-bar-menu-item routeName="AdministrationHome" icon="file-earmark-post" text="Administration" :mode="mode" title="Outils d'administration des données (juges, compétiteurs, règles)" class="d-none d-xl-block left-aside-menu"/>
    </secured-area>

    <!-- Gestion de la sécurité et des utilisateurs -->
    <secured-area
        :hideWhenAccessIsRejected="true"
        :operations="securityMap.security_group_operations/*['List_Users', 'View_User', 'Create_User', 'Edit_User', 'Remove_User', 'List_Roles', 'View_Role', 'Create_role', 'Edit_Role', 'Remove_Role', 'List_Operations', 'View_Operation', 'Edit_Operation', 'Archive_Operation', 'Unarchive_Operation', 'Remove_Operation', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'View_Users_Rights', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']*/">
        <nav-bar-menu-item v-show="mode!='icon-bar'" routeName="SecurityHome" icon="person-badge" text="Sécurité" :mode="mode" title="Gestion des utilisateurs & rôles" class="d-none d-xl-block left-aside-menu" />
    </secured-area>

    <!-- Outils du développeur -->
    <secured-area
      :hideWhenAccessIsRejected="true"
      :operations="securityMap.tools_group_operations"
    >
      <nav-bar-menu-item routeName="DevTools" icon="wrench" text="Outils" :mode="mode" title="Boîte à outils du développeur" class="d-none d-xl-block left-aside-menu" />
    </secured-area>

    <!-- Configuration -->
    <secured-area
      :hideWhenAccessIsRejected="true"
      :authenticationRequired="true">
      <nav-bar-menu-item v-show="mode!='icon-bar'" routeName="ConfigurationHome" icon="gear" text="Configuration" :mode="mode" class="d-none d-xl-block left-aside-menu" />
    </secured-area>

    
  </div>
</template>

<script>
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper'
import NavBarMenuItem from './NavBarMenuItem.vue';
//import WebArea from "@/components/WebArea.vue";
//import MobileAppArea from '@/components/MobileAppArea.vue';
import SecuredArea from "@/components/Security/NewSecuredArea.vue";
import SecurityMap from "@/Constants/securityMap.js";

export default {
  components: { NavBarMenuItem, /*WebArea,*/ SecuredArea, /*MobileAppArea*/ },
  props: {
    mode:{ type:String, default:'' },
  },
  data(){
    return {
      competitionCreate_routeName: 'CompetitionCreate'
    };
  },
  computed: {
    securityMap(){ return SecurityMap; },
    isTestMode(){
        return this.userHasRole( this.Roles.Tester );
    },
  },
  methods:{
    async navigateToPage(routeName)
    {
      //console.log('navigateToPage =>', routeName);
      if(this.$route.name != routeName){
        /*var loader = this.$showLoader()
          .then(() => {
            this.$router.push({ name: routeName });
          })*/
          var loading = await this.$showLoader();
          if(loading){
            //console.log ('navigateToPage', loading);
            this.$router.push({ name: routeName });
          }
      }
    },
    backToHome(){
      if(this.$route.name != 'Index'){
        this.$showLoader()
          .then(() => {
            this.$router.push({ name: 'Index'});
          });
      }
    },
    createNewCompetition(){
      this.$showLoader()
        .then(()=>{
          this.setCurrentCompetition_id(null);
          this.$router.push({name: 'CompetitionCreate'});
        });
    },
    listCompetitionsRunning(){
      this.$showLoader()
        .then(() => {
          this.$router.push({ name: 'CompetitionsRunning'});
        });
    },
    userHasRunningCompetitions(){
      return (CompetitionsConfigurationHelper.getUserRunningCompetitions(this.isTestMode).length > 0);
    },
  }
}
</script>

<style scoped>
.navbar-nav.icon-bar{
  width:100%;
  flex-direction: row !important;
  padding-left: 2px;
  padding-right: 2px;
  align-items: center;
  align-content: space-between;
}
.nav{
  align-content: space-around;
}
.d-block.icon-bar{
  display: flex !important;
}
.navbar-nav.icon-bar .nav-item {
  margin-left: 5px; margin-right: 5px;
}/*
.navbar-nav.icon-bar .nav-item:first {
  margin-left: 2px;
}*/

</style>
