<template>
  <div>
    <slot v-if="isWeb" name="web"></slot>
    <slot v-else name="mobile">
      MMMM
      <slot v-if="isAndroid" name="android"></slot>
      <slot v-if="isIos" name="ios"></slot>
    </slot>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { mapGetters } from 'vuex';

export default {
  name: 'WebArea',
  computed:{
    ...mapGetters('config', ['isMobileAppSimulationMode']),
    isWeb(){ return !Capacitor.isNativePlatform() && !this.isMobileAppSimulationMode},
    isAndroid(){ return Capacitor.getPlatform() == "android"; },
    isIos(){ return Capacitor.getPlatform() == 'ios'}
  }
}
</script>


