<template>
    <div>
        <h2 v-if="includeTitle">Collecte des données personnelles"></h2>
        Le sigle <b>RGPD</b> signifie "Règlement Général sur la Protection des Données".<br/>
        Le <b>RGPD</b> encadre le traitement des données personnelles sur le territoire de l'Union européenne.<br/>
        Le <b>RDPD est obligatoire</b> dans l'ensemble des 28 états membres depuis le 25 mai 2018. Tous les acteurs ayant recours à des données personnelles doivent se conformer à ce règlement européen.<br/>
        Les informations à caractère personnel recueillies et présentées sur ce site (et plus généralement, les données manipulées par la Commission Compétition Cerf-Volant) font l'objet d'un traitement
        informatisé destiné UNIQUEMENT à la Commission Compétition Cerf-Volant pour servir ce que de droit. Elles sont conservées le temps nécessaire à leur utilisation.
    </div>
</template>

<script>
export default{
  props: { includeTitle: { type:Boolean, default: true,}}
}
</script>