<template>
  <div class="side-bar-menu">
    <div class="fixed container">
      <slot name="title">
        <div class="tools-menu-header" v-show="title && title.trim()!=''">
          <b-iconstack v-show="collapsable" font-scale="0.9" class="collapse-btn" @click="contentShown=!contentShown" style="margin-top:1px;">
            <b-icon stacked icon="circle" ></b-icon>
            <b-icon stacked :icon="collapseIcon" scale="0.65" ></b-icon>
          </b-iconstack>&nbsp;
          <slot name="title-text">{{ title }}</slot>
        </div>
      </slot>
      <b-collapse class="side-bar-menu-content" v-model="isVisible">
        <ul class="side-bar-menu">
        <slot>
        </slot>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
  export default{
    props:{
      title: { type:String, default:null },
      contentShown: { type:Boolean, default: true, },
      collapsable: { type:Boolean, default: true },
    },
    computed:{
      isVisible: {
        get() {
          if(!this.collapsable) return true;
          return this.contentShown;
        },
        set (newVal){
          this.contentShown = newVal;
        }
      },
      collapseIcon(){
        return this.isVisible ? 'chevron-down' : 'chevron-up';
      }
    },
    data(){
      return {
        contentVisible: this.contentShown,
      }
    }
  }
</script>

<style scoped>
  /*.fixed{ position:fixed; width:auto; min-width:300px;}*/
  .side-bar-menu, .side-bar-menu-content { width: calc(100% - 12px); height:100%; overflow: hidden; margin-left:6px; margin-right: 6px;  }
  .tools-menu-header { padding-left: 5px; padding-right: 5px; border-radius: 3px; border: solid 1px var(--ffvlOrangePicto); margin-bottom: 10px; }
  .side-bar-menu-content { overflow-wrap: auto; /*background-color: whitesmoke;*/ background-color: var(--dark); border-radius: 3px; border: solid 1px var(--ffvlOrangePicto); margin-left:0px; margin-right:0px; width:100%; }
  .side-bar-menu-content { padding-left: -6px; }
  .side-bar-menu-content ul > li { list-style-type: none; }
  .side-bar-menu-content ul:first-child { margin-block-start: 5px; padding-inline-start: 20px;}
  .tools-menu-header { background-color: var(--dark); color: var(--light); font-size:1.2em; font-weight: bold; }
  ul.side-bar-menu { list-style-type: none;  }
</style>
