import Vue from 'vue'
import Vuex from 'vuex'
import { RST_COMPETITION, RST_ROUND, RST_LEVEL, RST_CATEGORY, RST_EVENT, RST_REF_EVENT, SEASON_RULE } from "@/LocalDB"

Vue.use(Vuex)

const current = {
    namespaced:true,
    state:  {
      competitionId: null,
      competition_id: null,
      round_number: null,
      level_code: null,
      category_code: null,
      event_code: null,
      judge_id: null, // id de RST_STAFF_MEMBER correspondant au juge en cours
      eventWizardSettings: { 
        competition_id: null,
        round_number: null,
        level_code: null,
        category_code: null,
        event_type: null
      },
      _viewedCompetitionId: null,
    },
    mutations: {
      setCurrentCompetition_id: (state, competitionId) => {
        state.competition_id = competitionId;
      },
      setCurrentRound_number: (state, no) => {
        state.round_number = no;
      },
      setCurrentLevel_code: (state, code) => state.level_code = code,
      setCurrentCategory_code: (state, code) => state.category_code = code,
      setCurrentEvent_code: (state, code) => state.event_code = code,
      setCurrentJudge_id: (state, id) => state.judge_id = id,
      setEventWizardSettings: (state, settings) => state.eventWizardSettings = settings,
      setViewedCompetitionId: (state, id) => { console.log ('setViewedCompetitionId', state, id); state._viewedCompetitionId = id},
    },
    actions: {
    },
    getters: {
      getEventWizardSettings: (state) => state.eventWizardSettings,
      currentCompetition_id: (state) => state.competition_id,
      currentRound_number: (state) => state.round_number,
      currentLevel_code: (state) => state.level_code,
      currentCategory_code: (state) => state.category_code,
      currentEvent_code: (state) => state.event_code,
      currentJudge_id: (state) => state.judge_id,

      currentCompetition: (state) => {
        if(state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_COMPETITION.find(state.competition_id);
        return found;
      },
      currentRound: (state) => {
        if(state.round_number == null || state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_ROUND.query()
                  .where("competition_id", state.competition_id)
                  .where("round_number", state.round_number)
                  .first();
        return found;
      },
      currentLevel: (state) => {
        if(state.level_code == null || state.level_code == '' || state.round_number == null || state.round_number == '' || state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_LEVEL.query()
                      .where("competition_id", state.competition_id)
                      .where("round_number", state.round_number)
                      .where('level', state.level_code)
                      .first();
        return found;
      },
      currentCategory: (state) => {
        if(state.category_code == null || state.category_code == '' || state.level_code == null || state.level_code == '' || state.round_number == null || state.round_number == '' || state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_CATEGORY.query()
                      .where("competition_id", state.competition_id)
                      .where("round_number", state.round_number)
                      .where('level', state.level_code)
                      .where('category', state.category_code)
                      .first();
        return found;
      },
      currentEventType: (state) => {
        if(state.event_code == null || state.event_code == '' || state.level_code == null || state.level_code == '' || state.round_number == null || state.round_number == '' || state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_REF_EVENT.query()
                      .where("competition_id", state.competition_id)
                      .where("round_number", state.round_number)
                      .where('level', state.level_code)
                      .where('event', state.event_code)
                      .first();
        return found;      
      },
      currentEvent: (state) => {
        if(state.event_code == null || state.event_code == '' || state.category_code == null || state.category_code == '' || state.level_code == null || state.level_code == '' || state.round_number == null || state.round_number == '' || state.competition_id == null || state.competition_id == '')
          return null;
        var found = RST_EVENT.query()
                      .where("competition_id", state.competition_id)
                      .where("round_number", state.round_number)
                      .where('level', state.level_code)
                      .where('category', state.category_code)
                      .where('event', state.event_code)
                      .first();
        return found;
      },
      currentRule_code: (state, getters) => {
        if(getters.currentCategory != null && getters.currentCategory.rule != null && getters.currentCategory.rule != '')
          return getters.currentCategory.rule;
        if(getters.currentLevel != null && getters.currentLevel.rule != null && getters.currentLevel.rule != '')
          return getters.currentLevel.rule;
        return null;
      },
      currentRule: (state, getters) => {
        if(getters.currentRule_code == null || getters.currentRule_code == '')
          return null;
        var found = SEASON_RULE.query()
                      .where("competition_type", state.competition.competition_type)
                      .where("year", state.competition.year)
                      .where("level", state.level_code)
                      .where("rule", getters.currentRule_code)
                      .first();
        return found;
      },
      currentJudge: (state, getters) => {
        if(getters.currentEvent == null || state.judge_id == null || state.judge_id == '')
          return null;
        var founds = getters.currentEvent.JUDGES.filter(item => item.id == state.judge_id);
        if(founds != null && founds.length > 0)
          return founds[0];
        return null;
      },
      currentCompetitor_id: (state, getters) => {
        if(getters.currentEvent != null)
          return getters.currentEvent.current_competitor_id;
        return null;
      },
      currentCompetitor: (state, getters) => {
        if(getters.currentEvent != null)
          return getters.currentEvent.CURRENT_COMPETITOR;
        return null;
      },

      ViewedCompetitionId: (state) => { return state._viewedCompetitionId; },

    }
  };

export default current;
