<template>
  <div>
    <div v-if="debugMode">
      <p><b><i>SecuredArea</i></b> => isAuthenticated : {{ isAuthenticated}}, isAuthorized : {{ isAuthorized }}</p>
      <!--<p>{{ operations }} </p>-->
    </div>
    <div v-if="isAuthorized && (isAuthenticated || !authenticationRequired)">
      <div v-if="debugMode"><i>Authentifié et Autorisé</i></div>
        <slot></slot>
    </div>
    <div v-else-if="!isAuthenticated && !hideWhenAccessIsRejected">
        <slot name="authentification-required">
          <authentification-required @authenticationChange="authentication_change" />
        </slot>
        <slot name="others"></slot>
    </div>
    <div v-else-if="!hideWhenAccessIsRejected">
        <slot name="access-denied">
          <access-denied />
        </slot>
    </div>
  </div>
</template>

<script>
import { /*mapActions,*/ mapGetters } from "vuex";
import AccessDenied from "@/components/Security/AccessDenied.vue";
import AuthentificationRequired from "@/components/Security/AuthentificationRequired.vue";

export default {
  model: {
    prop: 'operations',
  },
  //name: 'NewSecuredArea',
  components: { AccessDenied, AuthentificationRequired },
  props: {
           operations: { default: null},
           hideWhenAccessIsRejected: { type: Boolean, default: false},
           debugMode: { type: Boolean, default:false},
           authenticationRequired: {type:Boolean, default: true},
         },
  data(){
    return {
      //isAuthorized: false,
    }
  },
  computed:{
    ...mapGetters('auth', ['isAuthenticated', 'userHasOneOfRoles', 'currentUser' ]),
    ...mapGetters('config', ['cfgByPassSecurity']),
    isAuthorized(){
      if(this.cfgByPassSecurity)
        return true;
      if(!this.authenticationRequired)
        return true;
      var ret = this.operations && this.operations.length > 0 && this.isAuthenticated
        ? this.operations
          .map(o => {
            if(this.currentUser.operations[o])
              return (this.currentUser.operations[o] == true);
            return false;
          })
          .reduce((prev, cur) => prev || cur, false)
        : false;
      return ret;
    },
  },
}
</script>
