import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import { store } from '../store'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

var debugRoutes = false;

 Vue.use(Loading);

Vue.use(Router)

const redirectUserHome = (to, from, next) => {
  if (to.path === "/") {
    next();
  }
  next();
};

const routesDef = [
  { path: '/', componentName: 'Home', name:'Home' },
  {
    node: 'DevTools', componentName: 'Master', container: false,
    children: [
      { node: 'TestApi', nested: true},
      { node: 'GraphicalCharter', nested: true },
      { node: 'JsTips', nested: true},
      { componentName: 'UsefulLinks', name: 'DevUsefulLinks', nested: true },
    ]
  },
  {
    node: 'Configuration', componentName: 'Master', container: true,
    children: [
      // Ajouter les enfants du menu de configuration
      { componentName: 'Index', nested: true, name:"ConfigurationHome" },
     ]
  },
  {
    node: 'AccessDenied'
  },
  {
    node: 'Security',
    componentName: 'Master',
    container: true,
    children: [
      { componentName: "Index", nested: true, name:"SecurityHome" },
      {
        path: 'Operations',
        children: [
          { componentName: "INDEX", nested:true, name: 'SecurityOperationsList' },
          { componentName: "EDIT", nested:true, name: 'SecurityOperationEdit' },
        ]
      },
      {
        path: 'Users',
        children: [
          { componentName: "INDEX", nested: true, name: 'SecurityUsersList' },
          { componentName: "EDIT", nested:true, name: 'SecurityUserEdit' },
        ]
      },
      {
        path: 'Roles',
        children: [
          { componentName: "INDEX", nested:true, name: 'SecurityRolesList'},
          { componentName: "EDIT", nested: true, name: 'SecurityRoleEdit' },
        ]
      },
      {
        node: 'Login',
      }
    ]
  },
  {
    path: '/Competitions',
    node: 'Competitions',
    componentName: 'Master',
    container: true,
    children: [
      {
        node: 'CreateWizard',
        nested: true,
        name: 'CompetitionCreate',
      },
      {
        node: 'Running',
        nested: true,
        name: 'CompetitionsRunning',
      },
      {
        node: 'EditWizard',
        nested: true,
        name: 'CompetitionEdit'
      },
      {
        node: 'EditOrRun',
        nested: true,
        name: 'CompetitionEditOrRun'
      },
      {
        node: 'View',
        nested: true,
        name: 'CompetitionView',
      },
      {
        node: 'Results',
        nested: true,
        name: 'CompetitionResults',
      },
      {
        path: 'Round',
        children: [
          {
            node: 'Prepare',
            name: 'RoundPrepare'
          },
          {
            node: 'Briefing',
            name: 'RoundBriefing',
            nested: true,
          },
          {
            node: 'Results',
            name: 'RoundResults',
          },
        ]
      },
      {
        path: 'Events',
        children: [
          {
            node: 'Run',
            name: 'EventRun'
          },
          {
            node: 'PrepareWizard',
            name: 'EventPrepare',
            nested: true,
          },
          {
            node: 'Results',
            name: 'EventResults'
          },
        ]
      }
    ]
  },
  {
    path: '/ChampionnatDeFranceCVA',
    node:'ChampionnatDeFranceCVA',
    componentName: 'Master',
    container: true,
    children:[{
      componentName: 'View',
      name: 'CdfCVA',
      nested: true,
    }]
  },
  {
    node: 'Results',
    componentName: 'Master',
    container: false,
    children: [
      { path:"", componentName: 'Default', nested: true,  },
    ]
  },
  {
    name: 'HomeRunningCompetitionDetailsView',
    componentName: 'HomeRunningCompetitionDetailsView'
  },
  {
    path:'/Downloads',
    componentName: 'Downloads',
    name: 'Downloads'
  },
  {
    node: 'Administration',
    componentName: 'Master',
    container: true,
    children: [
      { componentName: "Index", nested:true, name:"AdministrationHome" },
      { componentName: "StaffList", nested:true, name:"StaffListAdmin" },
      { componentName: "Staff", nested:true, name:'Staff'},
      { path: 'MasterData',
        children: [
          { componentName: 'GlobalView', nested:true, name:"MasterDataGlobalView" },
        ]
      },
      { componentName: "SeasonCompulsories", nested:true, name:"SeasonCompulsoriesAdmin" },
    ]
  },
];

function hasChildren (r){ return (r.children && Array.isArray(r.children) && (r.children.length > 0) );}
function isSheet (r) { return !hasChildren(r);}
function isRootLevel (r) { return !r.parent;}
function isNested (r) { return r.nested === true}
function isContainer (r) { return r.container === true}
function hasContainer (r) {
  if(!r.parent) { return false;}
 if(r.parent.container === true) { return true;}
 return hasContainer(r.parent);
}
function hasComponentDefined (r) { return r.componentName && (r.componentName.trim() != '');}
function hasNodeDefined (r) { return r.node && (r.node.trim() != ''); }
function hasPathDefined (r) { return r.path && (r.path.trim() != '');}
function hasNameDefined (r) { return r.name && (r.name.trim() != '');}
function ensureFullPaths (r) {
  if(hasPathDefined(r)){
    if(!r.componentPath){
      if(isRootLevel(r)){
        r.componentPath = r.path.startsWith('/') ? r.path.slice(1) : r.path;
      } else {
        if(!r.path.startsWith('/')){
          r.componentPath = `${r.parent.componentPath}/${r.path}`;
        }
      }
    }
    if(!r.path.startsWith('/'))
    {
      // Vu que l'on est sur un chemin relatif, pour que cela fonctionne, il faut le préparer au remplacement du parent
      r.path = `.../${r.path}`.replace('...', r.parent ? r.parent.path : '');
    }
    r.path = r.path.toLowerCase();
    return r;
  }

  if(hasNodeDefined(r)) {
    r.path = r.node.toLowerCase();
    r.componentPath = r.node;
  } else if (hasComponentDefined(r)){
    r.path = r.componentName.toLowerCase();
  }
  return r;
}
function parseSheet (route) {
  if(!hasComponentDefined(route)){
      console.log("Pas de \"component\" défini pour La route de type \"sheet\"", route);
      throw "Pas de \"component\" défini pour La route de type \"sheet\" " + JSON.stringify(route);

  }

  // Si la route est marquée comme "nested" mais qu'aucun de ses parents est marqué comme "container" on annule le "nested"
  // pour eviter que la route ne reste à traîner en tant que "child".
  if(isNested(route) && !hasContainer(route)){
    route.nested = false;
  }

  if(!hasPathDefined(route)){ route.path = `.../${route.componentName}`; }
  if(!route.componentPath || route.componentPath.trim() == '' ){ route.componentPath = '...'; }

  /* Gestion du nom */
  route.name = (hasNameDefined(route) ? route.name : (route.parent ? `${route.parent.componentPath}-${route.componentName}` : route.componentName ));
  var re = new RegExp('/','g');
  route.name = route.name.replace(re, '-');
  if(route.name.startsWith('-')) { route.name = route.name.slice(1); }

  /* Gestion du chemin (url) */
  route.path = route.path.replace('...', route.parent ? route.parent.path : '');
  if(!route.path.startsWith('/')) { route.path = '/' + route.path ; }

  /* Gestion du chemin du fichier composant (arborescence de fichiers du projet) */
  route.componentPath = route.componentPath.replace('...', route.parent ? route.parent.componentPath : '');
  if(hasNodeDefined(route) && route.node.toLowerCase() != route.componentName.toLowerCase()) { route.componentPath = `${route.componentPath}/${route.node}`; }
  if(route.componentPath.startsWith('/')) route.componentPath = route.componentPath.slice(1)

  return route;
}
function parseSpecials  (r){
  if(hasNodeDefined(r)) { r.node = r.node.trim(); }
  if(hasPathDefined(r)) { r.path = r.path.trim(); }
  if(hasNameDefined(r)) { r.name = r.name.trim(); }

  // Si la route a un chemin qui indique '/' alors qu'elle se situe au plus haut niveau, c'est qu'il s'agit de la HOME.
  if(hasPathDefined(r) && r.path == '/' && isRootLevel(r)) { r.node = 'HOME'}
  // Si la route a un composant qui indique "INDEX" ou "EDIT" ou "MASTER" sans nom ni node défini, alors on peut assumer qu'il s'agit d'une potentielle erreur
  // et que c'est la propriété node qui aurait due être indiquée.
  if(hasComponentDefined(r) && !hasNodeDefined(r) && r.path == '/')
  {
    switch(r.componentName.toUpperCase()){
      case 'MASTER':
      case 'DEFAULT':
      case 'INDEX':
      case 'LIST':
      case 'EDIT':
      case 'VIEW':
      case 'NEW':
      case 'CREATE':
        /* A noter qu'aucune de ces valeurs pré-définies n'est poossible pour un élément du plus haut niveau */
        if(isRootLevel(r)) { throw `node=${r.componentName.toUpperCase()} ne peut pas être appliqué à un noeud de plus haut niveau (root)`;}
        r.node = r.componentName.toUpperCase();
        r.componentName = null;
        r.isPredef = true;
    }
  }

  if(hasNodeDefined(r)){
    switch(r.node.toUpperCase()){
      case 'HOME':
        if(!isRootLevel(r)) { throw 'node=HOME ne peut pas être appliqué à un noeud enfant.'; }
        r.path = '/';
        if(!hasComponentDefined(r)) r.componentName = 'Index';
        r.componentPath = '';
        if(!hasNameDefined(r)) { r.name = 'Home'}
        r.node = null;
        return r;
      case 'MASTER':
      case 'DEFAULT':
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.toLowerCase().slice(1); }
        if(!hasPathDefined(r)) { r.path = '.../'; }
        break;
      case 'INDEX':
      case 'LIST':
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.toLowerCase().slice(1); }
        if(!hasPathDefined(r)) { r.path = '.../'; }
        break;
      case 'EDIT':
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.toLowerCase().slice(1); }
        if(!hasPathDefined(r)) { r.path =  '.../:id'; }
        break;
      case 'VIEW':
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.toLowerCase().slice(1); }
        if(!hasPathDefined(r)) { r.path = `.../${r.componentName.toLowerCase()}/:id`; }
        break;
      case 'NEW':
      case 'CREATE':
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.toLowerCase().slice(1); }
        if(!hasPathDefined(r)) { r.path = `.../${r.componentName.toLowerCase()}`; }
        break;
      default:
        if(!hasComponentDefined(r)) { r.componentName = r.node.charAt(0).toUpperCase() + r.node.slice(1); }
        if(!hasPathDefined(r)) { r.path = r.componentName.toLowerCase() == r.node.toLowerCase ? '.../' : `.../${r.node.toLowerCase()}`; }
        if(!hasNameDefined(r)) { r.name = r.node; }
        break;
    }
  }

  return r;
}

function build (route) {
  /* On commence par traiter le cas ou route est un tableau, c'est-à-dire qu'il s'agit de la définition de l'ensemble des routes */
  if(Array.isArray(route))
  {
    let ret = [];
    route.forEach(r => {
      let built = build(r);
      if(Array.isArray(built)){
        ret = ret.concat(built);
      } else if(built !== undefined && built !== null) {
        ret.push(built);
      }
    });
    return ret.map(r => {
      var compo = r.componentName; if (r.componentPath && r.componentPath.trim() != '') compo = r.componentPath + '/' + r.componentName;
      return { path: r.path.toLowerCase(), name: r.name, children: r.children, componentName: compo, component: () => import(`@/views/${compo}.vue`)}
    });
  }

  /* On complète tout de suite le chemin si nécessaire */
  if(hasPathDefined(route) && isRootLevel(route) && !route.path.startsWith('/')) { route.path = `/${route.path}`; }

  /* Ensuite on traite les potentielles "valeurs spéciales" qui prédéfinissent certains des noeuds de route */
  route = parseSpecials(route);

  /* A partir de là on a 3 situations possibles :
      - la route est une "sheet" (feuille) cad qu'elle n'a pas d'enfant
      - la route est juste un "path" (chemin) cad qu'elle a des enfants mais n'a pas intrinsèquement de composant (page)
      - la route est un "node" (branche) cad qu'elle a des enfants mais qu'elle propose également un composant (page) à son niveau.
        Qui plus est, la page définiie dans une telle route, peut être (ou non) un container, ce qui signifie dans ce cas que tout ou partie de ses enfants
        peuvent être "embarqués" (nested) dans le composant lors de leur accès.
  */

  /* Traitement d'une route enfant (feuiille, cad sans enfants) => on peut la retourner directement */
  if(isSheet(route)){
    return parseSheet(route);
  }

  /* Préparation d'une route noeud (branche) cad avec enfants et composants */
  if(hasComponentDefined(route)){
    route = parseSheet(route);
  }
  /* Avant de continuer, avec le traitement des enfants il faut assurer le calcul des chemins complets (url & arborescence de fichiers) du "chemin" en cours. */
  else {
    route = ensureFullPaths(route);
  }

  /* On construit maintenant les enfants de la route */
  var pseudoChildren = [];
  route.children.forEach(child => {
    /* et pour ce faire on commence par indiquer au noeud enfant que la route est son parent */
    child.parent = route;
    let built = build(child);
    if(Array.isArray(built)){
      pseudoChildren = pseudoChildren.concat(built);
    }else if(built !== undefined && built !== null){
      pseudoChildren.push(built);
    }
  })
  pseudoChildren = pseudoChildren.filter(child => child !== undefined && child !== null);

  /* Si la route est un container, on va maintenant filtrer ses enfants entre ceux qui sont "embarqués" et ceux qui doivent être des noeux traités parallèlement.
    Les enfants marqués comme devant être embarqués resteront en tant que Children tandis que les autres ne seront plus considéres comme tel.
  */
  if(isContainer(route))
  {
    let realChildren = pseudoChildren.filter(child => child.nested);
    if(realChildren && realChildren.length > 0)
    {
      route.children = realChildren.map(child => {
        var compo = child.componentName; if (child.componentPath && child.componentPath.trim() != '') compo = child.componentPath + '/' + child.componentName;
        return { path: child.path.toLowerCase(), name: child.name, children: child.children, componentName: compo, component: () => import(`@/views/${compo}.vue`) };
      });
      pseudoChildren = pseudoChildren.filter(child => !child.nested);
    } else {
      route.children = null;
    }
  }

  /* pour finir, s'il reste des "pseudo-enfant" on retourne ces pseudo-enfants après leur avoir ajouté la route en cours, s'il y a lieu,
    cad si la route en cours de traitement est un composant et pas seulement un path
    sinon, on retoure la route
  */
  if(pseudoChildren && pseudoChildren.length > 0)
  {
    if(hasComponentDefined(route))
    {
      pseudoChildren.push(route);
    }
    return pseudoChildren;
  }
  return route;
}

const buildedRoutes = build(routesDef);
if(debugRoutes) console.log(buildedRoutes);

let loader = null;
function hideLoader(){
  if(loader){
    loader.hide();
    loader = null;
  }
}
function showLoader(){
  return new Promise((accept) => {
    if(loader == null){
      loader = Vue.$loading.show({
        loader: 'dots',
        color: '#f58a0f',
        height: 200,
        width: 200,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

let refresher = null;
function hideRefresher(){
  if(refresher){
    refresher.hide();
    refresher = null;
  }
}

function showRefresher(){
  return new Promise((accept) => {
    if(refresher == null){
      refresher = Vue.$loading.show({
        loader: 'spinner',
        color: '#f58a0f',
        height: 100,
        width: 100,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

let saver = null;
function hideSaver(){
  if(saver){
    saver.hide();
    saver = null;
  }
}

function showSaver(){
  return new Promise((accept) => {
    if(saver == null){
      saver = Vue.$loading.show({
        loader: 'dots',
        color: '#ff0000',
        height: 100,
        width: 100,
        backgroundColor: "#6c6c6c",
        opacity: 0.5,
        enforceFocus: true,
        lockScroll: true,
      });
    }
    setTimeout(accept(true), 100);
  });
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: buildedRoutes,
  })

  router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth || (record.meta.requiresRole != null) )){
      if(store.getters['auth/isAuthenticated'] || store.getters["auth/noAuthRequiredOnThisDevice"]){
        if(to.meta.requiresRole != null && !store.getters['auth/userHasOneOfRoles'](to.meta.requiresRole)){
          Vue.prototype.$showLoader = showLoader;
          Vue.prototype.$hideLoader = hideLoader;
          Vue.prototype.$showRefresher = showRefresher;
          Vue.prototype.$hideRefresher = hideRefresher;
          Vue.prototype.$showSaver = showSaver;
          Vue.prototype.$hideSaver = hideSaver;

          Vue.prototype.showLoader = showLoader;
          Vue.prototype.hideLoader = hideLoader;
          Vue.prototype.showRefresher = showRefresher;
          Vue.prototype.hideRefresher = hideRefresher;
          Vue.prototype.showSaver = showSaver;
          Vue.prototype.hideSaver = hideSaver;

          next('/accessDenied');
        } else {
          Vue.prototype.$showLoader = showLoader;
          Vue.prototype.$hideLoader = hideLoader;
          Vue.prototype.$showRefresher = showRefresher;
          Vue.prototype.$hideRefresher = hideRefresher;
          Vue.prototype.$showSaver = showSaver;
          Vue.prototype.$hideSaver = hideSaver;

          Vue.prototype.showLoader = showLoader;
          Vue.prototype.hideLoader = hideLoader;
          Vue.prototype.showRefresher = showRefresher;
          Vue.prototype.hideRefresher = hideRefresher;
          Vue.prototype.showSaver = showSaver;
          Vue.prototype.hideSaver = hideSaver;

          next()
        }
      } else {
        Vue.prototype.$showLoader = showLoader;
        Vue.prototype.$hideLoader = hideLoader;
        Vue.prototype.$showRefresher = showRefresher;
        Vue.prototype.$hideRefresher = hideRefresher;
        Vue.prototype.$showSaver = showSaver;
        Vue.prototype.$hideSaver = hideSaver;

        Vue.prototype.showLoader = showLoader;
        Vue.prototype.hideLoader = hideLoader;
        Vue.prototype.showRefresher = showRefresher;
        Vue.prototype.hideRefresher = hideRefresher;
        Vue.prototype.showSaver = showSaver;
        Vue.prototype.hideSaver = hideSaver;

        next('/login');
      }
    } else {
      Vue.prototype.$showLoader = showLoader;
      Vue.prototype.$hideLoader = hideLoader;
      Vue.prototype.$showRefresher = showRefresher;
      Vue.prototype.$hideRefresher = hideRefresher;
      Vue.prototype.$showSaver = showSaver;
      Vue.prototype.$hideSaver = hideSaver;

      Vue.prototype.showLoader = showLoader;
      Vue.prototype.hideLoader = hideLoader;
      Vue.prototype.showRefresher = showRefresher;
      Vue.prototype.hideRefresher = hideRefresher;
      Vue.prototype.showSaver = showSaver;
      Vue.prototype.hideSaver = hideSaver;

      next();
    }
  })

  router.beforeResolve(redirectUserHome);

  // Ré-écriture de la méthode "push" du router pour éviter les erreurs de type 'NavigationDuplicated'.s
  const originalPush = Router.prototype.push;
  Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
      console.log('router.push error', err, 'for location', location);
      if (err.name !== 'NavigationDuplicated') throw err
    });
  }
  export default router
