<template>
    <b-modal
      title="Emprunt d'identité"
      centered
      :static="true"
      :lazy="true"
      size="lg"
      v-if="isAuthenticated && d_visible"
      v-model="d_visible"
      @hide="onHide"
      @ok="onOk"
      button-size="lg"
    >
      <form-field caption="Actuellement">
        <b-radio-group v-model='impersonatedUsername' stacked>
          <b-radio v-if="impersonatedUsername && impersonatedUsername!=null && impersonatedUsername != ''">Mettre fin à l'emprunt d'identité</b-radio>
          <b-radio v-else value=''><i>Pas d'emprunt d'identité</i></b-radio>
          <b-radio v-show="impersonatedUsername && impersonatedUsername!=null && impersonatedUsername != ''" :value="impersonatedUsername">{{ impersonatedName }}</b-radio>
        </b-radio-group>
      </form-field>
      
      <hr/>

      <form-field caption="Recherche d'utilisateur">
      <vue-bootstrap-typeahead
          v-model="impersonatedUsername"
          :data="options"
          :minMatchingChars="1"
          :serializer="s => s.text"
          placeholder="recherche par nom, prénom"
          @hit="search_hit"
          style="width:100%"
      ><!--
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <span v-if="data.matches=='**'" class="full-match" v-html="htmlText"></span>
          <span v-if="data.matches=='*'" class="half-match" v-html="htmlText"></span>
          <span v-if="data.matches==''" class="no-match" v-html="htmlText"></span>
        </template>-->
      </vue-bootstrap-typeahead>
      </form-field>
    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PEOPLE } from "@/LocalDB";
//import SecuredArea from '@/components/Security/SecuredArea.vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import FormField from '../Panels/FormField.vue';

export default{
  components: { /*SecuredArea, FormField, CollapsePanel, InfoHelper,*/ VueBootstrapTypeahead, FormField},
  model:{
    prop: 'visible',
    event: 'change'
  },
  props: { visible: { type:Boolean, default: false} },
  data(){
    return {
      bufImpersonatedUsername: null,
      bufImpersonatedName:null,
      // options: []
      d_visible: this.visible,
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'impersonatedUser' ]),
    impersonatedUsername:{
      get(){
        if(this.bufImpersonatedUsername == null)
          return (this.impersonatedUser != null) ? this.impersonatedUser.username : '';
        return this.bufImpersonatedUsername;
      },
      set(value){
        this.bufImpersonatedUsername = value;
      }
    },
    impersonatedName: {
      get(){
        if(this.bufImpersonatedName == null)
          return (this.impersonatedUser != null) ? this.impersonatedUser.firstname + ' ' + this.impersonatedUser.lastname : '';
        return this.bufImpersonatedName;
      },
      set(value){
        this.bufImpersonatedName = value;
      }
    },
    options(){
      var users = PEOPLE.query().where(p => { return p.User_Id != null && p.VISA != null}).orderBy('name').get();
      //console.log('users', users);
      //this.options = 
      return users.map(p => {
        return { value: p.VISA.visa, text: p.name};
      });
    },
/*    impersonatedName(){
      if(this.bufImpersonatedUsername != null){
        console.log( 'impersonatedName');
        var found = this.options.filter(o => o.value == this.bufImpersonatedUsername);
        console.log('found', found);
        if(found.length > 0)
          return found[0].text;
      }
      return '';
    },*/

//      this.options.push({ value: '', text: "Pas d'emprunt d'identité"});


  },
  methods: {
    ...mapActions('auth', ['startImpersonationAsync', 'endImpersonation']),
    async onOk(){
      if(!this.bufImpersonatedUsername || this.bufImpersonatedUsername == '')
      {
        var responseEnd = await this.endImpersonation()
        if(responseEnd && this.impersonatedUser == null)
          this.toastSuccess("Emprunt d'identité terminé.");
        else
          this.toastFailure("L'emprunt d'identité ne s'est pas arrêté correctement.");
      }
      else{
        var responseStart = await this.startImpersonationAsync(this.bufImpersonatedUsername);
        if(responseStart)  
          this.toastSuccess("Emprunt d'identité démarré.");
        else 
          this.toastFailure("L'emprunt d'identité a échoué.");
      }
      await this.reset();
    },
    search_hit(selected){
      this.impersonatedUsername = selected.value;
      this.impersonatedName = selected.text;
    },
    onHide(){
      this.$emit('change', false);
      this.$emit('hide');
    },
    async reset(){
      this.bufImpersonatedUsername = null;
      this.bufImpersonatedName = null;
    }
  },
  async mounted(){
    //await this.refresh();
    await this.reset();
  },
  watch:{
    visible: function(newValue){
      this.d_visible = newValue;
    }
  }

}
</script>
